import Action from "@/providers/api/actions/action"

export default class StoreEditItemAction extends Action {
  /**
   * Stores the edit item
   *
   * @returns {object} Store action response
   */
  async handle ({ state, dispatch }) {
    let params = {};
    ["id", ...Object.keys(state.editNewRow)].forEach(e => {
      params[e] = state.editRow[e]
    })
    let res
    if (params.id === 0) {
      res = await dispatch("store", params)
    } else {
      res = await dispatch("update", { params, id: state.editRow.id })
    }
    if (res.status === 200 || res.status === 201) dispatch("clearEdit")
    return res
  }
}
