import Mutation from "@/providers/api/mutations/mutation"

export default class SetDetailsMutation extends Mutation {
  /**
   * Writes details of selected item to the state
   *
   * @param {object} state - the module state
   * @param {object} item - item details
   */
  async handle (state, param) {
    state.detailsLoaded = true
    state.details = param
  }
}
