import Extension from "@/providers/api/helpers/extension"

import RemoveAction from "./actions/remove"

export default class RemoveExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      actions: {
        /**
         * Deletes a item record
         */
        async remove (ctx, param) {
          if (typeof param === "object") {
            param = repository.ident(param)
          }
          return new RemoveAction(ctx, param, repository.api).run()
        }
      }
    })
  }
}
