import RepositoryTemplate from "@/providers/api/helpers/repositoryTemplate"

/**
 * The storage driver is responsible for handling data storage. This is
 * the default StorageDriver which can work with any JS object, but this
 * class in meant to be extended to create other storage drivers.
 * Examples of other Storage Drivers may include:
 *   - Vuex storage driver - for storing data in a vuex store
 *   - Locals storage driver - for storing data in local or session storage
 *   - Component storage driver - for storing data in a vue component
 *
 * @param {RepositoryTemplate} template - a repository template
 */
export default class StorageDriver {
  // The storage driver default template
  template = new RepositoryTemplate()

  /**
   * ? Extensions explanation
   * When adding class extensions the this.extensions property
   * is always used. When defining this.extensions in child classes,
   * the parent's definition is overwritten.
   * That's why if you add extensions in the extensions object you must also
   * call the this.loadClassExtensions() in the constructor to store
   * them in the loadedExtensions object, so they persist even when
   * the this.extensions object is overwriten in child classes.
   * This way, when the super() method is called in the constructor, the extensions
   * of each class will be moved to the loadedExtensions object which allows us to
   * overwrite the extensions property as many times as we want.
   * this.loadedExtenions should never be defined in child classes unless you
   * want to remove all parent extensions.
   */
  // The extensions of this class
  extensions = {}
  // All loaded extensions.
  loadedExtensions = {}

  /**
   * Moves the extensions from the extensions object to the loadedExtensions object
   */
  loadClassExtensions () {
    this.loadedExtensions = {
      ...this.loadedExtensions,
      ...this.extensions
    }
  }

  /**
   * ? Locally stored template properties explanation
   * Handlers exist so that the properties aren't a refererence to template properties, so even
   * if the template object changes the driver properties aren't actually changed until the
   * register method is called.
   * Class template property objects (this.actions, this.state, this.getters, this.mutations)
   * are proxys that call the handlers with the right context
   */
  state = {}
  gettersHandlers = {}
  getters = {}
  mutationsHandlers = {}
  mutations = {}

  /**
   * Extends the local template with the function argument template
   * Stores the template properties in prorerty handlers
   * Creates the template prorerty proxyx with the provided context
   *
   * @param {object|RepositoryTemplate} template - the template that the local template
   *                                               will be expanded with
   * @param {object} ctx - The context that will be provided to the actions
   */
  register (template = {}, ctx) {
    this.template.extend(template)

    // Creates the state proxy with the provided context
    this.state = new Proxy(ctx.state, {
      get: function (target, prop) {
        return target[prop]
      },
      set: function () {
        return Reflect.set(...arguments)
      }
    })

    // Stores the template getters in gettersHandlers
    this.gettersHandlers = { ...this.template.template.getters }
    // Creates the getters proxy with the provided context
    this.getters = new Proxy(this.gettersHandlers, {
      get: function (target, prop) {
        if (target.hasOwnProperty(prop)) {
          return new Proxy(target[prop], {
            apply: function (target, thisArg, argumentsList) {
              return target(argumentsList[0] ? argumentsList[0].state || ctx.state : ctx.state)
            }
          })
        }
      }
    })

    // Stores the template mutations in mutationsHandlers
    this.mutationsHandlers = { ...this.template.template.mutations }
    // Creates the mutations proxy with the provided context
    this.mutations = new Proxy(this.mutationsHandlers, {
      get: (target, prop) => {
        if (target.hasOwnProperty(prop)) {
          return new Proxy(target[prop], {
            apply: (target, thisArg, argumentsList) => {
              return target(argumentsList[1] || ctx.state, argumentsList[0])
            }
          })
        }
      }
    })
  }

  /**
   * Maps template properties of a certain type to the target (usually a repository)
   * so that they can be accessed directly from the target object/class
   *
   * @param {string} type - the type of properties to map (actions, mutations, getters, state)
   * @param {object} target - the object or class we want to map the properties to
   */
  mapProperties (type, target = this) {
    const propertyNames = Object.keys(this.template.template[type])
    for (let i = 0; i < propertyNames.length; i++) {
      const propertyName = propertyNames[i]
      if (process.env.NODE_ENV === "development" && target[propertyName]) {
        // eslint-disable-next-line no-console
        console.warn(`Overwriting resource "${propertyName}" method with ${type} definition.`)
      }
      if (type === "state") {
        Object.defineProperty(target, propertyName, {
          get: () => {
            return this.state[propertyName]
          },
          configurable: true
        })
        continue
      }
      if (type === "getters") {
        target[propertyName] = state => {
          return this.getters[propertyName](state)
        }
        continue
      }
      if (type === "mutations") {
        target[propertyName] = (params, ctx) => {
          return this.mutations[propertyName](params, ctx)
        }
      }
    }
    if (this[type]) {
      Object.defineProperty(target, type, {
        get: () => {
          return this[type]
        },
        configurable: true
      })
    }
  }

  /**
   * Maps multiple property types with a single funciton call to the provided target
   *
   * @param {object} target - the object or class we want to map the properties to
   */
  mapAllProperties (target = this) {
    this.mapProperties("state", target)
    this.mapProperties("getters", target)
    this.mapProperties("mutations", target)
  }
}
