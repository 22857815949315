import Action from "@/providers/api/actions/action"

export default class ClearListSortAction extends Action {
  /**
   * Clears the sort param
   */
  async handle ({ commit }) {
    commit("SET_LIST_SORT", "")
  }
}
