import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/subjects",
    name: "Subjects",
    meta: { title: "Subjects" },
    component: () => import(/* webpackChunkName: "Subjects List" */ "@/Modules/Subject/Views/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/subjects/new",
    name: "New Subject",
    meta: { title: "New Subject" },
    component: () => import(/* webpackChunkName: "New Subject" */ "@/Modules/Subject/Views/Edit"),
    children: [
      {
        path: "postal/new",
        name: "Subject New Postal"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/subjects/details/:id/edit",
    name: "Edit Subject",
    component: () => import(/* webpackChunkName: "Edit Subject" */ "@/Modules/Subject/Views/Edit"),
    children: [
      // Postal
      {
        path: "postal/new",
        name: "Edit Subject New Postal"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/subjects/details/:id",
    name: "Subject Details",
    component: () => import(/* webpackChunkName: "Subject Details" */ "@/Modules/Subject/Views/Details"),
    children: [
      {
        path: "add-user-contacts",
        name: "Add User Contacts"
      },
      {
        path: "edit-subject-contacts/:uid",
        name: "Edit Subject Contacts"
      },
      {
        path: "add-subject-contacts",
        name: "Add Subject Contacts"
      },

      // RELATIONS
      // Opportunity
      {
        path: "opportunity/new",
        name: "Subject Opportunity New"
      },

      // Order
      {
        path: "order/new",
        name: "New Subject Order"
      },
      {
        path: "order/details/:orderId",
        name: "Subject Order"
      },

      // Invoice
      {
        path: "invoice/new",
        name: "New Subject Invoice"
      },
      {
        path: "invoice/details/:invoiceId",
        name: "Subject Invoice"
      },

      // Task
      {
        path: "task/details/:taskId",
        name: "Subject Task"
      },
      {
        path: "task/new",
        name: "New Subject Task"
      },

      // Claim
      {
        path: "claim/details/:claimId",
        name: "Subject Claim"
      },
      {
        path: "claim/new",
        name: "New Subject Claim"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
