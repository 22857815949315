export default {
  layouts: {
    reminders: {
      title: "Reminders"
    },
    related_models: {
      title: "Related models"
    },
    details: {
      title: "Details"
    },
    sub_details: {
      title: "Sub details"
    }
  },
  views: {
    list: {
      header: {
        title: "Tasks",
        search_placeholder: "Search ...",
        add_button: "Add task"
      },
      table: {
        title: "Title",
        description: "Description",
        status: "Status",
        priority: "Priority",
        due_date: "Due date",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        copy: "Copy",

        selected_items: {
          selected: "Selected",
          items: "tasks",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          items1: "task",

          // Duality
          selected2: "Selected",
          items2: "tasks",

          // Plural
          selected3: "Selected",
          items3: "tasks"
        },
        data_is_missing: "Table is empty"
      }
    },
    details: {
      header: {
        back_button: "Tasks list",
        edit_button: "Edit task",
        new_task: "New Task",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} task here"
        }
      },
      main: {
        general: "General",
        basic_info: "Basic information",
        title: "Title",
        description: "Description",
        status: "Status",
        due_date: "Due date",
        start_date: "Start date",
        priority: "Priority",
        assignee_id: "Assignee",
        project_id: "Project",
        responsible_person_id: "Responsible person",
        project: {
          label: "Project",
          no_results_message: "Your search {{search}} did not have any matches",
          no_results_button: "Create a new project"
        }
      },
      reminders: {
        title: "Reminders",
        sub_title: "Set reminders for the task",
        success: "Has been successfully saved.",
        error: "Something went wrong",
        data_is_missing: "Table is empty",
        table: {
          title: "Title",
          recurrable: "Recurrable",
          date: "Reminder date"
        },
        reminder: {
          title: "Title",
          remind_date: "Remind date"
        },
        popup: {
          title: "Title",
          recipient: "Recipient",
          recipient_notice: "Task has to have atleast one connected customer with an email.",
          template: "Notification template",
          remind_date: "Remind date",
          recurrence: "Recurrence",
          complex: "Complex reminder",
          simple: "Simple reminder",
          add_button: "Add reminder",
          remove_button: "Remove reminder",
          default_remind_date_offset: "Default remind date before due date in days",
          recipients: {
            user: "Task assignee",
            customer: "Task related customers"
          }
        }
      },
      original_relation: {
        title: "Created from",
        relation_description: "Task was created from the {type} linked below",
        no_relation_description: "Select the {type} this Task was created from",
        type: "Type",
        types: {
          opportunity: "Opportunity"
        }
      },
      task_original_relations: {
        title: "Task is related to",
        title_if_no_data: "Task relations",
        connect_button: "Connect/Disconnect",
        tabs: {
          subjects: "Customers",
          projects: "Projects",
          orders: "Orders",
          invoices: "Invoices"
        },
        popup: {
          title: "Edit task relations",
          connect: "Connect",
          disconnect: "Disconnect",
          relation: "Relation",
          model: "Model",
          relations: {
            subjects: "Customer",
            orders: "Order",
            invoices: "Invoices",
            projects: "Projects"
          }
        }
      },
      sub_details: {
        responsible: "Responsible",
        assignee: "Assignee",
        start_date: "Start date",
        start_time: "Start time",
        due_date: "Due date",
        due_time: "Due time",
        project: "Project",
        priority: "Priority",
        status: "Status",
        created_by: "Created by",
        updated_by: "Updated by",
        subject: "Customer",
        data_not_available: "Data is not available",
        tabs: {
          tasks: "Tasks"
        },
        created_at: "Created",
        updated_at: "Last updated"
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Task {title} created",
    updated: "Task {title} updated",
    deleted: "Task {title} was deleted",
    deleted_multiple: "Tasks were deleted",
    not_found: "Unable to find specified task"
  }
}
