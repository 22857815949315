<template>
    <div class="global-search">
        <transition name="global-search-appear">
            <div v-show="visible" class="global-search__content">
                <div class="global-search__head">
                    <UikHeadline>Iskanje</UikHeadline>
                    <button class="global-search__close" @click="close()">
                        <UikIcon :icon="['far', 'times']"/>
                    </button>
                </div>
                <div class="global-search__search">
                    <UikIcon class="global-search__search-icon" :icon="['far', 'search']"/>
                    <input
                        ref="search"
                        v-model="search"
                        class="global-search__search-input"
                        placeholder="Vpišite iskalni niz"
                        @input="handleInput"
                        @change="triggerSearch"
                        @keydown.escape="close"
                    />
                    <div class="global-search__search-input-line"/>
                </div>
                <transition name="global-search-projects">
                    <div v-if="search" class="global-search__categories">

                        <div
                            v-for="(category, index) in list"
                            :key="'category-' + index"
                        >
                            <div v-if="index === 'tasks' && category.length > 0">
                                <div class="global-search__category-name">Tasks</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/tasks/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.title" />
                                </div>
                            </div>

                            <div v-if="index === 'subjects' && category.length > 0">
                                <div class="global-search__category-name">Subjects</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/subjects/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.title" :textBottom="entity.vat_number" />
                                </div>
                            </div>

                            <div v-if="index === 'projects' && category.length > 0">
                                <div class="global-search__category-name">Projects</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/projects/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.title" />
                                </div>
                            </div>

                            <div v-if="index === 'invoices' && category.length > 0">
                                <div class="global-search__category-name">Invoices</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/invoices/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.number" :textBottom="entity.number_view" />
                                </div>
                            </div>

                            <div v-if="index === 'orders' && category.length > 0">
                                <div class="global-search__category-name">Orders</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/orders/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.number" :textBottom="entity.number_view" />
                                </div>
                            </div>

                            <div v-if="index === 'supply' && category.length > 0">
                                <div class="global-search__category-name">Supply</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/supply/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.number" :textBottom="entity.number_view" />
                                </div>
                            </div>

                            <div v-if="index === 'opportunities' && category.length > 0">
                                <div class="global-search__category-name">Opportunities</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/opportunities/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.title" />
                                </div>
                            </div>

                            <div v-if="index === 'checklists' && category.length > 0">
                                <div class="global-search__category-name">Checklists</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/checklists/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.title" />
                                </div>
                            </div>

                            <div v-if="index === 'idents' && category.length > 0">
                                <div class="global-search__category-name">Idents</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/idents/edit/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.name" :textBottom="entity.code" />
                                </div>
                            </div>

                            <div v-if="index === 'claims' && category.length > 0">
                                <div class="global-search__category-name">Reclamations</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/claims/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.number" :textBottom="entity.number_view" />
                                </div>
                            </div>

                            <div v-if="index === 'reports' && category.length > 0">
                                <div class="global-search__category-name">Reports</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/reports/details/' + entity.id)"
                                >
                                    <UikAvatar :name="entity.title" />
                                </div>
                            </div>

                            <div v-if="index === 'kpis' && category.length > 0">
                                <div class="global-search__category-name">KPIs</div>
                                <div
                                    v-for="(entity, key) in category"
                                    :key="'category-' + index + '-' + key"
                                    class="global-search__category"
                                    @click="RoutePush ('/kpis/details/' + entity.uid)"
                                >
                                    <UikAvatar :name="entity.title" />
                                </div>
                            </div>
                        </div>
                        <div v-if="list && list.length <= 0" class="global-search__empty-notice">
                            <span>Iskanega projekta ni na tabli.<br/>Prepričajte se, da so vpisani podatki pravilni, ali pa ga dodajte na tablo.</span>
                            <UikButton  primary @click="close()">
                                <UikIcon :icon="['far', 'plus']"/>
                                <span>Dodaj projekt</span>
                            </UikButton>
                        </div>
                    </div>
                </transition>
            </div>
        </transition>
        <UikOverlay
            :visible="visible"
            class="global-search__overlay"
            :opacity="80"
            @close="close()"
        />
    </div>
</template>

<script>
import Popup from "@/Mixins/Utils/Popup"
import GlobalSearchResource from "@/Components/GlobalSearch/GlobalSearchResource"
export default {
  resources: { GlobalSearchResource },
  langKey: "GlobalSearch",
  mixins: [Popup],
  props: {
    projects: { type: Array, default: () => [] }
  },
  data () {
    return {
      search: "",
      list: null,
      timer: null
    }
  },
  created () {
    window.addEventListener("keydown", (event) => {
      // If key is Delete or Backspace return (for pdf viewer removing of objects)
      if (event.keyCode === 46 || event.keyCode === 8) {
        return
      }
      // if other elements like input are focused
      const currentFocus = document.activeElement
      if (["a", "body"].indexOf(currentFocus.nodeName.toLowerCase()) < 0) {
        return
      }
      if (event.key === "Escape") {
        this.close()
        return
      }
      if (!this.visible && !this.anyModifierKeyPressed(event)) {
        this.$emit("startSearch")
        this.$nextTick(() => { this.$refs.search.focus() })
      }
    })
  },
  methods: {
    close () {
      this.search = ""
      this.$emit("close")
    },
    anyModifierKeyPressed (event) {
      return [event.metaKey, event.altKey, event.ctrlKey].some(key => key === true)
    },
    async triggerSearch () {
      if (this.search === "") {
        this.list = null
      } else {
        const response = await this.$api.GlobalSearchResource.search(this.search)
        this.list = response.data
      }
    },
    RoutePush (path) {
      this.$router.push(path)
      this.close()
    },
    handleInput (event) {
      let search = event.target.value
      this.$emit("input", search)
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.search = search
        this.triggerSearch()
        this.inputTriggeredSearch = true
      }, 500)
    }
  }
}
</script>

<style lang="scss">
.global-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  > * {
    pointer-events: initial;
  }
  .global-search__content {
    position: relative;
    z-index: 11;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    width: 100%;
    max-width: 750px;
    .global-search__head {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .uik-headline__wrapper {
        font-size: 30px;
        color: white;
        padding-bottom: 3px;
      }
      .global-search__close {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        padding: 5px;
        background: transparent;
        border: none;
        font-size: 28px;
        color: $textLight;
        margin-top: 0;
        transition: all 0.15s;
        &:hover {
          color: lighten($primary, 2%);
          transform: scale(1.2);
          cursor: pointer;
        }
        &:active {
          transform: none;
        }
      }
    }
    .global-search__search {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin-top: 5px;
      width: 100%;
      border-bottom: solid 1px rgba($textSecondary , 0.5);
      padding: 10px 5px;
      margin-bottom: 20px;
      .global-search__search-icon {
        color: $textSecondary;
        font-size: 18px;
        margin-right: 10px;
      }
      .global-search__search-input-line {
        position: absolute;
        height: 2px;
        width: 0;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: -1px;
        background: lighten($primary, 2%);
        transition: all 0.25s;
      }
      .global-search__search-input {
        width: 100%;
        padding: 5px;
        color: white;
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        background: transparent;
        border: none;
        transition: all 0.15s;
        &::placeholder {
          color: $textSecondary;
          transition: all 0.15s;
        }
        &:focus {
          & + .global-search__search-input-line {
            width: 100%;
          }
        }
      }
    }
    .global-search__categories {
      overflow: auto;
      height: calc(70vh - 100px);
      max-height: calc(70vh - 100px);
      padding-bottom: 20px;
      width: 100%;
      &::-webkit-scrollbar {
        background: transparent;
        border: none;
        box-shadow: none;
        width: 10px;
        height: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #3d3e3e;
        border: none;
        box-shadow: none;
        &:hover {
          background: $primary;
        }
        &:active {
          background: #f03a24;
        }
      }
      .global-search__category-name {
        color: #fff;
        margin: 8px 8px 15px;
      }
      .global-search__category {
        width: calc(100% - 10px);
        margin: 5px;
        border: none;
        padding: 14px 15px;
        border-radius: $borderRadius;
        background: rgba(#424343, 0.95);
        box-shadow: 0 2px 4px rgba(black, 0.1);
        transition: all 0.15s;
        animation: board-project 0.25s;
        @keyframes board-project {
          from {
            opacity: 0.5;
            transform: scale(0.85);
          }
          to {
            opacity: 1;
            transform: none;
          }
        }
        .uik-avatar__wrapper {
          .uik-avatar__avatarWrapper {
            .uik-avatar__avatarPlaceholder {
              background: rgba(white, 0.25);
              border: none;
              color: white;
            }
          }
          .uik-avatar__info {
            .uik-avatar__name,
            .uik-avatar__textBottom {
              white-space: nowrap;
              text-align: left;
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
            }
            .uik-avatar__name {
              color: white;
            }
            .uik-avatar__textBottom {
              color: $textSecondary;
            }
          }
        }
        &:hover {
          background: rgba(#565757, 0.95);
          cursor: pointer;
        }
        &:active {
          background: rgba(#424343, 0.95);
        }
      }
    }
    .global-search__empty-notice {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      padding-top: 30px;
      justify-content: flex-start;
      align-items: center;
      margin: 0 auto;
      text-align: center;
      font-size: 15px;
      color: $textLight;
      line-height: 1.4;
      animation: global-search-empty-notice 0.25s;
      @keyframes global-search-empty-notice {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      span {
        max-width: 600px;
      }
      .uik-btn__base {
        margin-top: 40px;
      }
    }
  }
  .global-search-appear-enter-active,
  .global-search-appear-leave-active {
    transition: all 0.25s;
  }
  .global-search-appear-enter,
  .global-search-appear-leave-to {
    opacity: 0 !important;
    transform: translateY(75px) !important;
  }
  .global-search-projects-enter-active,
  .global-search-projects-leave-active {
    transition: all 0.4s;
  }
  .global-search-projects-enter,
  .global-search-projects-leave-to {
    height: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
