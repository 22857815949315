import Extension from "@/providers/api/helpers/extension"

import SetDetailsMutation from "./mutations/setDetails"
import ClearDetailsMutation from "./mutations/clearDetails"

import GetDetailsAction from "./actions/getDetails"
import ClearDetailsAction from "./actions/clearDetails"

export default class DetailsExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      state: {
        /**
         * Stores details of a selected item
         */
        details: {},
        /**
         * Keeps track of wether the details have been loaded
         */
        detailsLoaded: false,
        /**
         * The default parameters to be sent with getDetails (show) request
         */
        detailsDefaultParams: {}
      },
      mutations: {
        /**
         * Writes details of selected item to the state
         */
        SET_DETAILS (state, param) {
          return new SetDetailsMutation(state, param).run()
        },
        /**
         * Clears item details
         */
        CLEAR_DETAILS (state) {
          return new ClearDetailsMutation(state).run()
        }
      },
      actions: {
        /**
         * Retrieves item details from api
         */
        async getDetails (ctx, params) {
          if (params.id && typeof params.id === "object") {
            params.id = repository.identWith(params.id)
          }
          return new GetDetailsAction(ctx, params, repository.api).run()
        },
        /**
         * Commits the mutation that clears the details object
         */
        async clearDetails (ctx) {
          return new ClearDetailsAction(ctx).run()
        }
      }
    })
  }
}
