import Vue from "vue"
import LaravelRepository from "@/providers/api/repositories/LaravelRepository"

export default class Options extends LaravelRepository {
  route = "options"
  namespace = "options"

  state = {
    loaders: [],
    options: {}
  }

  actions = {
    /**
     * Fetches filter options
     *
     * @param {object} params - The params object
     * @param {object} params.namespace - The namespace for the filterOptions to be saved under (optional)
     * @param {object} params.model - The name of the model we want to get options from
     * @param {object} params.type - The type of filter options we want (list, relationList, minMax)
     * @param {object} params.column - The column we want to get the options for (only for list and minMax)
     * @param {object} params.columns - The columns we want returned in option objects (only for relationList)
     * @param {object} params.relation - The name of the relation to filter list by,
     *                                   if we want to get a list of users that are connected to a company,
     *                                   the model is "User" and the relation is "companies" which yields
     *                                   User::has(companies)->get();
     */
    getOptions: async ({ commit }, params) => {
      let namespace = params.namespace || params.column || params.columns[0]
      let response = await this.request({ namespace }).post("/options", params)
      commit("SET_OPTIONS", { options: response.data, namespace })
      return response
    },

    getFilterOptions ({ dispatch }, params) {
      return dispatch("getOptions", params)
    },

    setOptions ({ commit }, params) {
      return commit("SET_OPTIONS", params)
    },

    removeOptions ({ commit }, params) {
      return commit("REMOVE_OPTIONS", params)
    },

    clearOptions ({ commit }) {
      return commit("CLEAR_OPTIONS")
    }
  }

  mutations = {
    /**
     * Adds or removes a loader
     *
     * @param {object} state - the module state
     * @param {object} params - the params to set
     * @param {boolean} params.value - specifies if namespace should be added or removed from array
     * @param {string} params.key - the namespae to add/remove from array
     */
    SET_LOADER (state, params) {
      if (params.value && !state.loaders.includes(params.key)) {
        state.loaders.push(params.key)
      } else if (!params.value && state.loaders.includes(params.key)) {
        state.loaders.splice(state.loaders.indexOf(params.key), 1)
      }
    },
    /**
     * Write provided options under provided namespace to the state
     *
     * @param {object} state - the module state
     * @param {string} options - the options to add to state
     * @param {string} namespace - the namespace under which the options will be added
     */
    SET_OPTIONS (state, { options, namespace }) {
      Vue.set(state.options, namespace, options)
    },
    /**
     * Removes a single set of options stored under provided namespace
     *
     * @param {object} state - the module state
     * @param {string} namespace - The namespace of the options we wish to remove
     */
    REMOVE_OPTIONS (state, namespace) {
      if (state.options.hasOwnProperty("namespace")) Vue.remove(state.options, namespace)
    },
    /**
     * Removes all options
     *
     * @param {object} state - the module state
     */
    CLEAR_OPTIONS (state) {
      Vue.set(state, "options", {})
    }
  }
}
