import Extension from "@/providers/api/helpers/extension"

import DuplicateAction from "./actions/duplicate"

export default class DuplicateExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      actions: {
        /**
         * Deletes a item record
         */
        async duplicate (ctx, params) {
          return new DuplicateAction(ctx, params, repository.api).run()
        }
      }
    })
  }
}
