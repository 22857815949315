import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration",
    name: "Administration",
    meta: { title: "Administration" },
    component: () => import(/* webpackChunkName: "Administration" */ "@/Modules/Administration/Views/Default"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/settings",
    name: "App Settings",
    meta: { title: "Application Settings" },
    component: () => import(/* webpackChunkName: "Application Settings" */ "@/Modules/Administration/Views/AppSettings/Settings"),
    beforeEnter: ifAuthenticated
  }
]
