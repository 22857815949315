import FilterBuilder from "./FilterBuilder"

export default class Filter {
  params
  key
  module

  constructor (key, params, module) {
    this.key = key || null
    this.params = params || []
    this.module = module || null
  }

  setParams (params) {
    this.params = params
  }

  resetParams () {
    this.params = []
  }

  setKey (key) {
    this.key = key
  }

  setModule (module) {
    this.module = module
  }

  generateFilterObject () {
    return {
      module: this.module,
      key: this.key,
      params: this.params
    }
  }

  setToResource (resource) {
    return resource.setFilter(this.generateFilterObject())
  }

  build (builderProxyTarget, filter) {
    if (!Array.isArray(this.params)) throw new Error("Can't use FilterBuilder if the params property is not an array.")
    return new FilterBuilder(builderProxyTarget || this, filter || this.params)
  }
}
