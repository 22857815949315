export default {
  layouts: {
    details: {
      title: "Details"
    },
    travel_expenses: {
      title: "Travel expenses"
    },
    direct_expenses: {
      title: "Direct expenses"
    },
    subsistence_allowances: {
      title: "Subsistence allowances"
    }
  },
  views: {
    list: {
      header: {
        title: "Travel Orders",
        search_placeholder: "Search ...",
        add_button: "Add travel order"
      },
      table: {
        number: "Number",
        customer: "Customer",
        date: "Date",
        selected_items: {
          selected: "Selected",
          items: "travel orders",
          remove: "Remove selection",

          // Singular
          selected1: "Selected",
          item1: "travel order",

          // Duality
          selected2: "Selected",
          item2: "travel orders",

          // Plural
          selected3: "Selected",
          item3: "travel orders"
        }
      }
    },
    details: {
      sync_in_progress: "Travel Order has not yet been fully transferred from Pantheon. Some data is not yet visible and editing is disabled.",
      header: {
        back_button: "Travler Orders list",
        edit_button: "Edit travel order",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} travel order here"
        }
      },
      main: {
        title: "Main Details",
        number: "Number",
        number_view: "Number view",
        doc_type: "Document type",
        travel_order_date: "Travel order date",
        calc_date: "Calc date",
        status: "Status",
        statuses: {
          approved: "Appproved",
          not_approved: "Not approved"
        },
        worker: "Worker",
        car_plate_number: "Car plate number",
        vehicle: "Vehicle",
        is_company_car: "Is company car",
        department: "Department",
        active: "Active",
        inactive: "Inactive",
        toggle: {
          title: "Is active",
          activate: "Activate",
          deactivate: "Deactivate",
          description: "Test"
        },
        toggle_approved: {
          title: "Is approved",
          activate: "approved",
          deactivate: "not approved",
          description: "Travel order is "
        },
        toggle_car: {
          title: "Is company car",
          activate: "is company car",
          deactivate: "is not company car",
          description: "This car "
        }
      },
      customer: {
        customer: "Customer",
        select_subject: "Payer",
        name: "Name",
        address: "Address",
        email: "Email",
        phone: "Phone",
        subject_no_result_data: {
          no_results_message: "This Customer does not exist",
          no_results_button: "Create new Customer"
        }
      },
      footer: {
        remove_button: "Remove",
        save_button: "Save travel order"
      },
      travelExpenses: {
        title: "Travel Expenses",
        total_sum: "Total sum",
        add_new_travel_expense: "Add new travel expense",
        columns: {
          title: "Travel",
          note: "Note",
          subject_id: "Customer",
          start_date: "Start date",
          end_date: "End date",
          destination: "Destination",
          start_km: "Start km",
          end_km: "End km",
          eur_per_km: "Eur/km",
          total_eur: "Total Eur",
          country: "Country"
        },
        countries: {
          slovenia: "Slovenia",
          foreign: "Foreign"
        },
        popup: {
          popup_title: "Add new travel expense",
          title: "Title",
          country: "Country",
          customer: "Customer",
          start_date: "Start date",
          end_date: "End date",
          start_km: "Start km",
          end_km: "End km",
          eur_per_km: "EUR/km",
          note: "Note",
          add: "Add",
          countries: {
            slovenia: "Slovenia",
            foreign: "Foreign"
          }
        }
      },
      directExpenses: {
        title: "Direct Expenses",
        total_sum: "Total sum",
        add_new_direct_expense: "Add new direct expense",
        columns: {
          title: "Title",
          travel: "Travel",
          total_eur: "Total Eur"
        }
      },
      subsistenceAllowances: {
        title: "Subsistence Allowances",
        total_sum: "Total sum",
        add_new_subsistence_allowance: "Add new subsistence allowance",
        columns: {
          date: "Date",
          country: "Country",
          duration: "Duration",
          amount: "Amount",
          travel: "Travel",
          factor: "Factor",
          total: "Total"
        },
        popup: {
          popup_title: "Subsistence allowance",
          date: "Date",
          country: "Country",
          duration: "Duration",
          amount: "Amount",
          travel: "Travel",
          factor: "Factor",
          duration_factor: "Duration factor",
          add: "Add"
        }
      }
    }
  },
  popup: {
    title: "New Travel Order",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add travel order",
    update_button: "Update travel order"
  },
  prompts: {
    delete: {
      title: "Delete {number}",
      message: "You're about to delete {number}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Travel order {number} created",
    updated: "Travel order {number} updated",
    deleted: "Travel order {number} was deleted",
    deleted_multiple: "Travel orders were deleted",
    not_found: "Unable to find specified travel order"
  }
}
