import Vue from "vue"
import Mutation from "@/providers/api/mutations/mutation"

export default class SetPaginationMutation extends Mutation {
  /**
   * Writes current pagination params to the state
   *
   * @param {object} state - the module state
   * @param {object} params - Latest pagination settings
   */
  async handle (state, params) {
    Vue.set(state, "pagination", {
      current_page: params.current_page,
      last_page: params.last_page,
      per_page: parseInt(params.per_page),
      total: params.total || 0,
      from: params.from || 0,
      to: params.to || 0
    })
  }
}
