import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/opportunities",
    name: "Opportunities",
    meta: { title: "Opportunities" },
    component: () => import(/* webpackChunkName: "Opportunities List" */ "@/Modules/Opportunity/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/opportunities/new",
    name: "New Opportunity",
    meta: { title: "New Opportunity" },
    component: () => import(/* webpackChunkName: "Opportunity Details" */ "@/Modules/Opportunity/Views/Details"),
    children: [
      {
        path: "customer/new",
        name: "Opportunity New Customer",
        children: [
          {
            path: "postal/new",
            name: "Opportunity New Customer New Postal"
          }
        ]
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/opportunities/details/:id",
    name: "Opportunity Details",
    component: () => import(/* webpackChunkName: "Opportunity Details" */ "@/Modules/Opportunity/Views/Details"),
    children: [
      {
        path: "user/new",
        name: "Opportunity New User Details"
      },
      {
        path: "customer/new",
        name: "Opportunity New Customer Details",
        children: [
          {
            path: "postal/new",
            name: "Opportunity New Customer Details New Postal"
          }
        ]
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
