export default {
  Views: {
    components: {
      report_popup: {
        drill_down_title: "Drilldown title",
        report_title: "Report title",
        view_title: "View title",
        relation: "Relation",
        breadcrumb_identifier: "Breadcrumb identifier",
        model: "Model",
        add_drilldown: "Add drilldown",
        edit_drilldown: "Edit drilldown",
        redirect_url: "Redirect URL",
        add_column: "Add column",
        cancel: "Cancel",
        delete_view: "Delete view",
        delet_report: "Delete report",
        save_view: "Save view",
        save_report: "Save report",
        add_view: "Add view",
        add_report: "Add report",

        report_column: {
          column: "Column",
          title: "Title",
          filter_and_sort: "Filter and sort",
          empty_message: "Click to select the filtering and sorting property for this column",
          add_block: "Add block",

          components: {
            open: "Open",
            collapse: "Collapse",
            empty_message: "Click to select {text} value"
          }
        }
      },

      table_wrapper: {
        view: "View",
        edit_view: "Edit view",
        export_csv: "Export CSV",
        export_xlsx: "Export XLSX"
      }
    },
    Details: {
      Components: {
        Main: {
          Components: {
            Overview: {
              "Author": "Author",
              "Created_at": "Created at",
              "Last_modified": "Last modified",
              "Updated_at": "Updated at"
            }
          },
          index: {
            "Active": "Active",
            "Inactive": "Inactive"
          }
        },
        Header: {
          "Search": "Search ...",
          "Reports": "Reports",
          "List": "List",
          "Active": "Active",
          "Edit": "Edit",
          "Report": "Report",
          "activate": "activate",
          "deactivate": "deactivate",
          "You_can_action_Report_here": "You can {action} Report here",
          "An_error_occurred_while_updating_Report": "An error occurred while updating Report",
          export_csv: "Export CSV",
          export_xlsx: "Export XLSX"
        },
        Report: {
          table: {
            actions: "Actions"
          }
        }
      },
      index: {}
    },
    List: {
      Components: {
        Header: {
          "Search": "Search ...",
          "Add_Report": "Add Report"
        }
      },
      index: {
        "Title": "Title",
        "Created_At": "Created At",
        "Updated_At": "Updated At",
        "Author": "Author",
        "Last_Modified": "Last Modified",
        "Active": "Active",
        "Open": "Open",
        "Edit": "Edit",
        "Generate": "Generate",
        "Delete": "Delete",
        "An_error_occurred_while_updating_Report": "An error occurred while updating Report",
        "Successfully_updated_Report": "Successfully updated Report",
        "Remove_Report": "Remove Report",
        "Youre_about_to_delete_Report_title_Would_you_like_to_proceed_with_this_action": "You're about to delete Report {title}. Would you like to proceed with this action?",
        "Cancel": "Cancel",
        "Report_title_successfully_deleted": "Report {title} successfully deleted",
        "data_is_missing": "Table is empty"
      }
    }
  }
}
