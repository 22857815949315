import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration/mailbox-rules",
    name: "Mailbox Rules",
    meta: { title: "Mailbox Rules" },
    component: () => import(/* webpackChunkName: "Mailbox Rules List" */ "@/Modules/MailboxRule/Views/List/List"),
    children: [
      {
        path: "new",
        name: "New Mailbox Rule",
        meta: { title: "New Mailbox Rule" }
      },
      {
        path: "edit/:id",
        name: "Edit Mailbox Rule (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/mailbox-rules/details/:id",
    name: "Mailbox Rule Details",
    component: () => import(/* webpackChunkName: "Mailbox Rule Details" */ "@/Modules/MailboxRule/Views/Details/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Mailbox Rule (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
