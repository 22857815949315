import sourceDriver from "@/providers/api/sourceDrivers/sourceDriver"
import Api from "@/providers/api/helpers/api"
import Loaders from "./webApiDriver/extensions/Loaders/extension"
import Errors from "./webApiDriver/extensions/Errors/extension"

export default class ApiDriver extends sourceDriver {
  extensions = { Loaders, Errors }

  constructor (ctx) {
    super()
    this.ctx = ctx
    this.api = new Api(this.ctx)
    this.loadClassExtensions()
  }
}
