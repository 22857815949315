export default {
  install (Vue) {
    Vue.mixin({
      props: {
        idParam: { type: String, default: "id" }
      },
      computed: {
        id () {
          return this.$route.params[this.idParam]
        }
      }
    })
  }
}
