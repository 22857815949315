export default {
  views: {
    list: {
      header: {
        title: "Kontrolni seznami",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj kontrolni seznam"
      },
      table: {
        title: "Naslov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zanja sprememba",
        active: "Aktiven",
        copy: "Kopiraj",

        selected_items: {
          selected: "Izbrano",
          items: "kontrolni seznami",
          remove: "Odstrani izbiro",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          item1: "kontrolni seznami",

          // Duality
          selected2: "Izbrano",
          item2: "kontrolni seznami",

          // Plural
          selected3: "Izbrano",
          item3: "kontrolni seznami"
        },
        data_is_missing: "Tabela je prazna"
      }
    },
    details: {
      header: {
        back_button: "Seznam kontrolnih seznamov",
        edit_button: "Uredi kontrolni seznam",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tukaj lahko {action} kontrolni seznam"
        }
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Nazadnje spremenjeno"
        },
        information: {
          title: {
            label: "Naslov"
          },
          values: {
            label: "Vrednote"
          },
          description: {
            label: "Opis"
          },
          confirmer_id: {
            label: "Potrdite ID"
          },
          confirmer_type: {
            label: "Potrdi vrsto"
          },
          assignee_id: {
            label: "ID prejemnika"
          },
          assignee_type: {
            label: "Vrsta prejemnika"
          }
        }
      }
    }
  },
  popup: {
    title: "Nov kontrolni seznam",
    fields: {
      title: {
        label: "Naslov"
      },
      values: {
        label: "Vrednote"
      },
      description: {
        label: "Opis"
      }
    },
    values: {
      add_button: "Dodaj novo vrednost",
      remove_button: "Odstrani vrednost",
      title: "Naslov predmeta"
    },
    add_button: "Dodaj kontrolni seznam",
    update_button: "Posodobite kontrolni seznam"
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Kontrolni seznam {title} ustvarjen",
    updated: "Kontrolni seznam {title} posodobljen",
    deleted: "Kontrolni seznam {title} je bil izbrisan",
    deleted_multiple: "Kontrolni seznami so bili izbrisani",
    not_found: "Določenega kontrolnega seznama ni mogoče najti"
  }
}
