export default {
  layouts: {
    details: {
      title: "Glavne podrobnosti"
    },
    creation_info: {
      title: "Informacije o ustvarjanju"
    }
  },
  views: {
    list: {
      header: {
        title: "Reklamacije",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj reklamacijo"
      },
      table: {
        number: "Številka",
        number_view: "Prikaz številke",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnja sprememba",
        active: "Aktiven",
        copy: "Kopirati",
        warranty: "Garancija",
        in_warranty: "V garanciji",
        out_of_warranty: "Izven garancije",

        selected_items: {
          selected: "Izbrano",
          items: "reklamacije",
          remove: "Odstrani izbiro",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          item1: "reklamacija",

          // Duality
          selected2: "Izbrano",
          item2: "reklamacije",

          // Plural
          selected3: "Izbrano",
          item3: "reklamacije"
        },
        data_is_missing: "Tabela je prazna"
      }
    },
    details: {
      sync_in_progress: "Reklamacija še ni v celoti prenešena iz Pantheona. Nekateri podatki še niso vidni in urejanje je onemogočeno.",
      header: {
        back_button: "Reklamacijski seznam",
        edit_button: "Uredi reklamacijo",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tukaj lahko zahtevate {action} reklamacijo"
        },
        in_warranty: "V garanciji",
        out_of_warranty: "Izven garancije"
      },
      main: {
        title: "Glavne podrobnosti",
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Nazadnje spremenjeno"
        },
        basic: {
          headline: "Informacije",
          title: "Naslov"
        },
        customer: {
          title: "Stranka",
          name: "Ime",
          address: "Naslov"
        },
        edit: {
          title: "Nova reklamacija",
          is_confirmed: "Potrjeno",
          number: "Številka",
          number_view: "Prikaz številke",
          reclamation_date: "Datum reklamacije",
          due_date: "Planiran zaključek",
          resolved_date: "Zaključek rekmalacije",
          note: "Opomba",
          subject: {
            title: "Stranka",
            no_results_message: "Ni zadetkov",
            no_results_button: "Ustvari novo stranko"
          },
          doc_type: "Vrsta dokumenta",

          order_info_title: "Informacije o naročilu",
          receiver: "Prejemnik",
          status: "Status",
          responsible_person: "Odgovorna oseba",

          document_title: "Vrste dokumentov",

          delivery_title: "Način dostave",
          delivery_type: "Vrsta dostave",
          department: "Oddelek",

          payment_title: "Plačilo",
          user: {
            no_results_message: "Ta uporabnik ne obstaja",
            no_results_button: "Ustvari novega uporabnika"
          }
        }
      },
      footer: {
        remove_button: "Odstrani reklamacijo",
        save_button: "Shrani reklamacijo",
        save_button_confirmed: "Shranite in potrdite",
        resolve: "Rešeno",
        confirm_resolved: "Potrdi rešeno"
      },
      "creation-info": {
        resolved_date: "Datum rešitve",
        confirmed_date: "Datum potrditve"
      },
      original_relation: {
        title: "Ustvarjeno iz",
        relation_description: "Reklamacija je bila ustvarjena iz računa na spodnji povezavi",
        no_relation_description: "Izberite račun, iz katerega je bila ustvarjena reklamacija",
        type: "Type",
        types: {
          opportunity: "Priložnost",
          order: "Ponudba",
          invoice: "Račun"
        }
      }
    },
    edit: {
      title: "Nova reklamacija",
      back_button: "Seznam reklamacij",
      tabs: {
        properties: "Lastnosti"
      },
      footer: {
        add_button: "Dodaj reklamacijo",
        save_button: "Shrani reklamacijo"
      },
      properties: {
        title: "Naslov"
      }
    }
  },
  popup: {
    title: "Nova reklamacija",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj reklamacijo",
    update_button: "Posodobite reklamacijo"
  },
  "resolve-popup": {
    title: "Reši reklamacijo",
    resolved_title: "Potrdite rešeno reklamacijo",
    status: "Status",
    resolve_date: "Datum rešitve",
    name: "Potrditi",
    not_confirmed: "Ta reklamacija še ni bila potrjena kot rešena",
    confirm: "Potrdi",
    completed: "Dokončano",
    success_message_resolve: "Reklamacija je bila uspešno rešena",
    success_message_confirm: "Reklamacija je bila uspešno potrjena"
  },
  prompts: {
    delete: {
      title: "Izbriši {number}",
      message: "Izbrisali boste {number}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Reklamacija {number} ustvarjena",
    updated: "Reklamacija {number} posodobljena",
    deleted: "Reklamacija {številka} je bila izbrisana",
    deleted_multiple: "Reklamacije so bile izbrisane",
    not_found: "Navedene reklamacije ni mogoče najti"
  }
}
