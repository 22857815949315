export default {
  sidebar: {
    title: "Calendar",
    timeline_title: "Planning",
    create_btn: "Create",
    create_in_calendar: "Select a calendar",
    today: "Today",
    day: "Day",
    week: "Week",
    month: "Month",
    calendar: "Calendar",
    timeline: "Timeline",
    months: {
      full: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
      },
      short: {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec"
      }
    },
    calendars: {
      title: "Calendars",
      add_button: "Create a new calendar",
      edit_button: "Edit calendar {title}",
      target_popup: {
        title: "Select a target for the new calendar"
      }
    }
  },
  calendar: {
    weekdays: {
      full: {
        1: "Sunday",
        2: "Monday",
        3: "Tuesday",
        4: "Wednesday",
        5: "Thursday",
        6: "Friday",
        7: "Saturday"
      },
      short: {
        1: "Sun",
        2: "Mon",
        3: "Tue",
        4: "Wed",
        5: "Thu",
        6: "Fri",
        7: "Sat"
      }
    },
    no_event_title: "No Title",
    cannot_be_edited: "Calendar {calendar} cannot be edited from here",
    update_error: "There was an error while updating the calendar"
  },
  calendar_widget: {
    title: "Calendars",
    add_button: "Create new calendar",
    empty_notice: "List of calendars is empty",
    table: {
      title: "Title",
      color: "Color"
    },
    notify: {
      stored: "Calendar {title} created",
      updated: "Calendar {title} updated",
      deleted: "Calendar {title} was deleted",
      deleted_multiple: "Calendars were deleted",
      not_found: "Unable to find specified calendar"
    },
    prompts: {
      delete: {
        title: "Delete {title}",
        message: "You're about to delete {title}. Would you like to proceed with this action?",
        cancel: "Cancel",
        confirm: "Delete"
      }
    }
  },
  popup: {
    title: "New Calendar",
    fields: {
      title: {
        label: "Title"
      },
      event_title: {
        label: "Displayed content",
        placeholder: "Select property to be display in calendar card"
      },
      date_start: {
        label: "Date",
        placeholder: "Select property that represents date in calendar"
      },
      date_end: {
        label: "End date",
        placeholder: "Select property that represents end date in calendar"
      },
      color: "Color"
    },
    filter: {
      title: "Filter",
      description: "Use filters to narrow the results.",
      add_button: "Set filters",
      edit_button: "Edit filters",
      remove_button: "Remove filters",
      popup_title: "Find items where ..."
    },
    add_button: "Add calendar",
    update_button: "Update calendar"
  },
  models: {
    order: "Order",
    task: "Task"
  },
  back_to_calendar: "Back to Calendar",
  timeline: {
    users: "Assignees",
    projects: "Projects",
    unassigned: "Unassigned",
    no_project: "No project",
    cannot_be_edited: "Unable to edit the task"
  }
}
