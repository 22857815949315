import Extension from "@/providers/api/helpers/extension"

import SetFilterMutation from "./mutations/setFilter"
import RemoveFilterMutation from "./mutations/removeFilter"
import ClearFiltersMutation from "./mutations/clearFilters"
import LoadSessionstorageFiltersMutation from "./mutations/loadSessionstorageFilters"

import SetFilterAction from "./actions/setFilter"
import RemoveFilterAction from "./actions/removeFilter"
import ClearFilterAction from "./actions/clearFilters"
import BuildFilterAction from "./actions/buildFilter"

export default class FilterExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      state: {
        /**
         * Holds the selected filter options
         */
        filters: {}
      },
      mutations: {
        /**
         * Writes filter options to the state
         */
        SET_FILTER (state, params) {
          return new SetFilterMutation(state, params).run()
        },
        /**
         * Removes a filter
         */
        REMOVE_FILTER (state, params) {
          return new RemoveFilterMutation(state, params).run()
        },
        /**
         * Clears filter options in the state
         */
        CLEAR_FILTERS (state, param) {
          return new ClearFiltersMutation(state, param).run()
        },
        /**
         * Load filters from sessionStorage to the state
         */
        LOAD_SESSIONSTORAGE_FILTERS (state) {
          return new LoadSessionstorageFiltersMutation(state).run()
        }
      },
      actions: {
        /**
         * Sets the filter param
         */
        setFilter (ctx, param) {
          return new SetFilterAction(ctx, param).run()
        },
        /**
         * Removes a filter
         */
        removeFilter (ctx, param) {
          return new RemoveFilterAction(ctx, param).run()
        },
        /**
         * Removes all filters
         */
        clearFilters (ctx) {
          return new ClearFilterAction(ctx).run()
        },
        /**
         * Builds a filter
         */
        buildFilter (ctx, params) {
          return new BuildFilterAction(ctx, params).run()
        }
      }
    })
  }
}
