import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/claims",
    name: "Claims",
    meta: { title: "Reclamations" },
    component: () => import(/* webpackChunkName: "Claims List" */ "@/Modules/Claim/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/claims/details/:id",
    name: "Claim Details",
    component: () => import(/* webpackChunkName: "Claim Details" */ "@/Modules/Claim/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "user/new",
        name: "Claim New User Details"
      },
      {
        path: "customer/new",
        name: "Claim New Customer Details",
        children: [
          {
            path: "postal/new",
            name: "Claim New Customer Details New Postal"
          }
        ]
      }
    ]
  },
  {
    path: "/claims/new",
    name: "New Claim",
    component: () => import(/* webpackChunkName: "Claim New" */ "@/Modules/Claim/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "user/new",
        name: "Claim New User"
      },
      {
        path: "customer/new",
        name: "Claim New Customer",
        children: [
          {
            path: "postal/new",
            name: "Claim New Customer New Postal"
          }
        ]
      }
    ]
  }
]
