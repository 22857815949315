export default {
  layouts: {
    details: {
      title: "Main details"
    }
  },
  views: {
    list: {
      header: {
        title: "Assemblies",
        search_placeholder: "Search ...",
        add_button: "Add assembly"
      },
      table: {
        number: "Number",
        number_view: "Number view",
        subject: "Customer",
        assembly_date: "Assembly Date",
        status: "Status",
        options: "Options",
        data_is_missing: "Table is empty",
        selected_items: {
          selected: "Selected",
          items: "assembly",
          remove: "Remove selection",

          // Singular
          selected1: "Selected",
          item1: "assembly",

          // Duality
          selected2: "Selected",
          item2: "assemblies",

          // Plural
          selected3: "Selected",
          item3: "assemblies"
        }
      }
    },
    details: {
      header: {
        back_button: "Assemblies list",
        finalize_button: "Finalize assembly",
        send_to_customer: "Send to customer"
      },
      footer: {
        remove_button: "Remove assembly",
        save_button: "Save assembly"
      },
      main: {
        title: "New assembly",
        number: "Number",
        number_view: "Number view",
        status: "Status",
        assembly_date: "Assembly date",
        note: "Note",
        finalized_comment: "Finalization comment"
      }
    }
  },
  status: {
    draft: "Draft",
    "in-progress": "In progress",
    completed: "Completed"
  },
  popup: {
    title: "Finalize Assembly",
    fields: {
      status: "Status",
      finalized_comment: "Finalization comment"
    },
    finalize_button: "Finalize assembly"
  },
  send_popup: {
    title: "Send notification",
    template: "Notification template",
    cancel_button: "Cancel",
    confirm_button: "Next",
    error_message: "In order for this feature to wrok you need to add email to selected customer."
  },
  prompts: {
    delete: {
      title: "Delete {number}",
      message: "You're about to delete {number}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Assembly {number} created",
    updated: "Assembly {number} updated",
    deleted: "Assembly {number} was deleted",
    deleted_multiple: "Assemblies were deleted",
    not_found: "Unable to find specified assembly"
  }
}
