import LaravelResourceRepository from "@/providers/api/repositories/LaravelResourceRepository"

export default class Task extends LaravelResourceRepository {
  route = "tasks"
  namespace = "tasks"
  identifier = "uid"

  state = {
    listDefaultParams: {
      with: ["createdBy", "updatedBy", "status", "priority"]
    },
    detailsDefaultParams: {
      with: [
        "createdBy",
        "updatedBy",
        "assignee",
        "status",
        "priority",
        "project",
        "reminders",
        "fields",
        "subjects.addresses",
        "subjects.contacts",
        "opportunity",
        "modelDerivationRelations",
        "modelOriginalRelation.original",
        "access",
        "projects",
        "orders",
        "invoices"
      ]
    }
  }

  actions = {
    removeAttachedUsers: async (ctx, { id, params }) => {
      let res = await this.request({ namespace: "taskRemoveAttachedUsers" }).post(`${this.route}/remove-attached-users/${id}`, params)
      return res
    },
    storeTaskReminder: async (ctx, { id, params }) => {
      let res = await this.request({ namespace: "storeTaskReminder" }).post(`${this.route}/store-reminder/${id}`, params)
      if (res.isError) return res
      return res.data
    },
    updateTaskReminder: async (ctx, { id, params }) => {
      let res = await this.request({ namespace: "updateTaskReminder" }).post(`${this.route}/update-reminder/${id}`, params)
      if (res.isError) return res
      return res.data
    },
    deleteTaskReminder: async (ctx, { id, params }) => {
      let res = await this.request({ namespace: "deleteTaskReminder" }).post(`${this.route}/delete-reminder/${id}`, params)
      if (res.isError) return res
      return res.data
    },
    connect: async (ctx, { id, params }) => {
      return this.request({ namespace: "connectTask" }).post(`${this.route}/connect/${id}`, params)
    },
    disconnect: async (ctx, { id, params }) => {
      return this.request({ namespace: "disconnectTask" }).post(`${this.route}/disconnect/${id}`, params)
    }
  }
}
