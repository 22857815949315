import Job from "@/providers/api/jobs/job"

export default class GetFiltersRequestParamsJob extends Job {
  /**
   * Create the request filters param from state filters
   * and other additional filters
   *
   * @param {object} state - vuex state
   * @param {string} routeName - the route name for getting filter params from state
   * @param {object} filters - additional filters to add to request filters params
   *
   * @return {object} The request filters params object
   */
  handle (state, routeName, filters) {
    return {
      ...state.filters[routeName] || {},
      ...filters || {}
    }
  }
}
