import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/kpis",
    name: "Kpis",
    meta: { title: "Kpis" },
    component: () => import(/* webpackChunkName: "Kpis List" */ "@/Modules/Kpi/Views/List"),
    children: [
      {
        path: "new",
        name: "New Kpi",
        meta: { title: "New Kpi" }
      },
      {
        path: "edit/:id",
        name: "Edit Kpi (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/kpis/details/:id",
    name: "Kpi Details",
    component: () => import(/* webpackChunkName: "Kpi Details" */ "@/Modules/Kpi/Views/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Kpi (Details)"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
