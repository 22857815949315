import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/tasks",
    name: "Tasks",
    meta: { title: "Tasks" },
    component: () => import(/* webpackChunkName: "Tasks List" */ "@/Modules/Task/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/tasks/details/:id",
    name: "Task Details",
    component: () => import(/* webpackChunkName: "Task Details" */ "@/Modules/Task/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "access",
        name: "Task User Access"
      },
      {
        path: "reminder/new",
        name: "New Reminder"
      },
      {
        path: "reminder/:reminderId",
        name: "Edit Reminder"
      }
    ]
  },
  {
    path: "/tasks/new",
    name: "New Task",
    meta: { title: "New Task" },
    component: () => import(/* webpackChunkName: "New Task" */ "@/Modules/Task/Views/Details"),
    beforeEnter: ifAuthenticated
  }
]
