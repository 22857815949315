export default {
  user: {
    layouts: {
      users_list: {
        title: "Users list"
      }
    },
    views: {
      list: {
        header: {
          title: "Users",
          search_placeholder: "Search ...",
          add_button: "Add user"
        },
        table: {
          title: "Title",
          created_at: "Created at",
          updated_at: "Updated at",
          author: "Author",
          last_modified: "Last modified",
          active: "Active"
        }
      },
      details: {
        header: {
          back_button: "Users list",
          edit_button: "Edit user",
          toggle: {
            title: "Active",
            activate: "activate",
            deactivate: "deactivate",
            description: "You can {action} user here"
          }
        },
        main: {
          active: "Active",
          inactive: "Inactive",
          overview: {
            author: "Author",
            created_at: "Created at",
            updated_at: "Updated at",
            last_modified: "Last modified"
          }
        },
        resetPassword: {
          title: "Change Password",
          subtitle: "Security and authentication",
          form: {
            currentPassword: "Current password",
            newPassword: "New password",
            changePassword: "Change password"
          },
          feedback: {
            succcess: "You have successfully changed users password.",
            changeItAgain: "Change it again?"
          }
        },
        users_list: {
          title: "Attached users",
          add_user: "Add user",
          table: {
            name: "Name",
            data_is_missing: "Table is empty"
          }
        },
        navigationPermissions: {
          title: "Navigation Permissions",
          subtitle: "Which modules are hidden in navigation for this user",
          module: "Module",
          licence: "Licence",
          show: "Show",
          plans: {
            starter_licence: "Basic",
            standard_licence: "Advanced",
            map: "Map",
            claims: "Reclamations",
            travel_orders: "Travel Orders",
            assembly: "Assembly",
            advanced_invoices: "Advanced Invoices",
            supply: "Supply",
            projects: "Projects",
            opportunities: "Opportunities"
          }
        },
        plans: {
          title: "Licences",
          subtitle: "Which licences are assigned to this user",
          plan: "Plan",
          available: "Available",
          assigned: "Assigned",
          plans: {
            starter_licence: "Basic",
            standard_licence: "Advanced",
            map: "Map",
            claims: "Reclamations",
            travel_orders: "Travel Orders",
            assembly: "Assembly",
            advanced_invoices: "Advanced Invoices",
            supply: "Supply",
            projects: "Projects",
            opportunities: "Opportunities"
          }
        }
      }
    },
    popup: {
      title: "New User",
      active_user: "Active user",
      active_user_desc: "Activated user won't have to confirm its email.",
      fields: {
        name: "Full name",
        email: "Email"
      },
      add_button: "Add user",
      update_button: "Update user"
    },
    prompts: {
      delete: {
        title: "Delete {name}",
        message: "You're about to delete {name}. Would you like to proceed with this action?",
        cancel: "Cancel",
        confirm: "Delete"
      }
    },
    notify: {
      stored: "User {name} created",
      updated: "User {name} updated",
      deleted: "User {name} was deleted",
      not_found: "Unable to find specified user"
    }
  },
  login: {
    auth: {
      failed: "These credentials do not match our records.",
      throttle: "Too many login attempts. Please try again in :seconds seconds.",
      signup_success: "Successfully created user!",
      login_failed: "These credentials do not match our records.",
      logout_success: "Successfully logged out",
      token_invalid: "This activation token is invalid.",
      email_signup_activate_subject: "Confirm your account",
      email_signup_activate_line1: "Thanks for signup! Please before you begin, you must confirm your account.",
      email_signup_activate_action: "Confirm Account",
      email_signup_activate_line2: "Thank you for using our application!",
      email_signup_activated_subject: "Welcome to :appName",
      email_signup_activated_line1: "You have completed your registration! your account is active.",
      email_signup_activated_line2: "Now you can be login"
    }
  },
  auth: {
    login: {
      title: "Log In",
      sub_title: "AcronX",
      username: "Username",
      password: "Password",
      remember_me: "Remember Me",
      login: "Log In",
      forgot_password: "Forgot password?",
      forgot_password_text: "Not to worry. Your password can be reset.",
      reset_password: "Reset Password",
      trouble_loggin_in_text: "Trouble logging in?",
      contact_administrator: "Contact the administrator",
      auth: {
        login_failed: "User does not exist, is inactive, or the combination of username and password is incorrect. Please try again."
      }
    },
    reset_password: {
      title: "New Password",
      sub_title: "Enter your new password.",
      email: "E-Mail",
      new_password: "New Password",
      change_password: "Change Password",

      remembered_your_password: "Just remembered your password?",
      try_again: "Go back to Log In and try again. You can always come back to request a new password.",
      login: "Log In"
    },
    forgot_password: {
      title: "Reset Password",
      sub_title: "Please tell us your email address and we'll send you an email with reset password link.",
      email: "Email",
      send: "Send",
      email_text: "Email should arrive to your inbox shortly.",
      receiving_email_text: "Click here to try again if the email haven't arrived.",

      remember_title: "Just remembered your password?",
      go_back_to_login: "Go back to Log In and try again. You can always come back to request a new password.",
      login: "Log In",

      no_access_text: "Don't have login access, but think you should?",
      contact_administrator: "Contact the administrator"
    }
  },
  permissions: {
    title: "Permissions",
    add_button: "Add permissions",
    table: {
      columns: {
        permission: "Permission",
        module: "Module"
      },
      remove_button: "Remove permission"
    },
    module: {
      Administration: "Administration",
      Assembly: "Assembly",
      Auth: "Auth",
      Calendar: "Calendar",
      Chart: "Chart",
      Checklist: "Checklist",
      Claim: "Reclamation",
      Codelist: "Codelist",
      DocumentTemplate: "DocumentTemplate",
      Field: "Field",
      Ident: "Ident",
      Invoice: "Invoice",
      Kpi: "Kpi",
      Layout: "Layout",
      Media: "Media",
      NotificationTemplate: "NotificationTemplate",
      Opportunity: "Opportunity",
      Order: "Order",
      Project: "Project",
      PurchaseOrder: "PurchaseOrder",
      Report: "Report",
      Subject: "Customer",
      Supply: "Supply",
      Task: "Task",
      TravelOrder: "TravelOrder",
      UserSettings: "UserSettings",
      WorkOrder: "WorkOrder",
      Sync: "Sync"
    },
    model: {
      AdministrationUser: "AdministrationUser",
      Assembly: "Assembly",
      Permission: "Permission",
      Role: "Role",
      User: "User",
      Calendar: "Calendar",
      Chart: "Chart",
      Checklist: "Checklist",
      Claim: "Reclamation",
      CodelistData: "CodelistData",
      DocumentTemplate: "DocumentTemplate",
      Field: "Field",
      Ident: "Ident",
      Invoice: "Invoice",
      Kpi: "Kpi",
      Layout: "Layout",
      Media: "Media",
      NotificationTemplate: "NotificationTemplate",
      Opportunity: "Opportunity",
      Order: "Order",
      Project: "Project",
      PurchaseOrder: "PurchaseOrder",
      Report: "Report",
      Subject: "Customer",
      Supply: "Supply",
      Task: "Task",
      DirectExpense: "DirectExpense",
      TravelExpense: "TravelExpense",
      TravelOrder: "TravelOrder",
      UserSettings: "UserSettings",
      WorkOrder: "WorkOrder",
      Sync: "Sync"
    },
    empty_notice: "There are no permissions for {name}.",
    scope_drawer: {
      title: "Add scope to {name}",
      cancel_button: "Cancel",
      confirm_button: "Set scope"
    },
    add_drawer: {
      title: "Add permissions to {name}",
      search_placeholder: "Search ...",
      permissions_count: "{count} selected",
      table: {
        columns: {
          permission: "Permission",
          module: "Module"
        }
      },
      cancel_button: "Cancel",
      confirm_button: "Add selected permissions",
      notify: {
        success: "{count} permissions added to {name}",
        error: "An error occured while adding permissions to {name}"
      }
    }
  },
  access_drawer: {
    title: "User management",
    search: "Search...",
    tag: "Number of users ",
    table: {
      name: "Name"
    },
    cancel_button: "Canel",
    save_button: "Save"
  }
}
