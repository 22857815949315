import Getter from "@/providers/api/getters/getter"

export default class ListWithEditRowGetter extends Getter {
  /**
   * Returns list with the edit row data injected
   * used in UIK table's v-for
   */
  async handle (state) {
    if (state.editId === null) return state.list
    if (state.editId === 0) return [state.editRow, ...state.list]
    if (state.editId) {
      let rowBeingEditedIndex = state.list.findIndex(e => e.id === state.editId)
      let output = [...state.list]
      output.splice(rowBeingEditedIndex, 1, state.editRow)
      return output
    }
    return state.list
  }
}
