export default {
  layouts: {
    details: {
      title: "Details"
    }
  },
  views: {
    list: {
      header: {
        title: "Orders",
        search_placeholder: "Search ...",
        add_button: "Add order"
      },
      table: {
        number: "Number",
        number_view: "Number view",
        subject: "Customer",
        status: "Status",
        order_date: "Date",
        copy: "Copy",

        selected_items: {
          selected: "Selected",
          items: "invoices",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "order",

          // Duality
          selected2: "Selected",
          item2: "orders",

          // Plural
          selected3: "Selected",
          item3: "orders"
        },
        data_is_missing: "Table is empty"
      }
    },
    details: {
      sync_in_progress: "Order has not yet been fully transferred from Pantheon. Some data is not yet visible and editing is disabled.",
      header: {
        title: "New Order",
        back_button: "Orders list",
        document_preview_button: "Document preview",
        invoice_button: "Create Invoice",
        advance_button: "Create Advance",
        send_to_customer_button: "Send to customer"
      },
      footer: {
        remove_button: "Delete order",
        add_button: "Add order",
        save_button: "Save order"
      },
      main: {
        title: "Main Info"
      },
      tasks: {
        back_button: "Back to order"
      },
      properties: {
        main: {
          title: "New Order",
          required_data: "Order data",
          number: "Order number",
          number_view: "Number view",
          subject: {
            label: "Customer",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new customer"
          },
          recipient: {
            label: "Recipient",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new recipient"
          },
          order_date: "Order date",
          service_date: "Service date",
          date_valid: "Final date",
          status: "Status",
          department: {
            label: "Department",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new department"
          },
          note: "Note",
          doc_type: "Document type",
          created_from_opportunity: "Created from opporunity",
          responsible_person: "Responsible person",
          user: {
            no_results_message: "This User does not exist",
            no_results_button: "Create new user"
          },
          subject_no_result_data: {
            no_results_message: "This Customer does not exist",
            no_results_button: "Create new Customer"
          },
          days_for_payment: "Days for payment",
          due_date: "Due date"
        },
        delivery: {
          title: "Delivery",
          receiver: {
            label: "Deliver to",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new subject"
          },
          delivery_deadline: "Delivery deadline",
          delivery_type: "Delivery type",
          department_code: "Department code",
          sale_method: "Sale method",
          amount: "Amount"
        },
        accounting_summary: {
          title: "Accounting Summary"
        },
        accounting_items: {
          title: "Accounting Items"
        },
        accounting_costs: {
          title: "Accounting Costs"
        }
      },
      relations: {
        title: "Connected Documents",
        tabs: {
          invoices: "Invoices",
          advances: "Advance Invoices"
        },
        add_buttons: {
          opportunities: "New Opportunity"
        }
      },
      custom_fields: {
        title: "Custom Fields"
      },
      customer: {
        title: "Customer",
        name: "Name",
        address: "Address",
        email: "E-mail",
        phone: "Phone number"
      },
      creation_info: {
        title: "Creation Info"
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete order",
      message: "You're about to delete order {number}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    create_invoice: {
      title: "Create invoice from order",
      message: "A new invoice will be created from this order. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Create Invoice"
    },
    create_advance: {
      title: "Create advance from order",
      message: "A new advance will be created from this order. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Create Advance"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Order saved",
    updated: "Order updated",
    deleted: "Order {number} was deleted",
    deleted_multiple: "Orders were deleted",
    not_found: "Unable to find specified order"
  }
}
