import Vue from "vue"
import Mutation from "@/providers/api/mutations/mutation"

export default class LoadSessionstorageFiltersMutation extends Mutation {
  /**
   * Load filters from sessionStorage to the state
   *
   * @param {object} state - the module state
   */
  async handle (state) {
    let filters = JSON.parse(window.sessionStorage.getItem("filters")) || {}
    if (filters && filters[state.namespace]) Vue.set(state, "filters", filters[state.namespace])
  }
}
