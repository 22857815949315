import Vue from "vue"
export default {
  namespaced: true,
  state: {
    ui: {},
    options: {}
  },
  getters: {
    getUi (state) {
      return state.ui
    }
  },
  mutations: {
    USER_INTERFACE (state, obj) {
      for (let key in obj) {
        Vue.set(state.ui, key, obj[key])
      }
    },
    USER_INTERFACE_REMOVE (state, obj) {
      for (let key in obj) {
        delete state.ui[obj[key]]
      }
    },
    SET_OPTIONS (state, { resource, data }) {
      if (state.options[resource] === undefined) {
        Vue.set(state.options, resource)
      }
      state.options[resource] = data
    },
    CLEAR_OPTIONS (state, namespace) {
      state.options[namespace] = {}
    },
    SET_SPACE (state, { namespace, data }) {
      if (state[namespace] === undefined) {
        Vue.set(state, namespace, data)
      }
      /**
       * Ensure that array gets cleared
       */
      if (Array.isArray(data)) {
        Vue.set(state, namespace, data)
        return
      }
      /**
       * This is probably object or string
       * just keep on adding
       */
      for (let key in data) {
        Vue.set(state[namespace], key, data[key])
      }
    },
    CLEAR_SPACE (state, spaces) {
      if (typeof spaces === "string") {
        delete state[spaces]
        return
      }
      for (let key in spaces) {
        delete state[spaces[key]]
      }
    }
  },
  actions: {
    setSpace ({ commit }, [space, obj]) {
      commit("SET_SPACE", { namespace: space, data: obj })
    },
    removeSpace ({ commit }, spaces) {
      commit("CLEAR_SPACE", spaces)
    },
    setUi ({ commit }, obj) {
      commit("USER_INTERFACE", obj)
    },
    removeUi ({ commit }, obj) {
      commit("USER_INTERFACE_REMOVE", obj)
    },
    /**
     * Retrieves options for resource
     *
     * @param { String } Resouce - basically a controller
     * @param { Object } params
     */
    getOptions: async ({ commit }, { namespace, resource, params }) => {
      if (!namespace) namespace = resource

      try {
        let promise = await window.axios.get(resource, { params })
        commit("SET_OPTIONS", { resource: namespace, data: promise.data })
        return { status: promise.status }
      } catch (error) {
        return { ...error.response }
      }
    },
    /**
     * Clear options resource
     */
    clearOptions: async ({ commit }, namespace) => {
      commit("CLEAR_OPTIONS", namespace)
    }
  }
}
