import Action from "@/providers/api/actions/action"

import Filter from "../helpers/Filter"
import SetFilterAction from "./setFilter"

export default class BuildFilterAction extends Action {
  handle (ctx, params = {}) {
    let filter = new Filter(params.key, [], params.module)
    let builderProxyTarget = {
      set: function () {
        return new SetFilterAction(ctx, filter.generateFilterObject()).run()
      }
    }
    return filter.build(builderProxyTarget)
  }
}
