import LaravelRepository from "@/providers/api/repositories/LaravelRepository"
import router from "@/providers/router"
export default class Auth extends LaravelRepository {
  route = "auth"
  namespace = "auth"

  state = {
    user: {},
    showTrialBar: false
  }

  actions = {
    login: async ({ commit }, params) => {
      const response = await this.request({ namespace: "login" }).post("/auth/login", params)
      if (response.isError) return response
      commit("SET_USER", response.data)
      commit("SHOW_TRIAL_BAR")
      return true
    },
    logout: async ({ commit }) => {
      const response = await this.request({ namespace: "logout" }).get("/auth/logout")
      if (response.isError) return response
      commit("CLEAR_USER")
      router.push("/login")
    },
    /**
     * Init request is make in src/providers/store.js
     * and sent payload to this action
     * This is a guard helper
     */
    init: async ({ commit }, payload) => {
      const response = await payload
      if (response.isError) return response
      commit("SET_USER", response.data)
    },
    /**
     * Get user payload
     */
    check: async ({ commit }) => {
      const response = await this.request({ namespace: "check" }).get("/auth/user")
      if (response.isError) return response
      commit("SET_USER", response.data)
      // commit("SET_EXPIRATION", promise.data)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    forgotPassword: async (ctx, params) => {
      return this.request({ namespace: "forgot-password" }).post("/forgot-password/create", params)
    },
    findPasswordToken: async (ctx, token) => {
      return this.request({ namespace: "forgot-password/find" }).get("/forgot-password/find/" + token)
    },
    resetPassword: async (ctx, params) => {
      return this.request({ namespace: "forgot-password/reset" }).post("/forgot-password/reset", params)
    },
    updateUserDetails: async (ctx, params) => {
      return this.request({ namespace: "updateUserDetails" }).post("/auth/update", params)
    },
    changePassword: async (ctx, params) => {
      return this.request({ namespace: "changeUserPassword" }).post("/auth/change-password", params)
    },
    getAdminEmail: async () => {
      return this.request({ namespace: "getAdminEmail" }).get("/auth/admin-email")
    },
    hideTrialBar: async ({ commit }) => {
      commit("HIDE_TRIAL_BAR")
      return true
    },
    sendEmailToSupport: async (ctx, params) => {
      return this.request({ namespace: "sendEmailToSupport" }).post("/auth/email-support", params)
    }
  }
  getters = {
    isAuthenticated: (state) => {
      return Object.keys(state.user).length !== 0
    }
  }
  mutations = {
    SET_USER: (state, payload) => {
      state.user = payload
    },
    CLEAR_USER: (state) => {
      state.user = {}
    },
    SHOW_TRIAL_BAR: (state) => {
      state.showTrialBar = true
    },
    HIDE_TRIAL_BAR: (state) => {
      state.showTrialBar = false
    }
  }
}
