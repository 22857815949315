export default {
  layouts: {
    details: {
      title: "Details"
    },
    delivery: {
      title: "Delivery"
    }
  },
  views: {
    list: {
      header: {
        title: "Supply",
        search_placeholder: "Search ...",
        add_button: "Add supply"
      },
      table: {
        number: "Number",
        number_view: "Number view",
        subject: "Customer",
        status: "Status",
        supply_date: "Date",
        copy: "Copy",

        selected_items: {
          selected: "Selected",
          items: "invoices",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "supply",

          // Duality
          selected2: "Selected",
          item2: "supplies",

          // Plural
          selected3: "Selected",
          item3: "supplies"
        },
        data_is_missing: "Table is empty"
      }
    },
    details: {
      header: {
        title: "New Supply",
        back_button: "Supply list",
        invoice_button: "Create Invoice"
      },
      footer: {
        remove_button: "Delete supply",
        add_button: "Add supply",
        save_button: "Save supply"
      },
      main: {
        title: "Main Info"
      },
      properties: {
        main: {
          title: "New Supply",
          number: "Supply number",
          number_view: "Number view",
          subject: {
            label: "Customer",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new customer"
          },
          supply_date: "Supply date",
          date_valid: "Final date",
          status: "Status",
          department: {
            label: "Department",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new department"
          },
          note: "Note",
          doc_type: "Document type",
          created_from_opportunity: "Created from opporunity",
          responsible_person: "Responsible person",
          user: {
            no_results_message: "This User does not exist",
            no_results_button: "Create new user"
          }
        },
        delivery: {
          title: "Delivery",
          receiver: {
            label: "Deliver to",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new customer"
          },
          delivery_deadline: "Delivery deadline",
          delivery_type: "Delivery type",
          department_code: "Department code",
          sale_method: "Sale method",
          amount: "Amount"
        },
        payment: {
          title: "Payment",
          days_for_payment: "Days for payment",
          payment_method: "Payment method",
          price_rate: "Price rate",
          discount: "Discount",
          vat_base: "VAT Base",
          vat_amount: "VAT Amount",
          for_pay: "For pay",
          reference: "Reference",
          ext: "EXT",
          ext_provider: "EXT Provider"
        },
        accounting_summary: {
          title: "Accounting Summary"
        },
        accounting_items: {
          title: "Accounting Items"
        },
        accounting_costs: {
          title: "Accounting Costs"
        }
      },
      relations: {
        tabs: {
          opportunities: "Opportunities"
        },
        add_buttons: {
          opportunities: "New Opportunity"
        }
      },
      custom_fields: {
        title: "Custom Fields"
      },
      customer: {
        title: "Customer",
        name: "Name",
        address: "Address",
        email: "E-mail",
        phone: "Phone number"
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete supply",
      message: "You're about to delete supply {number}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    create_invoice: {
      title: "Create invoice from supply",
      message: "A new invoice will be created from this supply. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Create Invoice"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Supply saved",
    updated: "Supply updated",
    deleted: "Supply {number} was deleted",
    deleted_multiple: "Supplies were deleted",
    not_found: "Unable to find specified supply"
  },
  status: {
    draft: "Draft",
    "in-progress": "In progress",
    completed: "Completed"
  }
}
