export default {
  views: {
    list: {
      header: {
        title: "Mailbox Rules",
        search_placeholder: "Search ...",
        add_button: "Add mailbox rule"
      },
      table: {
        title: "Title",
        description: "Description",
        entity: "Entity",
        rule: "Rule",
        created_at: "Created at",
        updated_at: "Updated at",
        data_is_missing: "Table is empty"
      },
      entity: {
        "App\\Modules\\Subject\\Subject": "Customer",
        "App\\Modules\\Opportunity\\Opportunity": "Opportunity"
      },
      rules: {
        "connect-sender": "Match with email sender",
        "connect-keyword": "Match email with {keyword} <{identifier}> in subject",
        "create-keyword": "Create entity when getting mail with {keyword} in subject"
      }
    },
    details: {
      header: {
        back_button: "Mailbox Rules list",
        edit_button: "Edit mailbox rule"
      },
      main: {
        overview: {
          created_at: "Created at",
          updated_at: "Updated at",
          entity: "Entity",
          rule: "Rule",
          entities: {
            "App\\Modules\\Subject\\Subject": "Customer",
            "App\\Modules\\Opportunity\\Opportunity": "Opportunity"
          },
          rules: {
            "connect-sender": "Match with email sender",
            "connect-keyword": "Match email with {keyword} <{identifier}> in subject",
            "create-keyword": "Create entity when getting mail with {keyword} in subject"
          }
        }
      }
    }
  },
  popup: {
    title: "New Mailbox Rule",
    rules: {
      "connect-sender": "Match with email sender",
      "connect-keyword": "Match with keyword and identifier",
      "create-keyword": "Create entity when getting mail with keyword in subject"
    },
    entities: {
      subject: "Customer",
      opportunity: "Opportunity"
    },
    fields: {
      title: {
        label: "Title"
      },
      description: {
        label: "Description"
      },
      entity: {
        label: "Entity"
      },
      rule: {
        type: {
          label: "Type"
        },
        keyword: {
          label: "Keyword"
        },
        id_name: {
          label: "Property name"
        }
      }
    },
    add_button: "Add mailbox rule",
    update_button: "Update mailbox rule"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Mailbox rule {title} created",
    updated: "Mailbox rule {title} updated",
    deleted: "Mailbox rule {title} was deleted",
    deleted_multiple: "Mailbox rules were deleted",
    not_found: "Unable to find specified mailbox rule"
  }
}
