<template>
    <div
        id="app"
        class="uik--restyle"
        :class="{
            'app--top-bar-visible': topBarMessage
        }"
    >
        <TopBarWithUrl v-if="showTrialBar" :message="showTrialBar.msg" :url="showTrialBar.url" @close="hideTrialBar()" />

        <TopBar v-if="topBarMessage" :message="topBarMessage" @close="topBarMessage = null" />

        <Authentication @ready="handleAuthenticationReady()"/>

        <Navigation v-if="showNavigation && $api.Auth.isAuthenticated" ref="navigation" @openSupportPopup="supportPopup = true"/>

        <transition :name="transitionName" mode="out-in">
            <router-view v-if="showApp" :key="$route.meta.key ? $route.params[$route.meta.key] : null" @updateNavigationList="$refs.navigation.updateList()" />
        </transition>

        <GlobalSearch
            v-if="$api.Auth.isAuthenticated"
            :visible="globalSearch"
            @close="globalSearch = false"
            @startSearch="globalSearch = true"
        />

        <SupportPopup
            v-if="$api.Auth.isAuthenticated"
            :visible="supportPopup"
            @close="supportPopup = false"
        />

        <CodelistData
            v-if="$api.Auth.isAuthenticated"
            :categories="$options.getCodelistCategories"
            @ready="loaded.CodelistData = true"
        />
        <UpdateAvailable/>

        <Loading :state="loaded.Authentication"/>
    </div>
</template>

<script>
import Authentication from "./Modules/Auth/Components/Authentication"
import UpdateAvailable from "./Components/Support/UpdateAvailable"
import Navigation from "@/Components/Navigation"
import Loading from "@/Components/Loading"
import CodelistData from "@/Modules/Codelist/Components/GetData"
import Auth from "@/Modules/Auth/Auth/Resources/Auth"
import GlobalSearch from "@/Components/GlobalSearch"
import Settings from "@/Modules/Auth/Auth/Resources/Settings"
import TopBar from "@/Components/TopBar.vue"
import TopBarWithUrl from "@/Components/TopBarWithUrl.vue"
import SupportPopup from "@/Components/SupportPopup.vue"
import { categories } from "@/Modules/Administration/Views/AppSettings/Categories"

export default {
  settingsCategories: categories,
  components: {
    Authentication,
    Navigation,
    CodelistData,
    Loading,
    UpdateAvailable,
    GlobalSearch,
    TopBar,
    TopBarWithUrl,
    SupportPopup
  },
  resources: { Auth, Settings },
  getCodelistCategories: ["statuses", "vat_rates", "units", "project_statuses", "units", "classif", "items_set", "document-template-views", "document-template-categories", "probabilities", "weight-unit", "dimension-unit", "claim-status", "currencies", "order_doc_type_status", "priorities", "default_invoice_status", "default_advance_invoice_status", "default_order_status", "default_opportunity_status", "default_invoice_doc_type", "default_advance_invoice_doc_type", "default_order_doc_type"],
  data () {
    return {
      transitionName: "",
      disableNavigationInViews: [
        "/login",
        "/not-found",
        "/subscription-not-paid",
        "/trial-expired",
        "/missing-permission",
        "/missing-licence",
        "/tenant-does-not-exist"
      ],
      loaded: {
        CodelistData: false,
        Authentication: false,
        Settings: false
      },
      globalSearch: false,
      topBarMessage: null,
      supportPopup: false
    }
  },
  computed: {
    showNavigation () {
      let show = true
      const route = this.$route.path
      this.disableNavigationInViews.forEach(path => {
        if (route.startsWith(path)) show = false
      })
      return show
    },
    showApp () {
      if (this.$route.name === "TrialExpired" || this.$route.name === "SubscriptionNotPaid") return true
      if (this.loaded.Authentication && !this.$api.Auth.isAuthenticated) return true
      return this.loaded.CodelistData && this.loaded.Settings
    },
    showTrialBar () {
      // Determine whether message should be shown
      if (this.$api.Auth.isAuthenticated && this.$api.Auth.user) {
        let user = this.$api.Auth.user

        if (user.has_active_trial && !user.has_active_subscription && this.$api.Auth.showTrialBar) {
          let trialData = user.trial_data
          if (trialData && trialData.trial_expires_at) {
            // Set message
            let timeDiffSeconds = this.moment(trialData.trial_expires_at).diff(this.moment(), "seconds")
            let timeDiffDays = timeDiffSeconds / 60 / 60 / 24
            // Show message only 14 days before trial ends
            if (timeDiffDays <= 14) {
              let daysToDisplay = (parseInt(timeDiffDays) + 1).toString()

              let url = null
              let msg = this.$t("Global.trial_bar_messages.expire") + " " + daysToDisplay + " "
              msg = daysToDisplay === "1" ? msg + this.$t("Global.trial_bar_messages.day") + ". " : msg + this.$t("Global.trial_bar_messages.days") + ". "

              // Determine whether logged in user can extend the licence
              if (user.email === trialData.email) {
                msg = msg + this.$t("Global.trial_bar_messages.redirect")
                if (trialData.url) {
                  url = trialData.url
                }
              } else {
                msg = msg + this.$t("Global.trial_bar_messages.contact_admin") + " "
                if (trialData.name && trialData.email) {
                  msg = msg + trialData.name + " (" + trialData.email + ") "
                }
                msg = msg + this.$t("Global.trial_bar_messages.extend_licence")
              }

              let obj = { msg: msg }
              if (url) {
                obj.url = url
              }

              return obj
            }
          }
        }
      }

      return false
    }
  },
  watch: {
    "$route" (to, from) {
      // Add fade only transition routes to this array
      const staticViews = ["login", "settings"]
      if (staticViews.includes(this.$route.name)) {
        this.transitionName = "route-fade"
        return
      }
      if (!to.path.split("/").includes(from.path.split("/")[1])) {
        this.transitionName = "route-fade"
        return
      }
      const toDepth = to.path.split("/").length
      const fromDepth = from.path.split("/").length
      if (toDepth < fromDepth) {
        this.transitionName = "slide-right"
      } else if (toDepth > fromDepth) {
        this.transitionName = "slide-left"
      } else {
        this.transitionName = "route-fade"
      }
    }
  },
  methods: {
    async handleAuthenticationReady () {
      this.loaded.Authentication = true
      let settingsRequests = []
      this.$options.settingsCategories.forEach(category => {
        settingsRequests.push(this.$api.Settings.get(category.key))
      })
      await Promise.all(settingsRequests)
      this.setDefaultMomentFormat()
      this.$i18n.set(this.$api.Settings.state.userSettings.LangAndRegion?.language ?? "en-EN")
      this.loaded.Settings = true
      this.setTopBarMessage()
    },
    setDefaultMomentFormat () {
      // eslint-disable-next-line
      this.moment.defaultFormat = this.$api.Settings?.userSettings?.LangAndRegion?.date_format
    },
    setTopBarMessage () {
      if (!this.$api.Auth.user || !this.$api.Auth.user.asset_plan_leftover) return

      if (this.$api.Auth.user.asset_plan_leftover.sms <= 0 && this.$api.Auth.user.asset_plan_leftover.mail <= 0) {
        this.topBarMessage = this.t("Global.top_bar_messages.missing_sms_and_mail_amounts")
      } else if (this.$api.Auth.user.asset_plan_leftover.sms <= 0) {
        this.topBarMessage = this.t("Global.top_bar_messages.missing_sms_amounts")
      } else if (this.$api.Auth.user.asset_plan_leftover.mail <= 0) {
        this.topBarMessage = this.t("Global.top_bar_messages.missing_mail_amounts")
      }
    },
    hideTrialBar () {
      this.$api.Auth.hideTrialBar()
    }
  }
}
</script>

<style lang="scss">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media only screen and (min-width: 1025px) {
    &.app--top-bar-visible {
      padding-top: 60px;

      .navigation {
        height: calc(100% - 60px);
        top: 60px;
        }
      }
    }
}

.updateAvailable {
  width: 300px;
  position: fixed;
  top: 10px;
  left: 50%;
  margin-left: -150px;
}

@import '@/Styles/index.scss';
</style>
