export default {
  layouts: {
    details: {
      title: "Glavne podrobnosti"
    },
    progress: {
      title: "Napredek"
    },
    milestones: {
      title: "Mejniki"
    }
  },
  views: {
    list: {
      header: {
        title: "Projekti",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj projekt"
      },
      table: {
        title: "Naslov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnja sprememba",
        active: "Aktiven",
        copy: "Kopiraj",

        selected_items: {
          selected: "Izbrano",
          items: "projekti",
          remove: "Odstrani izbrano",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          item1: "projekt",

          // Duality
          selected2: "Izbrano",
          item2: "projekti",

          // Plural
          selected3: "Izbrano",
          item3: "projekti"
        },
        data_is_missing: "Seznam je prazen"
      }
    },
    details: {
      header: {
        back_button: "Seznam projektov",
        edit_button: "Uredi projekt",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tu lahko {action} projekt"
        }
      },
      footer: {
        remove_button: "Izbriši projekt",
        add_button: "Dodaj projekt",
        save_button: "Shrani projekt"
      },
      main: {
        title: "Nov projekt",
        fields: {
          title: {
            label: "Naslov"
          },
          description: {
            label: "Opis"
          },
          start_date: {
            label: "Začetni datum"
          },
          due_date: {
            label: "Končni datum"
          },
          status: {
            label: "Status"
          },
          progress: {
            label: "Napredek"
          },
          user: {
            label: "Odgovorna oseba"
          }
        },
        user: {
          no_results_message: "Ta uporabnik ne obstaja",
          no_results_button: "Ustvari novega uporabnika"
        }
      },
      tasks: {
        back_button: "Nazaj na projekt"
      },
      project_details: {
        title: "Napredek",
        status: "Status"
      },
      milestones: {
        title: "Vmesni cilj",
        title_label: "Naslov",
        state: "Stanje",
        text: "Predmet",
        add_new: "Dodaj vmesni cilj",
        edit: "Uredi vmesni cilj"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Projekt {title} ustvarjen",
    updated: "Projekt {title} posodobljen",
    deleted: "Projekt {title} je bil izbrisan",
    deleted_multiple: "Projekti so bili izbrisani",
    not_found: "Določenega projekta ni mogoče najti"
  }
}
