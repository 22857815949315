import Vue from "vue"
import Mutation from "@/providers/api/mutations/mutation"

export default class ClearListMutation extends Mutation {
  /**
   * Resets the state list array
   *
   * @param {object} state - the module state
   */
  async handle (state) {
    Vue.set(state, "list", [])
    state.listLoaded = false
  }
}
