import Mutation from "@/providers/api/mutations/mutation"

export default class SetEditMutation extends Mutation {
  /**
   * Sets the row being edited
   *
   * @param {object} state - the module state
   * @param {string} value - the edit value to persist
   */
  async handle (state, param) {
    let clearEdit = () => {
      state.editId = null
      state.editRow = {}
    }
    if (param === null) return clearEdit()
    if (param === 0 || param === "new") {
      state.editId = 0
      state.editRow = { id: 0, ...state.editNewRow }
      return
    }
    let rowBeingEditedIndex = state.list.findIndex(e => e.id === param)
    if (rowBeingEditedIndex === -1) return clearEdit()
    state.editId = param
    state.editRow = { ...state.list[rowBeingEditedIndex] }
  }
}
