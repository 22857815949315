export default {
  layouts: {
    customer: {
      title: "Stranka"
    },
    accounting_items: {
      title: "Računovodske postavke"
    },
    accounting_summary: {
      title: "Računovodski povzetek"
    },
    accounting_costs: {
      title: "Računovodski stroški"
    },
    original_relation: {
      title: "Ustvarjeno iz"
    },
    creation_info: {
      title: "Podatki o ustvarjenju"
    },
    payments: {
      title: "Plačila"
    },
    relations: {
      title: "Povezani dokumenti"
    }
  },
  confirm: "Potrdi",
  cancel: "Prekliči",
  close: "Zapri",
  add: "Dodaj",
  save: "Shrani",
  remove: "Odstrani",
  delete: "Izbriši",
  open: "Odpri",
  edit: "Uredi",
  duplicate: "Dvojnik",
  activate: "Aktiviraj",
  deactivate: "Deaktiviraj",
  none: "Brez",
  logged_in_user: "Prijavljen uporabnik",
  passwordRule: "Geslo mora biti dolgo vsaj 6 znakov <br/> in vsebovati črko, številko in simbol (!@#$%^&*?-=+_)",
  customer: {
    customer: "Stranka",
    recipient: "Prejemnik",
    select_subject: "Izberite stranko",
    select_recipient: "Izberite prejemnika",
    name: "Ime",
    address: "Naslov",
    email: "E-naslov",
    phone: "Telefonska številka",
    user: {
      no_results_message: "Ta uporabnik ne obstaja",
      no_results_button: "Ustvari novega uporabnika"
    },
    subject_no_result_data: {
      no_results_message: "Ta stranka ne obstaja",
      no_results_button: "Ustvari novo stranko"
    },
    add_customer: "Dodajte novo stranko"
  },
  colors: {
    red: "Rdeča",
    orange: "Oranžna",
    yellow: "Rumena",
    lime: "Zelena limeta",
    green: "Zelena",
    cyan: "Cian",
    blue: "Modra",
    purple: "Vijolična",
    pink: "Roza",
    gray: "Siva"
  },
  menu: {
    views: {
      dashboard: "Nadzorna plošča",
      entities: "Entitete",
      tasks: "Opravila",
      planning: "Načrtovanje",
      subjects: "Stranke",
      projects: "Projekti",
      invoices: "Računi",
      "advanced-invoices": "Avansni računi",
      orders: "Ponudbe",
      supply: "Dobava",
      assemblies: "Sestava",
      "travel-orders": "Potni nalogi",
      opportunities: "Priložnosti",
      checklists: "Kontrolni seznami",
      idents: "Produkti",
      reports: "Poročila",
      calendar: "Koledar",
      administration: "Administracija",
      kpis: "KPI-ji",
      maps: "Zemljevid",
      claims: "Reklamacije",
      data: "Podatki",
      documents: "Dokumenti",
      organization: "Organizacija"
    }
  },
  components: {
    popups: {
      payment_popup: {
        title: "Plačilo",
        amount: "Znesek",
        payment_date: "Datum plačila",
        payment_method: "Način plačila",
        currency: "Valuta",
        cancel_button: "Prekliči",
        save_button: "Shrani"
      }
    },
    payment: {
      title: "Plačila",
      days_for_payment: "Dni za plačilo",
      payment_method: "Način plačila",
      price_rate: "Stopnja cene",
      discount: "Popust",
      vat_base: "Osnova za DDV",
      vat_amount: "Znesek DDV",
      for_pay: "Za plačilo",
      reference: "Referenca",
      ext: "EXT",
      ext_provider: "ZUN. Ponudnik",
      vat_date: "Datum plačila",
      due_date: "Datum plačila",
      table: {
        payment_date: "Datum plačila",
        payment_method: "Način plačila",
        amount: "Znesek",
        open: "Odprto",
        delete: "Izbriši",
        add: "Dodaj",
        currency: "Valuta",
        no_currency_message: "Brez valute",
        no_payment_method_message: "Brez načina plačila",
        sum: "Skupaj"
      }
    },
    creation_info: {
      creation_info: "Informacije o ustvarjanju",
      created_at: "Datum izdelave",
      created_by: "Izdelalan",
      updated_at: "Spremenjen",
      updated_by: "Nazadnje spremenjen"
    },
    contact_info: {
      address: {
        title: "Naslov",
        remove_button: "Odstrani naslov",
        add_button: "Dodaj naslov",
        address: "Naslov",
        postal: "Pošta",
        add_postal: "Dodaj pošto",
        city: "Mesto",
        country: "Država"
      },
      contact: {
        title: "Kontakt",
        remove_button: "Odstrani stik",
        add_button: "Dodaj kontakt",
        contact: "Kontakt",
        phone: "Telefonska številka",
        email: "E-naslov",
        any: "Kontakt",
        type: "Vrsta",
        label: "Dodatna oznaka",
        phone_country_code: "Oznaka države"
      }
    },
    multiple_entries_check: {
      label: "Želite dodati še enega?",
      description: "Obrazec bo po potrditvi ostal odprt"
    },
    recurrence: {
      form: {
        type: "Interval",
        types: {
          hourly: "Urni",
          daily: "Dnevni",
          weekly: "Tedenski",
          monthly: "Mesečni",
          yearly: "Letni"
        },
        recurrence_headline: "Ponovitev {število}",
        remove_button: "Odstranite ponovitev",
        add_button: "Dodaj ponovitev",
        time: "Ura",
        minutes: "Minute",
        weekday: "Dan",
        weekdays: {
          monday: "Ponedeljek",
          tuesday: "Torek",
          wednesday: "Sreda",
          thursday: "Četrtek",
          friday: "Petek",
          saturday: "Sobota",
          sunday: "Nedelja"
        },
        day: "Dan",
        first_day_of_month: "Prvi dan v mesecu",
        last_day_of_month: "Zadnji dan v mesecu",
        month: "Mesec",
        months: {
          january: "Januar",
          february: "Februar",
          march: "Marc",
          april: "April",
          may: "Maj",
          june: "Junij",
          july: "Julij",
          august: "August",
          september: "September",
          october: "Oktober",
          november: "November",
          december: "December"
        }
      }
    },
    query_builder: {
      title: "Poiščite {models}, kjer ...",
      type: {
        string: "Besedilo",
        number: "Številka",
        boolean: "Preklop",
        date: "Datum",
        enum: "Enum",
        relation: "Razmerje"
      },
      comparison_operators: {
        string: {
          "=": "Je",
          "!=": "Ni",
          contains: "Vsebuje",
          starts_with: "Začne se z",
          ends_with: "Konča se z",
          is_empty: "Je prazno",
          is_not_empty: "Ni prazen"
        },
        number: {
          "=": "=",
          "!=": "≠",
          "<": "<",
          ">": ">",
          "<=": "≤",
          ">=": "≥",
          exists: "Obstaja",
          not_exists: "Ne obstaja"
        },
        boolean: {
          "=": "Je",
          "!=": "Ni"
        },
        date: {
          "=": "Je",
          "!=": "Ni",
          "<": "Je pred",
          ">": "Je po",
          "<=": "Je na ali prej",
          ">=": "Je na ali po",
          exists: "Obstaja",
          not_exists: "Ne obstaja"
        },
        enum: {
          "=": "Je",
          "!=": "Ni",
          is_empty: "Je prazno",
          is_not_empty: "Ni prazen"
        },
        relation_one: {
          exists: "Obstaja",
          not_exists: "Ne obstaja",
          exists_where: "Obstaja tam, kjer ...",
          not_exists_where: "Ne obstaja kje ..."
        },
        relation_many: {
          exists: "Obstaja",
          not_exists: "Obstaja",
          exists_where: "Obstaja tam, kjer ...",
          not_exists_where: "Ne obstaja kje ..."
        }
      },
      logical_operators: {
        and: "In",
        or: "Ali"
      },
      property_placeholder: "Izberite lastnost",
      boolean: {
        true: "Da",
        false: "Ne"
      },
      date: {
        type: {
          today: "Danes",
          tomorrow: "Jutri",
          yesterday: "Včeraj",
          week_ago: "Pred enim tednom",
          week_from_now: "Čez en teden",
          month_ago: "Pred enim mesecem",
          month_from_now: "Čez en mesec",
          now: "Zdaj",
          hour_ago: "Pred eno uro",
          hour_from_now: "Čez en mesec",
          "12h_ago": "Pred 12 urami",
          "12h_from_now": "Čez 12 ur",
          this_year: "To leto",
          year_ago: "Pred enim letom",
          year_from_now: "Čez eno leto",
          date: "Točen datum",
          datetime: "Točen datum in ura",
          time: "Točen čas",
          month: "Točen mesec",
          year: "Točno leto"
        }
      },
      relation: {
        results_count: "Število najdenih rezultatov"
      },
      add_filter_button: "Dodajte filter",
      add_filter_group_button: "Dodajte skupino filtrov",
      actions: {
        duplicate: "Podvoji",
        turn_into_group: "Spremenite v skupino",
        release_group: "Izpusti skupino",
        remove: "Odstrani"
      }
    },
    pantheon_widget: {
      title: "Pantheon",
      saved: {
        title: "Shranjeno v Panteonu",
        description: "{title} je bil uspešno shranjen v Pantheon dne {date} ob {time}.\n",
        button: "Shranite zdaj"
      },
      not_saved: {
        title: "Ni shranjeno v Pantheonu",
        description: "{title} še ni shranjen v Panteonu.",
        button: "Shrani v Pantheon"
      },
      error: {
        title: "Napaka pri sinhronizaciji Pantheon",
        description: "Sinhronizacija Pantheon je naletela na napako. To je lahko posledica tega, da Pantheon v času sinhronizacije ni bil na voljo.\nNazadnje uspešno shranjevanje se je zgodilo {date}, ob {time}.\nPoskusite znova shraniti. Če težava ni odpravljena, se obrnite na skrbnika.",
        button: "Shrani v Pantheon"
      }
    },
    query_builder_form_section: {
      title: "Filter",
      description: "Za zoženje rezultatov uporabite filtre.",
      add_button: "Nastavi filtre",
      edit_button: "Urejanje filtrov",
      remove_button: "Odstranite filtre",
      popup_title: "Poiščite predmete, kjer ..."
    },
    notify: {
      error: "Prišlo je do napake"
    }
  },
  support_popup: {
    title: "Opis težave",
    terms_and_conditions1: "Želim podporo in se strinjam s",
    terms_and_conditions2: "pogoji",
    terms_and_conditions: "Želim podporo in se strinjam s pogoji",
    success: "Zahteva je bila poslana.",
    error: "Prišlo je do napake.",
    fields: {
      message: "Sporočilo"
    },
    button: {
      send: "Pošlji"
    }
  },
  top_bar_messages: {
    missing_sms_and_mail_amounts: "Manjkajo sredstva SMS in Mail. Obvestila po pošti in SMS ne bodo poslana. Posodobite svojo naročnino, da omogočite obvestila po pošti in SMS.",
    missing_sms_amounts: "Manjkajoča sredstva SMS. SMS obvestila ne bodo poslana. Posodobite svojo naročnino, da omogočite obvestila SMS.",
    missing_mail_amounts: "Manjkajoča sredstva Mail. Obvestila po pošti ne bodo poslana. Posodobite svojo naročnino, da omogočite obvestila po pošti."
  },
  trial_bar_messages: {
    expire: "Vaša licenca bo potekla v",
    day: "dnevu",
    days: "dneh",
    redirect: "Licenco lahko podaljšate s klikom na povezavo.",
    contact_admin: "Kontaktirajte administratorja",
    extend_licence: "za podaljšanje vaše licence."
  },
  top_bar_with_url: {
    click_here: "Kliknite tukaj"
  }
}
