export default {
  views: {
    list: {
      header: {
        title: "Newsletter lists",
        search_placeholder: "Search ...",
        add_button: "Add newsletter list"
      },
      table: {
        name: "Title",
        description: "Description",
        color: "Color",
        data_is_missing: "Table is empty"
      },
      popup: {
        subscribe: "Subscribe to"
      }
    },
    details: {
      header: {
        back_button: "Newsletter lists list"
      },
      main: {
        overview: {
          description: "Description"
        }
      },
      subscribed_list: {
        title: "Subscribed emails",
        id: "Id",
        email: "Email"
      }
    }
  },
  popup: {
    title: "New Newsletter",
    fields: {
      title: {
        label: "Title"
      },
      description: {
        label: "Description"
      }
    },
    add_button: "Add newsletter list"
  },
  notify: {
    stored: "Newsletter {title} created",
    updated: "Newsletter {title} updated",
    deleted: "Newsletter {title} was deleted",
    deleted_multiple: "Newsletters were deleted",
    not_found: "Unable to find specified newsletter"
  }
}
