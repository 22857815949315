import store from "@/providers/store"
/**
 * Route guard
 * We will set guards here, for checking if
 * the user has the right access level for
 * entering a specific route
 */

async function initStateHelper () {
  try {
    const response = await store.state.init
    return response
  } catch (e) {

  }
}

export async function ifAuthenticated (to, from, next) {
  /**
   * Not sure why this works, but it does.
   * Without "store.state.init" auth user is not
   * redirected from login page (if you go there after login)
   */
  await initStateHelper()

  if (store.getters["Auth/isAuthenticated"]) {
    const hasAccessToTenant = store.state.Auth.user.has_access_to_tenant
    const hasActiveTrial = store.state.Auth.user.has_active_trial
    const hasSubscription = store.state.Auth.user.has_subscription
    const hasActiveSubscription = store.state.Auth.user.has_active_subscription
    const navigationPermissions = store.state.Auth.user.navigation_permissions || {}
    const userPlans = store.state.Auth?.user?.plans?.map(plan => plan.key) || []
    const plansContainALicence = userPlans.includes("starter_licence") || userPlans.includes("standard_licence")

    if (hasActiveSubscription && !plansContainALicence) {
      return next("/missing-licence")
    }

    if (hasAccessToTenant) {
      const routePrefix = to.fullPath.split("/")[1]
      if (Object.keys(navigationPermissions).includes(routePrefix)) {
        if (navigationPermissions[routePrefix]) {
          return next()
        } else {
          return next("/missing-permission")
        }
      } else {
        return next()
      }
    } else if (hasSubscription && !hasActiveSubscription) {
      return next("/subscription-not-paid")
    } else if (!hasSubscription && !hasActiveTrial) {
      return next("/trial-expired")
    }

    return next("/not-found")
  }
  next("/login")
}

export async function ifNotAuthenticated (to, from, next) {
  await initStateHelper()

  if (!store.state.Auth.user.id) {
    const subdomain = window.location.host.split(".")[0]
    const tenantExistsResponse = await window.axios.post("/licensing/tenants/exists", { url_subdomain: subdomain })

    if (!tenantExistsResponse.data.exists) {
      next("/tenant-does-not-exist")
      return
    }
  }

  if (to.path.startsWith("/login") && store.getters["Auth/isAuthenticated"]) {
    next("/")
    return
  }

  if (!store.getters["Auth/isAuthenticated"]) {
    next()
    return
  }
  next(document.location.pathname)
}
