import RequestAction from "@/providers/api/sourceDrivers/webApiDriver/actionTypes/requestAction"

export default class GetDetailsAction extends RequestAction {
  /**
   * Retrieves item details from api
   * commits mutations to write the details to state
   *
   * @param {number} id - Id of the item we want to get details for
   * @param {object} params - Additional request specific params
   *
   * @returns {object} Response status and data
   */
  async handle ({ commit, state }, { id, params }) {
    let res = await this.request({ namespace: "getDetails" }).get(`${state.route}/${id}`, { params: {
      // Load default params
      ...state.detailsDefaultParams,
      // Load other request specific params
      ...params
    } })
    if (res.isError) return res
    commit("SET_DETAILS", res.data)
    return res
  }
}
