export default {
  SubscriptionNotPaid: {
    title: "Naročnina ni plačana",
    subtitle: "Oops ...",
    content: "Videti je, da ste pozabili plačati naročnino. Za nadaljevanje plačajte naročnino.",
    to_licensing: "Preverite naročnino",
    to_home: "Domov"
  },
  TrialExpired: {
    title: "Preizkus je potekel",
    subtitle: "",
    content: "Vaš preizkus je potekel. Za nadaljevanje ustvarite novo naročnino.",
    to_licensing: "Ustvarite naročnino",
    to_home: "Domov"
  },
  TenantDoesNotExist: {
    title: "CRM podjetja ne obstaja",
    subtitle: "",
    content: "Ta najemnik ne obstaja. Ta url lahko uporabite za svojega najemnika.",
    to_licensing: "Ustvarite CRM podjetja"
  }
}
