import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/travel-orders",
    name: "Travel Orders",
    meta: { title: "Travel Orders" },
    component: () => import(/* webpackChunkName: "Travel Orders List" */ "@/Modules/TravelOrder/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/travel-orders/details/:id",
    name: "Travel Order Details",
    component: () => import(/* webpackChunkName: "Travel Order Details" */ "@/Modules/TravelOrder/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "customer/new",
        name: "Travel Order New Customer Details"
      }
    ]
  },
  {
    path: "/travel-orders/new",
    name: "New Travel Order",
    component: () => import(/* webpackChunkName: "New Travel Order" */ "@/Modules/TravelOrder/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "customer/new",
        name: "Travel Order New Customer"
      }
    ]
  }
]
