import Vue from "vue"
import LaravelResourceRepository from "@/providers/api/repositories/LaravelResourceRepository"

export default class Settings extends LaravelResourceRepository {
  route = "settings-alt"
  namespace = "settings"
  identifier = "id"
  defaultKey = "App"

  state = {
    userSettings: {},
    systemSettings: {}
  }

  actions = {
    /**
     * Gets user settings or system if user's don't exist
     */
    get: async ({ commit }, params) => {
      if (typeof params !== "object") params = { key: params }

      const response = await this.request({ namespace: "get" }).get(`/${this.route}/user`, { params })

      if (!response.isError && response?.data) {
        commit("SET_USER_SETTINGS", { key: params.key, data: response.data })
      }

      return response
    },
    /**
     * Gets system settings
     */
    getSystem: async ({ commit }, params) => {
      if (typeof params !== "object") params = { key: params }

      const response = await this.request({ namespace: "get" }).get("/settings/system", { params })

      if (!response.isError && response?.data) {
        commit("SET_SYSTEM_SETTINGS", { key: params.key, data: response.data })
      }

      return response
    },
    /**
     * Sets user settings
     */
    set: async (ctx, params) => {
      if (!params.key) params.key = this.defaultKey
      const response = await this.request({ namespace: "set" }).post(`/${this.route}/user`, params)

      return response
    },
    /**
     * Sets system settings
     */
    setSystem: async (ctx, params) => {
      if (!params.key) params.key = this.defaultKey
      const response = await this.request({ namespace: "setSystem" }).post("/settings/system", params)

      return response
    },
    /**
     * Cleares all settings in store
     */
    clearSettings: ({ commit }) => {
      commit("CLEAR_ALL_SETTINGS")
    }
  }

  mutations = {
    SET_USER_SETTINGS: (state, { key, data }) => {
      Vue.set(state.userSettings, key || this.defaultKey, data)
    },

    CLEAR_USER_SETTINGS: (state, key) => {
      if (!key) return
      Vue.remove(state.userSettings, key)
    },

    SET_SYSTEM_SETTINGS: (state, { key, data }) => {
      Vue.set(state.systemSettings, key || this.defaultKey, data)
    },

    CLEAR_SYSTEM_SETTINGS: (state, key) => {
      if (!key) return
      Vue.remove(state.systemSettings, key)
    },

    CLEAR_ALL_SETTINGS: (state) => {
      Vue.set(state, "userSettings", {})
      Vue.set(state, "systemSettings", {})
    }
  }
}
