import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/supply",
    name: "Supply",
    meta: { title: "Supply" },
    component: () => import(/* webpackChunkName: "Supply List" */ "@/Modules/Supply/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/supply/details/:id",
    name: "Supply Details",
    component: () => import(/* webpackChunkName: "Supply Details" */ "@/Modules/Supply/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "user/new",
        name: "Supply New User Details"
      },
      {
        path: "customer/new",
        name: "Supply New Customer Details",
        children: [
          {
            path: "postal/new",
            name: "Supply New Customer Details New Postal"
          }
        ]
      }
    ]
  },
  {
    path: "/supply/new",
    name: "New Supply",
    component: () => import(/* webpackChunkName: "Supply Details" */ "@/Modules/Supply/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "user/new",
        name: "Supply New User"
      },
      {
        path: "customer/new",
        name: "Supply New Customer",
        children: [
          {
            path: "postal/new",
            name: "Supply New Customer New Postal"
          }
        ]
      }
    ]
  }
]
