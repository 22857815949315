import RequestAction from "@/providers/api/sourceDrivers/webApiDriver/actionTypes/requestAction"

export default class RemoveAction extends RequestAction {
  /**
   * Deletes a item record
   *
   * @param {object} context - The vuex context object
   * @param {object} context.state - The module state
   * @param {number} id - Id pf the item record we wish to delete
   *
   * @returns {object} Response status and data
   */
  async handle ({ state }, id) {
    let res = await this.request({ namespace: "remove", loaderArrayValue: id }).delete(`${state.route}/${id}`)
    return res
  }
}
