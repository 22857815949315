export default {
  views: {
    list: {
      header: {
        title: "Postavitve",
        search_placeholder: "Iskanje ...",
        add_button: "Ustvari novo postavitev"
      },
      table: {
        data_is_missing: "Tabela je prazna",
        columns: {
          title: "Naslov",
          key: "Pogled",
          last_modified: "Nazadnje spremenjeno"
        }
      }
    },
    builder: {
      header: {
        back_button: "Nazaj",
        default_layout_button: "Nastavite privzeto postavitev",
        access: {
          everyone: "Na voljo vsem",
          selected: "Na voljo izbranim",
          select: "Izberite, kdo lahko uporablja to postavitev"
        }
      },
      layout: {
        remove_column_button: "Odstrani stolpec",
        add_column_button: "Dodaj stolpec",
        remove_row_button: "Odstrani vrstico",
        add_row_button: "Dodaj vrstico",
        type: "Postavitev",
        types: {
          columns: "Preprosta postavitev",
          grid: "Mrežna postavitev"
        },
        width: "Širina",
        add_widget_button: "Postavite {widget} sem",
        move_widget_button: "Premakni {widget}",
        remove_widget_button: "Odstrani {widget}",
        widget_settings_button: "{widget} Nastavitve",
        widget_settings: {
          title: "{title} Nastavitve",
          confirm_button: "Shrani",
          cancel_button: "Prekliči"
        }
      },
      widgets: {
        title: "Pripomočki"
      },
      footer: {
        undo_button: "Razveljavi vse spremembe",
        save_button: "Shrani spremembe",
        save_button_saved: "Shranjeno"
      },
      notify: {
        update_success: "{title} uspešno posodobljen",
        save_error: "Med shranjevanjem je prišlo do napake",
        widget_missing: "Pripomoček, ki ga je uporabljala ta postavitev, ni več na voljo"
      },
      before_leave_alert: {
        title: "Spremembe, ki ste jih naredili, niso shranjene",
        message: "Zapustili boste to stran brez shranjevanja. Vaše spremembe bodo zavržene. Ste prepričani, da želite oditi?",
        confirm_button: "Zapusti",
        cancel_button: "Ostani na tej strani"
      }
    }
  },
  components: {
    popup: {
      title: "Ustvari novo postavitev",
      fields: {
        title: "Naslov",
        key: "Pogled",
        public: {
          title_on: "Javno",
          title_off: "Zasebno",
          description_on: "Postavitev je na voljo vsem",
          description_off: "Postavitev je na voljo samo izbranim uporabnikom"
        }
      },
      layout_titles: {
        "assembly-details": "Podrobnosti montaža",
        "claim-details": "Podrobnosti reklamacija",
        dashboard: "Nadzorna plošča",
        "invoice-details": "Podrobnosti račun",
        "notification-template-details": "Podrobnosti predloga obvestil",
        "opportunity-details": "Podrobnosti priložnost",
        "order-details": "Podrobnosti ponudba",
        "project-details": "Podrobnosti projekt",
        "subject-details": "Podrobnosti stranka",
        "subject-task-details": "Podrobnosti opravilo stranke",
        "supply-details": "Podrobnosti dobava",
        "task-details": "Podrobnosti opravilo",
        "travel-order-details": "Podrobnosti potni nalog"
      },
      notify: {
        add_success: "Uspešno ustvarjena postavitev {title}"
      },
      close_button: "Zapri",
      confirm_button: "Ustvarite postavitev"
    },
    access: {
      layout: "Postavitev",
      layout_text1: " je trenutno na voljo vsem za uporabo.",
      layout_text2: "Lahko ga naredite zasebnega in omogočite dostop določenim uporabnikom in skupinam.",
      make_private: "Naredi zasebno",
      make_public: "Spremeni v javno",
      revoke_access: "Prekliči dostop",
      grant_access: "Dovolite dostop",
      table: {
        name: "Ime",
        type: "Vrsta",
        members: "Člani"
      },
      prompts: {
        make_public: {
          title: "Naj bo {title} javno",
          message: "Postavitev {title} boste naredili na voljo vsem. Ali želite nadaljevati s tem dejanjem?",
          cancel: "Prekliči",
          confirm: "Objavi"
        },
        make_private: {
          title: "Naj bo {title} zaseben",
          message: "Omogočili boste, da bo postavitev {title} na voljo samo izbranim uporabnikom in skupinam. Ali želite nadaljevati s tem dejanjem?",
          cancel: "Prekliči",
          confirm: "Naredi zasebno"
        }
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste postavitev {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Postavitev {title} ustvarjena",
    updated: "Postavitev {title} posodobljena",
    deleted: "Postavitev {title} je bila izbrisana",
    deleted_multiple: "Postavitve so bile izbrisane",
    not_found: "Navedene postavitve ni mogoče najti"
  },
  default: "Privzeta postavitev"
}
