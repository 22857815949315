export default {
  views: {
    list: {
      header: {
        title: "Predloge obvestil",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj predlogo obvestila"
      },
      table: {
        title: "Naslov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnja sprememba",
        active: "Aktiven",
        data_is_missing: "Seznam je prazen"
      }
    },
    details: {
      header: {
        back_button: "Seznam s predlogami obvestil",
        edit_button: "Uredi predlogo obvestila",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tukaj lahko {action} predlogo obvestila"
        }
      },
      main: {
        title: "Glavne podrobnosti",
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Zadnja sprememba"
        },
        basic: {
          headline: "Informacija",
          title: "Naslov",
          type: "Tip",
          subject: "Stranka"
        }
      }
    },
    edit: {
      header: {
        details_back_button: "Nazaj na podrobnosti",
        list_back_button: "Seznam predlog obvestil",
        title: "Nova predloga obvestila",
        save_button: "Shrani predlogo obvestila",
        add_button: "Dodaj predlogo obvestila",
        remove_button: "Izbriši"
      },
      menu: {
        title: "Naslov",
        customer: "Zadeva"
      },
      footer: {
        save_button: "Shrani predlogo obvestila"
      }
    }
  },
  popup: {
    title: "Nova preloga obvestila",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj predlogo obvestila",
    update_button: "Posodobi predlogo obvestila"
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Predloga obvestila {title} ustvarjena",
    updated: "Predloga obvestila {title} posodobljena",
    deleted: "Predloga obvestila {title} je bila izbrisana",
    deleted_multiple: "Predloge obvestil so bile izbrisane",
    not_found: "Navedene predloge obvestila ni mogoče najti"
  }
}
