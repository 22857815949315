<template>
    <div class="default-popup support-popup">
        <UikPopup
            v-if="visible"
            visible
            @close="close()"
            @confirm="confirm()"
            @mounted="mounted()"
            @beforeDestroy="beforeDestroy()"
        >
            <UikTopBarTitle is="h2">{{ $t("Global.support_popup.title") }}</UikTopBarTitle>
            <div class="uik-popup__section">
                <UikFormInputGroup direction="horizontal">
                    <UikInput
                        ref="mainInput"
                        v-model="form.message"
                        v-validate="'required:true'"
                        required
                        :label="$t('Global.support_popup.fields.message')"
                        :name="$t('Global.support_popup.fields.message')"
                        :errorMessage="errors.first($t('Global.support_popup.fields.message')) || getFormError($t('Global.support_popup.fields.message'))"
                        component=textarea
                        :rows="5"
                    />
                </UikFormInputGroup>
            </div>
            <div class="uik-popup__section terms-and-conditions">
                <UikFormInputGroup class="form-input__wrapper">
                    <UikFormInputGroup direction="horizontal">
                        <UikContainer horizontal centerX>
                            <UikCheckbox
                                v-validate="'required:true'"
                                :value="form.accepted_terms_and_conditions"
                                :name="$t('Global.support_popup.terms_and_conditions')"
                                :errorMessage="errors.first($t('Global.support_popup.terms_and_conditions')) || getFormError($t('Global.support_popup.terms_and_conditions'))"
                                color="primary"
                                @change="form.accepted_terms_and_conditions = !form.accepted_terms_and_conditions"
                            />
                            <p class="support-popup__terms-checkbox-text">
                                <button @click="form.accepted_terms_and_conditions = !form.accepted_terms_and_conditions">{{$t('Global.support_popup.terms_and_conditions1')}} </button>
                                <a href="https://acronx.com/legal/" target="_blank">
                                    <button class="support-popup__terms-link">{{ $t('Global.support_popup.terms_and_conditions2') }}</button>
                                </a>
                            </p>
                        </UikContainer>
                    </UikFormInputGroup>
                    <div v-if="errors.first($t('Global.support_popup.terms_and_conditions'))">
                        <p class="uik-input__errorMessage">{{ errors.first($t('Global.support_popup.terms_and_conditions')) }}</p>
                    </div>
                    <UikFormInputGroup direction="horizontal">
                        <UikButton
                            :text="$t('Global.close')"
                            transparent
                            @click="close()"
                        />
                        <UikButton
                            :text="$t('Global.support_popup.button.send')"
                            :disabled="!isValid || $api.Auth.GetLoader('sendEmailToSupport')"
                            :is-loading="$api.Auth.GetLoader('sendEmailToSupport')"
                            primary
                            @click="confirm()"
                        />
                    </UikFormInputGroup>
                </UikFormInputGroup>
            </div>
        </UikPopup>
        <UikOverlay :visible="visible" @close="close()"/>
    </div>
</template>

<script>
import GetProperty from "@/Mixins//GetProperty"
import GetFormError from "@/Mixins/Utils/Features/Form/GetFormError"

export default {
  mixins: [ GetProperty, GetFormError ],
  props: {
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      form: {
        message: "",
        accepted_terms_and_conditions: false
      },
      validate: ["message", "accepted_terms_and_conditions"]
    }
  },
  computed: {
    isValid () {
      const properties = this.validate
      const data = this.form

      if (properties && properties.length && data) {
        for (let i = 0; i < properties.length; i++) {
          const property = properties[i]
          const value = this.getProperty(property, data)
          if (!value && value !== 0) return false
        }
      }

      return true
    }
  },
  methods: {
    close () {
      this.$emit("close")
    },
    mounted () {
      this.focusMainInput()
    },
    beforeDestroy () {
      this.clearForm()
    },
    focusMainInput () {
      this.$nextTick(() => {
        const input = this.$refs.mainInput
        if (input) {
          try { input.$el.getElementsByTagName("input")[0].focus() } catch {}
        }
      })
    },
    clearForm () {
      this.form = {
        message: "",
        accepted_terms_and_conditions: false
      }
    },
    async confirm () {
      let response = await this.$api.Auth.sendEmailToSupport(this.form)
      if (response.status !== 200 && response.status !== 201) {
        this.$notify.error(this.t("Global.support_popup.error"))
        return
      }

      this.$notify.success(this.t("Global.support_popup.success"))
      this.close()
    }
  }
}
</script>

<style lang="scss">
.support-popup {
  .terms-and-conditions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .form-input__wrapper {
      width: 100%;
    }
    .support-popup__terms-checkbox-text {
      display: inline-block;
      margin-top: -3px;
      margin-left: 2px;
      button {
        background: transparent;
        padding: 0;
        margin: 0;
        border: none;
        cursor: pointer;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        display: inline;
        &.support-popup__terms-link {
          color: $secondary;
          text-decoration: underline;
          padding-left: 6px;
          &:hover {
            color: $primary;
          }
          &:active {
            color: rgba($primary, 0.5);
          }
        }
      }
    }
  }
}
</style>
