export default {
  layouts: {
    customer: {
      title: "Customer"
    },
    accounting_items: {
      title: "Accounting items"
    },
    accounting_summary: {
      title: "Accounting summary"
    },
    accounting_costs: {
      title: "Accounting costs"
    },
    original_relation: {
      title: "Original relation"
    },
    creation_info: {
      title: "Creation info"
    },
    payments: {
      title: "Payments"
    },
    relations: {
      title: "Relations"
    }
  },
  confirm: "Confirm",
  cancel: "Cancel",
  close: "Close",
  add: "Add",
  save: "Save",
  remove: "Remove",
  delete: "Delete",
  open: "Open",
  edit: "Edit",
  duplicate: "Duplicate",
  activate: "Activate",
  deactivate: "Deactivate",
  none: "None",
  logged_in_user: "Logged-in user",
  passwordRule: "Password has to be at least 6 characters long <br/> and contain a letter, a number and a symbol(!@#$%^&*?-=+_)",
  customer: {
    customer: "Customer",
    recipient: "Recipient",
    select_subject: "Select customer",
    select_recipient: "Select recipient",
    name: "Name",
    address: "Address",
    email: "E-mail",
    phone: "Phone number",
    user: {
      no_results_message: "This User does not exist",
      no_results_button: "Create new user"
    },
    subject_no_result_data: {
      no_results_message: "This Customer does not exist",
      no_results_button: "Create new Customer"
    },
    add_customer: "Add new customer"
  },
  colors: {
    red: "Red",
    orange: "Orange",
    yellow: "Yellow",
    lime: "Lime",
    green: "Green",
    cyan: "Cyan",
    blue: "Blue",
    purple: "Purple",
    pink: "Pink",
    gray: "Gray"
  },
  menu: {
    views: {
      dashboard: "Dashboard",
      entities: "Entities",
      tasks: "Tasks",
      planning: "Planning",
      subjects: "Customers",
      projects: "Projects",
      invoices: "Invoices",
      "advanced-invoices": "Advanced Invoices",
      orders: "Orders",
      supply: "Supply",
      assemblies: "Assembly",
      "travel-orders": "Travel Orders",
      opportunities: "Opportunities",
      checklists: "Checklists",
      idents: "Idents",
      reports: "Reports",
      calendar: "Calendar",
      administration: "Administration",
      kpis: "KPIs",
      maps: "Map",
      claims: "Reclamations",
      data: "Data",
      documents: "Documents",
      organization: "Organization"
    }
  },
  components: {
    popups: {
      payment_popup: {
        title: "Payment",
        amount: "Amount",
        payment_date: "Payment date",
        payment_method: "Payment method",
        currency: "Currency",
        cancel_button: "Cancel",
        save_button: "Save"
      }
    },
    payment: {
      title: "Payments",
      days_for_payment: "Days for payment",
      payment_method: "Payment method",
      price_rate: "Price rate",
      discount: "Discount",
      vat_base: "VAT Base",
      vat_amount: "VAT Amount",
      for_pay: "For pay",
      reference: "Reference",
      ext: "EXT",
      ext_provider: "EXT Provider",
      vat_date: "Date of payment",
      due_date: "Due date",
      table: {
        payment_date: "Payment date",
        payment_method: "Payment method",
        amount: "Amount",
        open: "Open",
        delete: "Delete",
        add: "Add",
        currency: "Currency",
        no_currency_message: "No currency",
        no_payment_method_message: "No payment method",
        sum: "Sum"
      }
    },
    creation_info: {
      creation_info: "Creation Info",
      created_at: "Date created",
      created_by: "User author",
      updated_at: "Date modified",
      updated_by: "User last modified"
    },
    contact_info: {
      address: {
        title: "Address",
        remove_button: "Remove address",
        add_button: "Add address",
        address: "Address",
        postal: "Postal",
        add_postal: "Add postal",
        city: "City",
        country: "Country"
      },
      contact: {
        title: "Contact",
        remove_button: "Remove contact",
        add_button: "Add contact",
        contact: "Contact",
        phone: "Phone number",
        email: "E-mail",
        any: "Contact",
        type: "Type",
        label: "Additional label",
        phone_country_code: "Country code"
      }
    },
    multiple_entries_check: {
      label: "Wish to add another?",
      description: "Form will stay open after confirmation"
    },
    recurrence: {
      form: {
        type: "Interval",
        types: {
          hourly: "Hourly",
          daily: "Daily",
          weekly: "Weekly",
          monthly: "Monthly",
          yearly: "Yearly"
        },
        recurrence_headline: "Recurrence {number}",
        remove_button: "Remove recurrence",
        add_button: "Add recurrence",
        time: "Time",
        minutes: "Minutes",
        weekday: "Day",
        weekdays: {
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          sunday: "Sunday"
        },
        day: "Day",
        first_day_of_month: "First day of the month",
        last_day_of_month: "Last day of the month",
        month: "Month",
        months: {
          january: "January",
          february: "February",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          september: "September",
          october: "October",
          november: "November",
          december: "December"
        }
      }
    },
    query_builder: {
      title: "Find {models} where ...",
      type: {
        string: "Text",
        number: "Number",
        boolean: "Boolean",
        date: "Date",
        enum: "Enum",
        relation: "Relation"
      },
      comparison_operators: {
        string: {
          "=": "Is",
          "!=": "Is not",
          contains: "Contains",
          starts_with: "Starts with",
          ends_with: "Ends with",
          is_empty: "Is empty",
          is_not_empty: "Is not empty"
        },
        number: {
          "=": "=",
          "!=": "≠",
          "<": "<",
          ">": ">",
          "<=": "≤",
          ">=": "≥",
          exists: "Exists",
          not_exists: "Doesn't exist"
        },
        boolean: {
          "=": "Is",
          "!=": "Is not"
        },
        date: {
          "=": "Is",
          "!=": "Is not",
          "<": "Is before",
          ">": "Is after",
          "<=": "Is on or before",
          ">=": "Is on or after",
          exists: "Exists",
          not_exists: "Doesn't exist"
        },
        enum: {
          "=": "Is",
          "!=": "Is not",
          is_empty: "Is empty",
          is_not_empty: "Is not empty"
        },
        relation_one: {
          exists: "Exists",
          not_exists: "Doesn't exist",
          exists_where: "Exists where...",
          not_exists_where: "Doesn't exist where..."
        },
        relation_many: {
          exists: "Exist",
          not_exists: "Don't exist",
          exists_where: "Exist where...",
          not_exists_where: "Don't exist where..."
        }
      },
      logical_operators: {
        and: "And",
        or: "Or"
      },
      property_placeholder: "Select a property",
      boolean: {
        true: "Yes",
        false: "No"
      },
      date: {
        type: {
          today: "Today",
          tomorrow: "Tomorrow",
          yesterday: "Yesterday",
          week_ago: "One week ago",
          week_from_now: "One week from now",
          month_ago: "One month ago",
          month_from_now: "One month from now",
          now: "Now",
          hour_ago: "One hour ago",
          hour_from_now: "One month from now",
          "12h_ago": "12 hours ago",
          "12h_from_now": "12 hours from now",
          this_year: "This year",
          year_ago: "One year ago",
          year_from_now: "One year from now",
          date: "Exact date",
          datetime: "Exact date and time",
          time: "Exact time",
          month: "Exact month",
          year: "Exact year"
        }
      },
      relation: {
        results_count: "Number of results found"
      },
      add_filter_button: "Add a filter",
      add_filter_group_button: "Add a filter group",
      actions: {
        duplicate: "Duplicate",
        turn_into_group: "Turn into group",
        release_group: "Release group",
        remove: "Remove"
      }
    },
    pantheon_widget: {
      title: "Pantheon",
      saved: {
        title: "Saved in Pantheon",
        description: "{title} has been successfully saved in Pantheon on {date}, at {time}.\n",
        button: "Save now"
      },
      not_saved: {
        title: "Not saved in Pantheon",
        description: "{title} has not yet been saved in Pantheon.",
        button: "Save in Pantheon"
      },
      error: {
        title: "Pantheon sync error",
        description: "Pantheon sync has encountered an error. This could be due to Pantheon being unavailable at the time of syncing.\nLast successfull save happened on {date}, at {time}.\nTry saving again. If the issue isn't resolved, contact the administrator.",
        button: "Save in Pantheon"
      }
    },
    query_builder_form_section: {
      title: "Filter",
      description: "Use filters to narrow the results.",
      add_button: "Set filters",
      edit_button: "Edit filters",
      remove_button: "Remove filters",
      popup_title: "Find items where ..."
    },
    notify: {
      error: "Error occured"
    }
  },
  support_popup: {
    title: "Problem description",
    terms_and_conditions1: "I want support and agree with",
    terms_and_conditions2: "terms and conditions",
    terms_and_conditions: "I want support and agree with terms and conditions",
    success: "The request has been sent.",
    error: "Error occured.",
    fields: {
      message: "Message"
    },
    button: {
      send: "Send"
    }
  },
  top_bar_messages: {
    missing_sms_and_mail_amounts: "Missing SMS and Mail assets. Mail and SMS notifications will not be sent. Update your subscription to enable Mail and SMS notifications.",
    missing_sms_amounts: "Missing SMS assets. SMS notifications will not be sent. Update your subscription to enable SMS notifications.",
    missing_mail_amounts: "Missing Mail assets. Mail notifications will not be sent. Update your subscription to enable Mail notifications."
  },
  trial_bar_messages: {
    expire: "Your licence will expire in",
    day: "day",
    days: "days",
    redirect: "You can extend your licence by following the link.",
    contact_admin: "Contact administrator",
    extend_licence: "to extend your licence."
  },
  top_bar_with_url: {
    click_here: "Click here"
  }
}
