export default {
  computed: {
    /**
     * Gets util config
     *
     * @returns {object} config
     */
    utilConfig: {
      get () {
        return this.$options.utils || {}
      },
      set (value) {
        this.$options.utils = value
      }
    },
    /**
     * Gets resourse used by utils
     *
     * @returns {object} Resource
     */
    utilResource () {
      return this.$api[this.utilConfig.resource]
    }
  },
  methods: {
    /**
     * Sets config properties for specific util.
     *
     * @param {string} util - name of util
     * @param {object} props - properties and values to set
     * @param {boolean} overwrite - overwrite properties if they exist
     *
     * @returns {object} config
     */
    setUtilConfig (util, props, overwrite = false) {
      if (util && !this.utilConfig[util]) this.utilConfig[util] = {}

      for (const prop in props) {
        if (util) {
          if (this.utilConfig[util][prop] === undefined || overwrite) {
            this.utilConfig[util][prop] = props[prop]
          }
        } else {
          if (this.utilConfig[prop] === undefined || overwrite) {
            this.utilConfig[prop] = props[prop]
          }
        }
      }

      return this.utilConfig
    }
  }
}
