export default {
  layouts: {
    details: {
      title: "Glavne podrobnosti"
    },
    delivery: {
      title: "Dostava"
    }
  },
  views: {
    list: {
      header: {
        title: "Dobava",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj ponudbo"
      },
      table: {
        number: "Številka",
        number_view: "Prikaz številke",
        subject: "Stranka",
        status: "Status",
        supply_date: "Datum",
        copy: "Kopiraj",

        selected_items: {
          selected: "Izbrano",
          items: "računi",
          remove: "Odstrani izbiro",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          item1: "dobava",

          // Duality
          selected2: "Izbrano",
          item2: "dobave",

          // Plural
          selected3: "Izbrano",
          item3: "dobave"
        },
        data_is_missing: "Seznam je prazen"
      }
    },
    details: {
      header: {
        title: "Nova dobava",
        back_button: "Seznam dobav",
        invoice_button: "Ustvarite račun"
      },
      footer: {
        remove_button: "Izbrišite dobavo",
        add_button: "Dodaj dobavo",
        save_button: "Shranite dobavo"
      },
      main: {
        title: "Glavne informacije"
      },
      properties: {
        main: {
          title: "Nova dobava",
          number: "Številka dobave",
          number_view: "Prikaz številke",
          subject: {
            label: "Stranka",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvari novo stranko"
          },
          supply_date: "Datum dobave",
          date_valid: "Končni datum",
          status: "Status",
          department: {
            label: "Oddelek",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvarite nov oddelek"
          },
          note: "Opomba",
          doc_type: "Tip dokumenta",
          created_from_opportunity: "Izdelano iz priložnosti",
          responsible_person: "Odgovorna oseba",
          user: {
            no_results_message: "Ta uporabnik ne obstaja",
            no_results_button: "Ustvarite novega uporabnika"
          }
        },
        delivery: {
          title: "Dostava",
          receiver: {
            label: "Dostavi do",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvari novo stranko"
          },
          delivery_deadline: "Dobavni rok",
          delivery_type: "Vrsta dostave",
          department_code: "Šifra oddelka",
          sale_method: "Način prodaje",
          amount: "Znesek"
        },
        payment: {
          title: "Plačilo",
          days_for_payment: "Dnevi za plačilo",
          payment_method: "Način plačila",
          price_rate: "Stopnja cene",
          discount: "Popust",
          vat_base: "Osnova za DDV",
          vat_amount: "Znesek DDV",
          for_pay: "Za plačilo",
          reference: "Referenca",
          ext: "EXT",
          ext_provider: "EXT Ponudnik"
        },
        accounting_summary: {
          title: "Računovodski povzetek"
        },
        accounting_items: {
          title: "Računovodske postavke"
        },
        accounting_costs: {
          title: "Računovodski stroški"
        }
      },
      relations: {
        tabs: {
          opportunities: "Priložnosti"
        },
        add_buttons: {
          opportunities: "Nove priložnosti"
        }
      },
      custom_fields: {
        title: "Polja po meri"
      },
      customer: {
        title: "Stranka",
        name: "Ime",
        address: "Naslov",
        email: "E-mail",
        phone: "Telefonska številka"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbriši dobavo",
      message: "Izbrisali boste dobavo {number}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    create_invoice: {
      title: "Ustvarite račun iz dobave",
      message: "Iz te dobave bo ustvarjen nov račun. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Ustvari račun"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Dobava shranjena",
    updated: "Dobava posodobljena",
    deleted: "Dobava {number} je bila izbrisana",
    deleted_multiple: "Dobave so bile izbrisane",
    not_found: "Navedene dobave ni mogoče najti"
  },
  status: {
    draft: "Osnutek",
    "in-progress": "V izdelavi",
    completed: "Dokončano"
  }
}
