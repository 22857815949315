import Connector from "@/providers/api/connectors/connector"
import WebApiDriver from "@/providers/api/sourceDrivers/WebApiDriver"
import VuexDriver from "@/providers/api/storageDrivers/VuexDriver"
import store from "@/providers/store"

export default class VuexApiResource extends Connector {
  namespace = "VuexApiConnector"

  registrationMethod = "createStore"
  unregistrationMethod = "destroyStore"

  constructor () {
    super()
    this.loadClassExtensions()
    this.storageDriver = new VuexDriver()
    this.sourceDriver = new WebApiDriver(this.ctxProxy)
    this.extendCtx()
    this.sourceDriver.register({}, this.ctxProxy)
    this.storageDriver.register({}, this.ctxProxy)
    this.api = this.sourceDriver.api
    this.request = this.api.request
  }

  register (template, ctx) {
    this.template.extend({
      namespaced: this.namespaced,
      plugins: this.plugins || [],
      state: this.state || {},
      getters: this.getters || {},
      actions: this.actions || {},
      mutations: this.mutations || {}
    })
    this.extendCtx()
    this.extendRepository(template, ctx)
    return this
  }

  createStore (namespace) {
    this.register()
    this.template.extend({
      state: {
        namespace: namespace || this.namespace
      }
    })
    this.extendTemplate()
    this.namespace = namespace || this.namespace
    this.storageDriver.createStore(this.namespace, this.template)
    this.extendCtx()
    this.sourceDriver.register(this.template, this.ctxProxy)
    this.storageDriver.register(this.template, this.ctxProxy, this.namespace)
    this.sourceDriver.mapAllProperties(this)
    this.storageDriver.mapAllProperties(this)
    return this
  }

  destroyStore () {
    this.storageDriver.destroyStore(this.namespace)
  }

  extendCtx () {
    if (this.storageDriver.storeCreated) {
      let vuexContext = {}
      Object.getOwnPropertyNames(store._modules.root._children[this.namespace].context).forEach((property) => {
        vuexContext[property] = store._modules.root._children[this.namespace].context[property]
      })
      this.ctx = Object.assign(this.ctx, vuexContext)
    } else {
      this.ctx = Object.assign(this.ctx, {
        state: new Proxy(this.storageDriver.state, {
          get: function (target, prop) {
            return target[prop]
          },
          set: function () {
            return Reflect.set(...arguments)
          }
        }),
        getters: new Proxy(this.storageDriver.getters, {
          get: function (target, prop) {
            if (typeof target[prop] === "function") {
              return target[prop]()
            } else {
              return target[prop]
            }
          }
        }),
        commit: (mutation, params) => {
          return this.storageDriver.mutations[mutation](params)
        },
        dispatch: (action, params) => {
          return this.sourceDriver.actions[action](params)
        }
      })
    }
  }
}
