<template>
    <div v-if="updateAvailable" pointer class="updateAvailable notify notify-bottom-right" @click="helperUpdateRefresh()">
        <div>
            <div class="notify-item" style="display: block;">
                <div class="notification notification--info">
                    Na voljo je nova posodobitev
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * Helper that takes care of app updates
 * when update gets deployed service worker will
 * register it and it will send the signal to get
 * updated.
 *
 * There should be a prompt whitch gets shown to the user
 * and when it gets clicked, cache will be cleared and new
 * service worker will get installed.
 */

export default {
  data () {
    return {
      updateAvailable: false,
      updateRefreshing: false,
      updateRegistration: null
    }
  },
  mounted () {
    this.setEventListeners()
  },
  beforeDestroy () {
    this.removeEventListeners()
  },
  methods: {
    setEventListeners () {
      document.addEventListener("swUpdated", this.helperUpdateAvailable, { once: true })

      // Refresh all open app tabs when a new service worker is installed.
      if (navigator && navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (this.updateRefreshing) return
          this.updateRefreshing = true
          window.location.reload()
        })
      }
    },
    removeEventListeners () {
      document.removeEventListener("swUpdated", this.helperUpdateAvailable)
    },
    helperUpdateAvailable (event) {
      this.updateRegistration = event.detail
      this.updateAvailable = true
    },
    helperUpdateRefresh () {
      this.updateAvailable = false

      // Protect against missing registration.waiting.
      if (!this.updateRegistration || !this.updateRegistration.waiting) { return }
      this.updateRegistration.waiting.postMessage("skipWaiting")
      this.$notify("Posodobitev je bila uspešna")
    }
  }
}

</script>

<style lang="scss">
.updateAvailable {
  width: 300px;
  position: fixed;
  top: 10px;
  left: 50%;
  margin-left: -150px;
}
</style>
