export default {
  Views: {
    components: {
      report_popup: {
        drill_down_title: "Naslov razčlenitve",
        report_title: "Naslov poročila",
        view_title: "Naslov pogleda",
        relation: "Povezava",
        breadcrumb_identifier: "Identifikator poti",
        model: "Model",
        add_drilldown: "Dodaj razčlenitev",
        edit_drilldown: "Uredi razčlenitev",
        redirect_url: "Preusmeri URL",
        add_column: "Dodaj stolpec",
        cancel: "preklic",
        delete_view: "Izbriši pogled",
        delet_report: "Izbriši poročilo",
        save_view: "Shrani pogled",
        save_report: "Shrani poročilo",
        add_view: "Dodaj pogled",
        add_report: "Dodaj poročilo",

        report_column: {
          column: "Stolpec",
          title: "Naslov",
          filter_and_sort: "Filtrirajte in sortirajte",
          empty_message: "Kliknite, da izberete lastnost filtriranja in razvrščanja za ta stolpec",
          add_block: "Dodajte blok",

          components: {
            open: "Odpri",
            collapse: "Strnite",
            empty_message: "Kliknite, da izberete {text} vrednost"
          }
        }
      },

      table_wrapper: {
        view: "Pogled",
        edit_view: "Uredite pogled",
        export_csv: "Izvozite CSV",
        export_xlsx: "Izvozite XLSX"
      }
    },
    Details: {
      Components: {
        Main: {
          Components: {
            Overview: {
              "Author": "Avtor",
              "Created_at": "Ustvarjeno",
              "Last_modified": "Zadnja sprememba",
              "Updated_at": "Posodobljeno"
            }
          },
          index: {
            "Active": "Aktiven",
            "Inactive": "Neaktiven"
          }
        },
        Header: {
          "Search": "Iskanje ...",
          "Reports": "Poročila",
          "List": "Seznam",
          "Active": "Aktiven",
          "Edit": "Uredite",
          "Report": "Poročilo",
          "activate": "aktivirajte",
          "deactivate": "deaktivirajte",
          "You_can_action_Report_here": "Tu lahko {action} poročilo",
          "An_error_occurred_while_updating_Report": "Med posodabljanjem poročila je prišlo do napake",
          export_csv: "Izvozite CSV",
          export_xlsx: "Izvozite XLSX"
        },
        Report: {
          table: {
            actions: "Akcije"
          }
        }
      },
      index: {}
    },
    List: {
      Components: {
        Header: {
          "Search": "Iskanje ...",
          "Add_Report": "Dodaj poročilo"
        }
      },
      index: {
        "Title": "Naslov",
        "Created_At": "Ustvarjeno",
        "Updated_At": "Posodobljeno",
        "Author": "Avtor",
        "Last_Modified": "Zadnja sprememba",
        "Active": "Aktiven",
        "Open": "Odpri",
        "Edit": "Uredi",
        "Generate": "Ustvari",
        "Delete": "Izbriši",
        "An_error_occurred_while_updating_Report": "Med posodabljanjem poročila je prišlo do napake",
        "Successfully_updated_Report": "Uspešno posodobljeno poročilo",
        "Remove_Report": "Odstrani poročilo",
        "Youre_about_to_delete_Report_title_Would_you_like_to_proceed_with_this_action": "Izbrisali boste poročilo {title}. Ali želite nadaljevati s tem dejanjem?",
        "Cancel": "Prekliči",
        "Report_title_successfully_deleted": "Poročilo {title} uspešno zbrisano",
        "data_is_missing": "Seznam je prazen"
      }
    }
  }
}
