import Mutation from "@/providers/api/mutations/mutation"

export default class ClearFiltersMutation extends Mutation {
  /**
   * Clears filter options in the state
   *
   * @param {object} state - the module state
   * @param {object} route - The route we wish to clear the filters for
   */
  async handle (state, route) {
    let filters = JSON.parse(window.sessionStorage.getItem("filters")) || {}
    if (route) filters[state.namespace][route] = {}
    filters[state.namespace] = {}
    window.sessionStorage.setItem("filters", JSON.stringify(filters))
    state.filters = filters[state.namespace]
  }
}
