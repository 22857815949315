import StorageDriver from "@/providers/api/storageDrivers/storageDriver"
import store from "@/providers/store"

export default class VuexDriver extends StorageDriver {
  namespace = "vuexDriver"
  storeCreated = false

  createStore (namespace, template) {
    namespace = namespace || this.namespace
    template = template || this.namespace
    store.registerModule(namespace, template.template)
    this.state = store.state[namespace]
    this.storeCreated = true
  }

  destroyStore (namespace) {
    store.unregisterModule(namespace || this.namespace)
  }

  register (template = {}, ctx, namespace) {
    this.namespace = namespace || this.namespace
    this.template.extend(template)

    if (!this.storeCreated) {
      this.state = new Proxy(ctx.state, {
        get: function (target, prop) {
          return target[prop]
        },
        set: function () {
          return Reflect.set(...arguments)
        }
      })

      this.gettersHandlers = { ...this.template.template.getters }
      this.mutationsHandlers = { ...this.template.template.mutations }

      this.getters = new Proxy(this.gettersHandlers, {
        get: function (target, prop) {
          if (target.hasOwnProperty(prop)) {
            return new Proxy(target[prop], {
              apply: function (target, thisArg, argumentsList) {
                return target(argumentsList[0] ? argumentsList[0].state || ctx.state : ctx.state)
              }
            })
          }
        }
      })
      this.mutations = new Proxy(this.mutationsHandlers, {
        get: (target, prop) => {
          if (target.hasOwnProperty(prop)) {
            return new Proxy(target[prop], {
              apply: (target, thisArg, argumentsList) => {
                return target(argumentsList[1] || ctx.state, argumentsList[0])
              }
            })
          }
        }
      })
    } else {
      this.state = store.state[this.namespace]
      this.getters = new Proxy(store.getters, {
        get: function (target, prop) {
          if (typeof prop !== "string") {
            if (target.hasOwnProperty(prop)) {
              return target[prop]
            }
          } else {
            if (target.hasOwnProperty(this.namespace + "/" + prop)) {
              return target[this.namespace + "/" + prop]
            }
          }
        }
      })
      this.mutations = new Proxy(store.commit, {
        apply: (target, thisArg, argumentsList) => {
          return store.commit(this.namespace + "/" + target, argumentsList[0])
        }
      })
      this.actions = new Proxy(store.commit, {
        apply: (target, thisArg, argumentsList) => {
          return store.dispatch(this.namespace + "/" + target, argumentsList[0])
        }
      })
    }
  }

  mapProperties (type, target = this, template = this.template) {
    const propertyNames = Object.keys(template.template[type])
    for (let i = 0; i < propertyNames.length; i++) {
      const propertyName = propertyNames[i]
      if (this.storeCreated) {
        if (type === "state") {
          Object.defineProperty(target, propertyName, {
            get: () => {
              return store.state[this.namespace][propertyName]
            },
            configurable: true
          })
          continue
        }
        if (type === "getters") {
          Object.defineProperty(target, propertyName, {
            get: () => {
              return store.getters[`${this.namespace}/${propertyName}`]
            },
            configurable: true
          })
          continue
        }
        if (type === "mutations") {
          target[propertyName] = params => {
            return store.commit(`${this.namespace}/${propertyName}`, params)
          }
        }
        if (type === "actions") {
          target[propertyName] = params => {
            return store.dispatch(`${this.namespace}/${propertyName}`, params)
          }
          continue
        }
      } else {
        if (type === "state") {
          Object.defineProperty(target, propertyName, {
            get: () => {
              return this.state[propertyName]
            },
            configurable: true
          })
          continue
        }
        if (type === "getters") {
          target[propertyName] = state => {
            return this.getters[propertyName](state)
          }
          continue
        }
        if (type === "mutations") {
          target[propertyName] = (params, ctx) => {
            return this.mutations[propertyName](params, ctx)
          }
        }
      }
    }

    if (this[type]) {
      Object.defineProperty(target, type, {
        get: () => {
          return this[type]
        },
        configurable: true
      })
    }
  }
}
