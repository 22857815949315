import Mutation from "@/providers/api/mutations/mutation"

export default class ClearDetailsMutation extends Mutation {
  /**
   * Clears item details
   */
  async handle (state) {
    state.detailsLoaded = false
    state.details = {}
  }
}
