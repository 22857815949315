export default {
  "views": {
    "list": {
      "header": {
        "search_placeholder": "Iskanje ...",
        "add_button": "Nova polja po meri"
      },
      "table": {
        data_is_missing: "Tabela je prazna",
        "columns": {
          "title": "Naslov",
          "description": "Opis",
          "fields_count": "Št. polj",
          "models_count": "Uporabljeno na",
          "created_at": "Ustvarjeno",
          "updated_at": "Zadnja sprememba"
        },
        "actions": {
          "open": "Odprto",
          "delete": "Izbriši",
          "duplicate": "Podvoji"
        }
      },
      "prompts": {
        "delete": {
          "title": "Izbriši {title}",
          "content": "Izbrisali boste skupino {title} in vsa polja po meri, ki jih vsebuje. To dejanje bo odstranilo tudi vse podatke, shranjene v omenjenih poljih na aktivnih modelih. Ali želite nadaljevati s tem dejanjem?",
          "confirm_button": "Izbriši",
          "cancel_button": "Prekliči"
        }
      },
      "notify": {
        "duplicate_error": "Med podvajanjem je prišlo do napake",
        "duplicate_success": "{title} je uspešno dodan",
        "delete_success": "{title} uspešno izbrisan"
      },
      "duplicate_title_addition": " (duplicated)",
      "popup": {
        "title": "Nova polja po meri",
        "section_title": "Lastnosti skupine",
        "section_subtitle": "Ustvarjate novo skupino polj po meri.",
        "inputs": {
          "title": {
            "label": "Naslov",
            "placeholder": "Dodajte naslov za svojo skupino"
          },
          "description": {
            "label": "Opis",
            "placeholder": "Opišite namen polj po meri v tej skupini."
          },
          "use_providers": {
            "label": "Uporabite zunanje ponudnike na poljih",
            "description": "Polja ne bo mogoče urejati, ampak se bodo uporabljala le za prikaz podatkov zunanjih ponudnikov."
          }
        },
        "notify": {
          "delete_success": "{title} uspešno izbrisan",
          "update_success": "{title} je uspešno posodobljen",
          "save_error": "Med shranjevanjem je prišlo do napake",
          "add_success": "{title} je uspešno dodan",
          "save_success": "{title} uspešno shranjen"
        },
        "remove_button": "Izbriši skupino",
        "confirm_button": "Dodaj skupino",
        "update_button": "Posodobi skupino",
        "cancel_button": "Zapri",
        "prompts": {
          "delete": {
            "title": "Izbriši {title}",
            "content": "Izbrisali boste skupino {title} in vsa polja po meri, ki jih vsebuje. To dejanje bo odstranilo tudi vse podatke, shranjene v omenjenih poljih na aktivnih modelih. Ali želite nadaljevati s tem dejanjem?",
            "confirm_button": "Izbriši",
            "cancel_button": "Prekliči"
          }
        }
      }
    },
    "details": {
      "header": {
        "back_button": "Seznam polj po meri",
        "edit_button": "Uredi skupino"
      },
      "general": {
        "title": "Splošno",
        "subtitle": "Lastnosti skupine polj",
        "data": {
          "title": "Naslov",
          "description": "Opis"
        }
      },
      "models": {
        models: {
          "assembly": "Sestava",
          "user": "Uporabnik",
          "subject": "Stranka",
          "task": "Opravilo",
          "order": "Ponudba",
          "invoice": "Račun",
          "opportunity": "Priložnost",
          "claim": "Reklamacija",
          "project": "Projekt"
        },
        "title": "Modeli",
        "subtitle": "Uporaba polj na podatkovnih modelih",
        "remove_button_title": "Odstrani {title}",
        "add_text": "Izberite model ...",
        "prompts": {
          "remove": {
            "title": "Odstrani model {title}",
            "content": "Odstranili boste model {title}. Trajno bodo izbrisani tudi vsi podatki, shranjeni v poljih po meri pod to nastavitvijo. Ali želite nadaljevati s tem dejanjem?",
            "confirm_button": "Odstrani",
            "cancel_button": "Prekliči"
          }
        },
        "notify": {
          "remove_success": "Polja {schema} so bila odstranjena iz modela {title}",
          "remove_error": "Med odstranjevanjem modela {title} je prišlo do napake",
          "add_success": "Polja {schema} so bila uporabljena za model {title}",
          "add_error": "Pri uporabi modela {title} je prišlo do napake"
        }
      },
      "fields": {
        "title": "Polja",
        "untitled_field": "Polje brez naslova",
        "add_field_button": "Dodajte novo polje",
        "preview_fields_button": "Predogled vseh polj",
        "delete_field_button": "Izbriši polje",
        "types": {
          "string": "Besedilo",
          "enum": "Izberi",
          "integer": "Celo število",
          "decimal": "Decimalno število",
          "boolean": "Preklopnik",
          "date": "Datum"
        },
        "properties": {
          "title": {
            "label": "Naslov",
            "placeholder": "Naslov, prikazan nad poljem"
          },
          "key": {
            "label": "Ključ",
            "placeholder": "Unikatni ključ za shranjevanje podatkov",
            "errors": {
              "empty": "Potreben je ključ",
              "duplicated": "Ta ključ že obstaja"
            }
          },
          "type": {
            "label": "Vrsta"
          },
          "codelist": {
            "label": "Opcije",
            "placeholder": "Seznam šifrantov z izbranimi možnostmi",
            "no_results_message": "Your search for {{ search }} did not have any matches"
          },
          "description": {
            "label": "Opis",
            "placeholder": "Besedilo, prikazano pod naslovom"
          },
          "places": {
            "label": "Decimalna mesta",
            "placeholder": "Največje število decimalnih mest"
          },
          "required": {
            "label": "Obvezno",
            "options": {
              "y": "Da",
              "n": "Ne"
            }
          },
          "placeholder": {
            "label": "Prikazano besedilo",
            "placeholder": "Besedilo, prikazano pri vnosu, ko ni vrednosti"
          },
          "element": {
            "label": "Vizualizacija",
            "options": {
              "input": "Ena vrstica",
              "textarea": "Več vrstic"
            }
          },
          "maxlength": {
            "label": "Največja dolžina",
            "placeholder": "Največje dovoljeno število znakov (vključno s presledki)"
          },
          "min": {
            "label": "Najmanjša vrednost",
            "placeholder": "Najmanjša dovoljena vrednost"
          },
          "max": {
            "label": "Največja vrednost",
            "placeholder": "Največja dovoljena vrednost"
          },
          "default": {
            "label": "Privzeta vrednost",
            "placeholder": "Začetna vrednost polja"
          },
          "default_connection": {
            "title": "Privzeta povezava",
            "description": "Samodejno izpolni to polje z vrednostjo iz drugega polja po meri.",
            "enabled": "Privzeta povezava omogočena",
            "disabled": "Privzeta povezava onemogočena",
            "model": "Model",
            "relation": "Razmerje",
            "group": "Skupina",
            "field": "Polje"
          },
          "provider": {
            "label": "Zunanji ponudnik (za sinhronizacijo podatkov)",
            "options": {
              "n": "Ni ponudnika",
              "pantheon": "Pantheon",
              "bisnode": "Bisnode"
            }
          },
          "provider_key": {
            "label": "Ključ ponudnika",
            "placeholder": "Izberite polje ponudnika"
          }
        },
        "prompts": {
          "delete": {
            "title": "Izbriši {title}",
            "content": "Izbrisali boste polje {title}. Ali želite nadaljevati s tem dejanjem?",
            "confirm_button": "Izbriši",
            "cancel_button": "Prekliči"
          }
        }
      },
      "notify": {
        "update_success": "{title} uspešno posodobljen",
        "save_error": "Med shranjevanjem je prišlo do napake"
      },
      "footer": {
        "undo_button": "Razveljavi vse spremembe",
        "save_button": "Shrani spremembe",
        "save_button_saved": "Shranjeno",
        "notify": {
          "error_empty_key": "Ni mogoče shraniti, ker je polja brez ključa",
          "error_duplicated_key": "Ni mogoče shraniti zaradi podvojenih ključev"
        }
      },
      "before_leave_alert": {
        "title": "Spremembe, ki ste jih naredili, niso shranjene",
        "message": "Zapustili boste to stran brez shranjevanja. Vaše spremembe bodo zavržene. Ste prepričani, da želite oditi?",
        "confirm_button": "Zapusti",
        "cancel_button": "Ostani na tej strani"
      }
    }
  },
  "components": {
    "title": "Polja po meri",
    "field": {
      "select": {
        "no_results": "Vaše iskanje za {{ search }} ni imelo nobenega zadetka"
      }
    },
    "render": {
      "toggle": {
        "true": "Da",
        "false": "Ne"
      },
      "dummy": {
        "title": "Skupina polj po meri",
        "label": "Polje po meri",
        "value": "..."
      },
      "notify_removed_group": "Skupina polj po meri, ki ste jo uporabljali v svoji postavitvi, je bila odstranjena iz podatkovnega modela. Prav tako smo ga odstranili iz vaše postavitve, da bi se izognili težavam.",
      "notify_removed_fields": "Pripomočki (Polja po meri) v vaši postavitvi ni imel nobenega polja za prikaz. Odstranili smo ga iz vaše postavitve, da bi se izognili težavam.",
      "settings": {
        "groups": "Prikaži skupine",
        "all_groups": "Vse skupine",
        "type": "Prikaži skupine v",
        "type_widgets": "Ločeni pripomočki",
        "type_sections": "En pripomoček"
      }
    }
  }
}
