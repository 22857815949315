export default {
  layouts: {
    line_chart: {
      title: "Line chart"
    },
    area_chart: {
      title: "Area chart"
    },
    bar_chart: {
      title: "Bar chart"
    },
    column_chart: {
      title: "Column chart"
    },
    pie_chart: {
      title: "Pie chart"
    },
    treemap_chart: {
      title: "Treemap chart"
    },
    report_line_chart: {
      title: "Report line chart"
    },
    report_area_chart: {
      title: "Report area chart"
    },
    report_bar_chart: {
      title: "Report bar chart"
    },
    report_column_chart: {
      title: "Report column chart"
    },
    report_pie_chart: {
      title: "Report pie chart"
    },
    kpi_line_chart: {
      title: "KPI line chart"
    },
    kpi_area_chart: {
      title: "KPI area chart"
    },
    kpi_bar_chart: {
      title: "KPI bar chart"
    },
    kpi_column_chart: {
      title: "KPI column chart"
    },
    kpi_indicator: {
      title: "KPI indicator"
    },
    kpi_highlight_card: {
      title: "KPI highlight card"
    }
  },
  title: "Dashboard",
  missing_configurations: "Missing configuration",
  no_configuration: "No Configuration",
  views: {
    kpi_charts: {
      kpi_chart_config: {
        title: "Title",
        kpi: "Kpi"
      }
    },

    report_charts: {
      one_categories_multiple_series: {
        title: "Title",
        report: "Report"
      },
      one_categories_one_series: {
        title: "Title",
        report: "Report"
      }
    },

    data_visualization_components: {
      kpi_highlight_card: {
        kpi: "Kpi",
        no_kpi_found: "No kpis found",
        background: "Background",
        layout: "Layout"
      },
      kpi_indicator_config: {
        no_kpi_found: "No kpis found",
        kpi: "Kpi"
      }
    },

    charts: {
      multiple_series: {
        title: "Title",
        model: "Model"
      },
      one_series: {
        title: "Title",
        model: "Model"
      }
    },

    chart_config: {
      auto_categories: {
        categories_property: "Categories property",
        empty_property: "Click to select the categories property",
        select_model: "Select Model",
        date_format: "Category date format"
      },
      auto_series: {
        name_property: "Series Name Property",
        empty_property: "Click to select the name property",
        select_model: "Select Model",
        date_format: "Series name date format",
        value_property: "Series Values Property",
        select_value_properties: "Click to select the values property",
        aggregate_function: "Series Value Aggregate Function"
      },
      predefined_series: {
        series_name: "Series Name",
        value_property: "Series Values Property",
        empty_property: "Click to select the series property",
        select_model: "Select Model",
        aggregate_function: "Series Value Aggregate Function"
      },
      series_specification: {
        series_type: "Series Type",
        remove: "Remove"
      },
      series_specification_form: {
        add_series_specification: "Add Series Specification"
      }

    }
  }
}
