export default {
  layouts: {
    details: {
      title: "Glavne podrobnosti"
    },
    travel_expenses: {
      title: "Potni stroški"
    },
    direct_expenses: {
      title: "Neposredni stroški"
    },
    subsistence_allowances: {
      title: "Dnevnice"
    }
  },
  views: {
    list: {
      header: {
        title: "Potni nalogi",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj potni nalog"
      },
      table: {
        number: "Številka",
        customer: "Stranka",
        date: "Datum",
        selected_items: {
          selected: "Izbrano",
          items: "potni nalogi",
          remove: "Odstrani izbor",

          // Singular
          selected1: "Izbrano",
          item1: "potni nalog",

          // Duality
          selected2: "Izbrano",
          item2: "potni nalogi",

          // Plural
          selected3: "Izbrano",
          item3: "potni nalogi"
        }
      }
    },
    details: {
      sync_in_progress: "Potni nalog še ni v celoti prenešen iz Pantheona. Nekateri podatki še niso vidni in urejanje je onemogočeno.",
      header: {
        back_button: "Seznam potnih nalogov",
        edit_button: "Uredi potni nalog",
        toggle: {
          title: "Aktiven",
          activate: "aktivirajte",
          deactivate: "deaktivirajte",
          description: "Tu lahko {action} potni nalog"
        }
      },
      main: {
        title: "Glavne podrobnosti",
        number: "Številka",
        number_view: "Prikaz številke",
        doc_type: "Tip dokumenta",
        travel_order_date: "Datum potnega naloga",
        calc_date: "Izračunan datum",
        status: "Status",
        statuses: {
          approved: "Oddobreno",
          not_approved: "Ni oddobreno"
        },
        worker: "Delavec",
        car_plate_number: "Številka avtomobilske tablice",
        vehicle: "Vozilo",
        is_company_car: "Službeno vozilo",
        department: "Oddelek",
        active: "Aktiven",
        inactive: "Neaktiven",
        toggle: {
          title: "Je aktiven",
          activate: "Aktiviraj",
          deactivate: "Deaktiviraj",
          description: "Test"
        },
        toggle_approved: {
          title: "Je oddobreno",
          activate: "oddobreno",
          deactivate: "ni oddobreno",
          description: "Potni nalog je "
        },
        toggle_car: {
          title: "Službeni avto",
          activate: "Službeni avto",
          deactivate: "ni službeni avto",
          description: "Ta avto "
        }
      },
      customer: {
        customer: "Stranka",
        select_subject: "Plačnik",
        name: "Ime",
        address: "Hišni naslov",
        email: "Email",
        phone: "Telefon",
        subject_no_result_data: {
          no_results_message: "Ta stranka ne obstaja",
          no_results_button: "Ustvari novo stranko"
        }
      },
      footer: {
        remove_button: "Odstrani",
        save_button: "Shrani potni nalog"
      },
      travelExpenses: {
        title: "Potni stroški",
        total_sum: "Skupna vrednost",
        add_new_travel_expense: "Dodajte nov potni strošek",
        columns: {
          title: "Pot",
          note: "Opomba",
          subject_id: "Stranka",
          start_date: "Začetni datum",
          end_date: "Končni datum",
          destination: "Destinacija",
          start_km: "Začetni km",
          end_km: "Končni km",
          eur_per_km: "Eur/km",
          total_eur: "Skupaj Eur",
          country: "Država"
        },
        countries: {
          slovenia: "Slovenija",
          foreign: "Tuja država"
        },
        popup: {
          popup_title: "Dodajte nov potni strošek",
          title: "Naslov",
          country: "Država",
          customer: "Stranka",
          start_date: "Začetni datum",
          end_date: "Končni datum",
          start_km: "Začetni km",
          end_km: "Končni km",
          eur_per_km: "EUR/km",
          note: "Opomba",
          add: "Dodaj",
          countries: {
            slovenia: "Slovenija",
            foreign: "Tuja država"
          }
        }
      },
      directExpenses: {
        title: "Direktni stroški",
        total_sum: "Polna vsota",
        add_new_direct_expense: "Dodajte nov direkten strošek",
        columns: {
          title: "Naslov",
          travel: "Pot",
          total_eur: "Skupaj Eur"
        }
      },
      subsistenceAllowances: {
        title: "Dnevnice",
        total_sum: "Skupna vsota",
        add_new_subsistence_allowance: "Dodajte novo dnevnico",
        columns: {
          date: "Datum",
          country: "Država",
          duration: "Trajanje",
          amount: "Vsota",
          travel: "Pot",
          factor: "Faktor",
          total: "Skupaj"
        },
        popup: {
          popup_title: "Dnevnica",
          date: "Datum",
          country: "Država",
          duration: "Trajanje",
          amount: "Vsota",
          travel: "Pot",
          factor: "Faktor",
          duration_factor: "Faktor trajanja",
          add: "Dodaj"
        }
      }
    }
  },
  popup: {
    title: "Nov potni nalog",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj potni nalog",
    update_button: "Posodobi potni nalog"
  },
  prompts: {
    delete: {
      title: "Izbiši {number}",
      message: "Izbrisali boste {number}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Potni nalog {number} ustvarjen",
    updated: "Potni nalog {number} posodobljen",
    deleted: "Potni nalog {number} je bil izbrisan",
    deleted_multiple: "Potni nalogi so bili izbrisani",
    not_found: "Navedenega potnega naloga ni mogoče najti"
  }
}
