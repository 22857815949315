export default {
  settings: {
    views: {
      components: {
        user_settings: {
          title: "Račun",
          sub_title: "Uporabniške nastavitve",
          email: "E-naslov",
          name: "Ime",
          name_placeholder: "Ime in priimek",
          success: "Uporabnik je bil uspešno posodobljen.",

          licensing_title: "Licenciranje",
          licensing_sub_title: "To vas bo preusmerilo na stran za licenciranje, kjer lahko uredite aplikacijo.",
          licensing_url: "Pojdite na stran Licenciranje"
        },
        change_password: {
          title: "Spremeni geslo",
          sub_title: "Varnost in avtentikacija",
          password_label: "Trenutno geslo",
          new_password_label: "Novo geslo",
          save: "Spremeni geslo",
          success: "Vaše geslo je bilo uspešno spremenjeno!"
        },
        list_range: {
          title: "Obseg seznama",
          sub_title: "Vsebina",
          number_of_items: "Število predmetov",
          save: "Nastavi privzeto",
          description1: "Število predmetov",
          description2: "vam omogoča, da nastavite poljubno število elementov, ki bodo prikazani na vsakem seznamu.",
          success: "Število elementov, prikazanih na seznamu, je bilo uspešno posodobljeno"
        },
        settings: {
          title: "Uporabniške nastavitve aplikacije",
          sub_title: "Spodnji gumb vas pelje do uporabniških nastavitev aplikacije.",
          go_to: "Pojdite na uporabniške nastavitve"
        }
      }
    }
  }
}
