import Utils from "./../../index"

export default {
  mixins: [ Utils ],
  methods: {
    /**
     * Gets form errors for specific field from resource
     *
     * @param {string} field
     *
     * @returns {string} error message
     */
    getFormError (field) {
      if (!this.utilResource) return

      const action = this.id ? "update" : "store"
      return this.utilResource.GetFieldError(action, field)
    }
  }
}
