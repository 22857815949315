export default {
  views: {
    list: {
      title: "Codelists",
      key: "Key",
      data: "Data",
      additional_fields_schema: "Additional fields schema",
      color: "Color",
      icon: "Icon",
      updated_at: "Updated",
      created_at: "Created",
      save: "Save",
      remove: "Remove",

      remove_category_msg: "Codelist {title} has been successfully removed.",
      remove_msg: "Codelist item has been successfully removed.",
      remove_error: "Error has occurred while removing an item.",
      updated_msg: "Codelist item has been successfully updated.",
      update_error: "Error has occurred while updating an item.",
      store_error: "Error has occurred while storing an item."
    },
    header: {
      add: "Add"
    },
    tree: {
      title: "Codelists",
      add: "Add",
      category_add: "Add new cateogry",
      empty: "There is no data here. Please add new Codelist."
    },
    category_popup: {
      edit: "Edit Codelist",
      new: "Add New Codelist",
      title: "Title",
      schema_active: "Schema is active",
      schema_not_active: "Schema is not active",
      schema_desc: "When activated you can write your own schema.",
      schema_title: "Schema",

      schema_error: "Schema is invalid. Try writing new schema.",
      saved: "Codelist has been successfully saved.",
      updated: "Codelist has been successfully updated.",

      error_update: "Error has occurred when updating.",
      error_store: "Error has occurred while saving.",

      remove: "Remove",
      cancel: "Cancel",
      save: "Save",
      add: "Add",

      prompt: {
        title: "Remove Codelist",
        remove_msg: "Are you sure you want to delete",
        remove: "Remove",
        cancel: "Cancel"
      }
    },
    add_postal_popup: {
      title: "Add postal",
      fields: {
        name: "City",
        code: "Code",
        region: "Region"
      },
      store_error: "Error has occurred while saving."
    }
  }
}
