import Mutation from "@/providers/api/mutations/mutation"

export default class SetListSortMutation extends Mutation {
  /**
   * Sets the list sort param
   *
   * @param {object} state - the module state
   * @param {string} param - the sort param to persist
   */
  async handle (state, param) {
    state.listSortParam = param
  }
}
