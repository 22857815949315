import EchoServer from "laravel-echo"
window.io = require("socket.io-client")

export default {
  created () {
    /**
     * @todo Change window scope!
     */
    window.Echo = new EchoServer({
      broadcaster: "socket.io",
      host: process.env.VUE_APP_SOCKET_URL,
      transports: [ "polling" ]
    })
  }

}
