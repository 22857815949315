import Extension from "@/providers/api/helpers/extension"

import UpdateAction from "./actions/update"

export default class UpdateExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      actions: {
        /**
         * Updates a item record with provided params
         */
        async update (ctx, params) {
          if (params.id && typeof params.id === "object") {
            params.id = repository.ident(params.id)
          }
          return new UpdateAction(ctx, params, repository.api).run()
        }
      }
    })
  }
}
