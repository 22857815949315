// import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration/layouts",
    name: "Layouts",
    meta: { title: "Layouts" },
    component: () => import(/* webpackChunkName: "Layouts List" */ "@/Modules/Layout/Views/List"),
    // beforeEnter: ifAuthenticated
    children: [
      {
        path: "new",
        name: "New Layout",
        meta: { title: "Create New Layout" }
      }
    ]
  },
  {
    path: "/administration/layouts/builder/:id",
    name: "Layout Builder",
    component: () => import(/* webpackChunkName: "Layouts Details" */ "@/Modules/Layout/Views/Builder")
    // beforeEnter: ifAuthenticated
  }
]
