import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration/notification-templates",
    name: "Notification Templates",
    meta: { title: "Notification Templates" },
    component: () => import(/* webpackChunkName: "Notification Templates List" */ "@/Modules/NotificationTemplate/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/notification-templates/new",
    name: "New Notification Template",
    meta: { title: "New Notification Template" },
    component: () => import(/* webpackChunkName: "New Notification Template" */ "@/Modules/NotificationTemplate/Views/Edit/Edit"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/notification-templates/details/:id",
    name: "Notification Template Details",
    component: () => import(/* webpackChunkName: "Notification Template Details" */ "@/Modules/NotificationTemplate/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/notification-templates/details/:id/edit",
    name: "Edit Notification Template",
    component: () => import(/* webpackChunkName: "Edit Notification Template" */ "@/Modules/NotificationTemplate/Views/Edit/Edit"),
    beforeEnter: ifAuthenticated
  }
]
