import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration/mailbox",
    name: "Mailbox",
    meta: { title: "Mailbox" },
    component: () => import(/* webpackChunkName: "Mailbox List" */ "@/Modules/Mailbox/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/mailbox/details/:id",
    name: "Mailbox Details",
    component: () => import(/* webpackChunkName: "Mailbox Details" */ "@/Modules/Mailbox/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
