import Action from "@/providers/api/actions/action"

export default class SetListSortAction extends Action {
  /**
   * Sets the list sort param
   *
   * @param {string} param - the sort param to set
   */
  async handle ({ commit }, param) {
    commit("SET_LIST_SORT", param)
  }
}
