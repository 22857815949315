import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/checklists",
    name: "Checklists",
    meta: { title: "Checklists" },
    component: () => import(/* webpackChunkName: "Checklists List" */ "@/Modules/Checklist/Views/List/List"),
    children: [
      {
        path: "new",
        name: "New Checklist",
        meta: { title: "New Checklist" }
      },
      {
        path: "edit/:id",
        name: "Edit Checklist (List)"
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/checklists/details/:id",
    name: "Checklist Details",
    component: () => import(/* webpackChunkName: "Checklist Details" */ "@/Modules/Checklist/Views/Details/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Checklist"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
