export default {
  layouts: {
    reminders: {
      title: "Opomniki"
    },
    related_models: {
      title: "Povezani modeli"
    },
    details: {
      title: "Glavne podrobnosti"
    },
    sub_details: {
      title: "Dodatne podrobnosti"
    }
  },
  views: {
    list: {
      header: {
        title: "Opravila",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj opravilo"
      },
      table: {
        title: "Naslov",
        description: "Opis",
        status: "Status",
        priority: "Prioriteta",
        due_date: "Končni datum",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnje spremembe",
        active: "Aktiven",
        copy: "Kopiraj",

        selected_items: {
          selected: "Izbrano",
          items: "opravila",
          remove: "Odstarin izbrano",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          items1: "opravilo",

          // Duality
          selected2: "Izbrano",
          items2: "opravila",

          // Plural
          selected3: "Izbrano",
          items3: "opravila"
        },
        data_is_missing: "Seznam je prazen"
      }
    },
    details: {
      header: {
        back_button: "Seznam opravil",
        edit_button: "Uredite dopravilo",
        new_task: "Novo opravilo",
        toggle: {
          title: "Aktiven",
          activate: "aktivirajte",
          deactivate: "deaktivirajte",
          description: "Tu lahko {action} opravilo"
        }
      },
      main: {
        general: "Splošno",
        basic_info: "Splošne informacije",
        title: "Naslov",
        description: "Opis",
        status: "Status",
        due_date: "Končni datum",
        start_date: "Začetni datum",
        priority: "Prioriteta",
        assignee_id: "Prejemnik",
        project_id: "Projekt",
        responsible_person_id: "Odgovorna oseba",
        project: {
          label: "Projekt",
          no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
          no_results_button: "Ustvarite nov projekt"
        }
      },
      reminders: {
        title: "Opomniki",
        sub_title: "Nastavite opomnike za opravilo",
        success: "Uspešno shranjeno.",
        error: "Nekaj ​​je šlo narobe",
        data_is_missing: "Seznam je prazen",
        table: {
          title: "Naslov",
          recurrable: "Ponavljajoče se",
          date: "Datum opomnika"
        },
        reminder: {
          title: "Naslov",
          remind_date: "Datum opomnika"
        },
        popup: {
          title: "Naslov",
          recipient: "Prejemnik",
          recipient_notice: "Opravilo mora imeti vsaj eno povezano stranko z emailom.",
          template: "Predloga obvestila",
          remind_date: "Datum opomnika",
          recurrence: "Ponovitev",
          complex: "Kompleksni opomnik",
          simple: "Preprost opomnik",
          add_button: "Dodaj opomnik",
          remove_button: "Odstrani opomnik",
          default_remind_date_offset: "Privzeti datum opomnika pred rokom v dnevih",
          recipients: {
            user: "Prejemnik opravila",
            customer: "Stranke, povezane z opravilom"
          }
        }
      },
      original_relation: {
        title: "Izdelano iz",
        relation_description: "Opravilo je bilo ustvarjeno iz {type} na spodnji povezavi",
        no_relation_description: "Izberite {type}, iz katerega je bilo to opravilo ustvarjeno",
        type: "Tip",
        types: {
          opportunity: "Priložnost"
        }
      },
      task_original_relations: {
        title: "Opravilo je povezano z",
        title_if_no_data: "Odnosi med opravili",
        connect_button: "Poveži/Odstrani povezavo",
        tabs: {
          subjects: "Stranke",
          projects: "Projekti",
          orders: "Ponudbe",
          invoices: "Računi"
        },
        popup: {
          title: "Poveži z opravilom",
          connect: "Poveži",
          disconnect: "Odstrani povezavo",
          relation: "Povezava",
          model: "Model",
          relations: {
            subjects: "Stranka",
            orders: "Ponudba",
            invoices: "Računi",
            projects: "Projekti"
          }
        }
      },
      sub_details: {
        responsible: "Odgovorna oseba",
        assignee: "Prejemnik",
        start_date: "Začetni datum",
        start_time: "Začetni čas",
        due_date: "Končni datum",
        due_time: "Končni čas",
        project: "Projekt",
        priority: "Prioriteta",
        status: "Status",
        created_by: "Ustvaril",
        updated_by: "Posodobil",
        subject: "Stranka",
        data_not_available: "Podatek ni na voljo",
        tabs: {
          tasks: "Opravila"
        },
        created_at: "Ustvarjeno",
        updated_at: "Nazadnje posodobljeno"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Opravilo {title} izdelano",
    updated: "Opravilo {title} posodobljeno",
    deleted: "Opravilo {title} je bilo izbrisano",
    deleted_multiple: "Opravila so izbrisana",
    not_found: "Navedene naloge ni mogoče najti"
  }
}
