export default {
  layouts: {
    details: {
      title: "Glavne podrobnosti"
    }
  },
  views: {
    list: {
      header: {
        title: "Priložnosti",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj priložnost"
      },
      table: {
        number: "Številka",
        number_view: "Prikaz številke",
        title: "Naslov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnja sprememba",
        active: "Aktiven",
        copy: "Kopiraj",

        selected_items: {
          selected: "Izbrano",
          items: "priložnosti",
          remove: "Odstrani izbrano",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          item1: "priložnost",

          // Duality
          selected2: "Izbrano",
          item2: "priložnosti",

          // Plural
          selected3: "Izbrano",
          item3: "priložnosti"
        },
        data_is_missing: "Seznam je prazen"
      }
    },
    details: {
      header: {
        title: "Nova priložnost",
        back_button: "Seznam priložnosti",
        edit_button: "Uredi priložnost",
        order_button: "Ustvari ponudbo",
        task_button: "Ustvari opravilo",
        invoice_button: "Ustvari račun",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tu lahko {action} priložnost"
        }
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Zadnja sprememba"
        },
        information: {
          number: "Številka",
          title: "Naslov",
          status: "Status",
          source: "Vir",
          value: "Vrednost",
          probability: "Verjetnost",
          responsible_person: "Odgovorna oseba",
          subject: "Stranka",
          description: "Opis"
        },
        customer_details: {
          customer: "Stranka",
          subject: "Stranka",
          status: "Status"
        },
        responsible_details: {
          responsible_user: "Odgovorna oseba",
          user: "Uporabnik"
        }
      },
      relations: {
        title: "Povezani dokumenti",
        tabs: {
          tasks: "Opravila",
          invoices: "Računi",
          orders: "Ponudbe"
        }
      },
      footer: {
        remove_button: "Odstrani priložnost",
        save_button: "Shrani priložnost"
      }
    }
  },
  popup: {
    title: "Nova priložnost",
    number: "Številka",
    title_field: "Naslov",
    status: "Status",
    source: "Vir",
    value: "Vrednost",
    probability: "Verjetnost",
    description: "Opis",
    subject: "Stranka",
    responsible: "Odgovorna oseba",
    opportunity_date: "Datum priložnosti",
    add_button: "Dodaj priložnost",
    update_button: "Posodobi priložnost",
    user: {
      no_results_message: "Ta uporabnik ne obstaja",
      no_results_button: "Ustvari novega uporabnika"
    },
    subject_no_result_data: {
      no_results_message: "Ta stranka ne obstaja",
      no_results_button: "Ustvari novo stranko"
    }
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    create_order: {
      title: "Ustvari ponudbo iz priložnosti",
      message: "Iz te priložnosti bo ustvarjena nova ponudba. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izdelaj ponudbo"
    },
    create_task: {
      title: "Izdelaj opravilo iz priložnosti",
      message: "Iz te priložnosti bo ustvarjeno novo opravilo. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izdelaj opravilo"
    },
    create_invoice: {
      title: "Ustvarite račun iz priložnosti",
      message: "Iz te priložnosti bo ustvarjen nov račun. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izdelaj račun"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Priložnost {title} ustvarjena",
    updated: "Priložnost {title} posodobljena",
    deleted: "Priložnost {title} je bila izbrisana",
    deleted_multiple: "Priložnosti so bile izbrisane",
    not_found: "Navedene priložnosti ni mogoče najti"
  }
}
