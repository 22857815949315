export const categories = [
  {
    icon: "globe-europe",
    color: "blue",
    key: "LangAndRegion",
    component: () => import(/* webpackChunkName: "App Settings" */ "@/Modules/Administration/Views/AppSettings/Components/LangAndRegion"),
    default: {
      language: "en-EN",
      timezone: "Europe/Ljubljana",
      date_format: "DD. MM. YYYY",
      time_format: "24",
      first_day_of_week: "1",
      decimal_places: "2"
    }
  },
  {
    icon: "columns",
    color: "cyan",
    key: "Tables",
    component: () => import(/* webpackChunkName: "App Settings" */ "@/Modules/Administration/Views/AppSettings/Components/Tables"),
    default: {
      form: {
        per_page: 10
      }
    }
  },
  {
    icon: "sms",
    color: "green",
    key: "Infobip",
    component: () => import(/* webpackChunkName: "App Settings Infobip" */ "@/Modules/Administration/Views/AppSettings/Components/Infobip"),
    default: {
      sender_id: "",
      sender_confirm: false
    }
  },
  {
    icon: "address-card",
    color: "orange",
    key: "Subjects",
    component: () => import(/* webpackChunkName: "App Settings Subject/Customer" */ "@/Modules/Administration/Views/AppSettings/Components/Subjects"),
    default: {
      form: {
        currency_id: null
      }
    }
  },
  {
    icon: "tasks",
    color: "orange",
    key: "Tasks",
    component: () => import(/* webpackChunkName: "App Settings Tasks" */ "@/Modules/Administration/Views/AppSettings/Components/Tasks"),
    default: {
      form: {
        title: "",
        description: "",
        status_id: null,
        priority_id: null,
        reminders: []
      }
    }
  },
  {
    icon: "file-chart-line",
    color: "orange",
    key: "Projects",
    component: () => import(/* webpackChunkName: "App Settings Projects" */ "@/Modules/Administration/Views/AppSettings/Components/Projects"),
    default: {
      form: {
        title: "",
        description: "",
        status_id: null,
        user_id: null
      }
    }
  },
  {
    icon: "file-invoice",
    color: "orange",
    key: "Invoices",
    component: () => import(/* webpackChunkName: "App Settings Invoices" */ "@/Modules/Administration/Views/AppSettings/Components/Invoices"),
    default: {
      form: {
        status_id: null,
        responsible_person_id: null,
        note: "",
        doc_type: null
      },
      // Accounting
      number_format: "{{ invoice_date|date('Y') }}{{ doc_type_id }}0",
      number_format_view: "{{ invoice_date|date('Y') }}-{{ doc_type.key }}0-",
      number_format_length: 5
    }
  },
  {
    icon: "file-check",
    color: "orange",
    key: "Orders",
    component: () => import(/* webpackChunkName: "App Settings Orders" */ "@/Modules/Administration/Views/AppSettings/Components/Orders"),
    default: {
      form: {
        status_id: null,
        order_date_offset: 7,
        responsible_person_id: null,
        department_id: null,
        note: "",
        doc_type: null
      },
      // Accounting
      number_format: "{{ order_date|date('Y') }}{{ doc_type_id }}0",
      number_format_view: "{{ order_date|date('Y') }}-{{ doc_type.key }}0-",
      number_format_length: 5
    }
  },
  {
    icon: "box-open",
    color: "orange",
    key: "Supply",
    component: () => import(/* webpackChunkName: "App Settings Supply" */ "@/Modules/Administration/Views/AppSettings/Components/Supply"),
    default: {
      form: {
        status_id: null,
        responsible_person_id: null,
        note: "",
        doc_type: null
      },
      // Accounting
      number_format: "{{ supply_date|date('Y') }}{{ doc_type_id }}0",
      number_format_view: "{{ supply_date|date('Y') }}-{{ doc_type.key }}0-",
      number_format_length: 5
    }
  },
  {
    icon: "ruler-combined",
    color: "orange",
    key: "Assembly",
    component: () => import(/* webpackChunkName: "App Settings Assembly" */ "@/Modules/Administration/Views/AppSettings/Components/Assembly"),
    default: {
      form: {
        status_id: null,
        note: ""
      },
      // Accounting
      number_format: "{{ assembly_date|date('Y') }}0",
      number_format_view: "{{ assembly_date|date('Y') }}0-",
      number_format_length: 5
    }
  },
  {
    icon: "star-exclamation",
    color: "orange",
    key: "Opportunity",
    component: () => import(/* webpackChunkName: "App Settings Opportunity" */ "@/Modules/Administration/Views/AppSettings/Components/Opportunities"),
    default: {
      form: {
        status_id: null,
        responsible_person_id: null,
        description: ""
      },
      // Accounting
      number_format: "{{ ''|date('Y') }}0",
      number_format_length: 5
    }
  },
  {
    icon: "clipboard-list-check",
    color: "orange",
    key: "Checklist",
    component: () => import(/* webpackChunkName: "App Settings Checklist" */ "@/Modules/Administration/Views/AppSettings/Components/Checklist"),
    default: {
      form: {
        title: "",
        description: "",
        values: []
      }
    }
  },
  {
    icon: "list",
    color: "orange",
    key: "Idents",
    component: () => import(/* webpackChunkName: "App Settings Idents" */ "@/Modules/Administration/Views/AppSettings/Components/Idents"),
    default: {
      form: {
        currency_id: null
      }
    }
  },
  {
    icon: "repeat-alt",
    color: "orange",
    key: "Claims",
    component: () => import(/* webpackChunkName: "App Settings Claims" */ "@/Modules/Administration/Views/AppSettings/Components/Claims"),
    default: {
      form: {
        status_id: null,
        responsible_person_id: null,
        warranty_days: 365,
        doc_type: null
      },
      // Accounting
      number_format: "{{ doc_type_id }}0",
      number_format_view: "{{ doc_type.key }}0-",
      number_format_length: 5
    }
  },
  {
    icon: "bell-exclamation",
    color: "grey",
    key: "AutomaticNotifications",
    component: () => import(/* webpackChunkName: "App Settings Notifications" */ "@/Modules/Administration/Views/AppSettings/Components/Notifications")
  },
  {
    icon: "file-check",
    color: "green",
    key: "TravelOrders",
    component: () => import(/* webpackChunkName: "App Settings" */ "@/Modules/Administration/Views/AppSettings/Components/TravelOrders"),
    default: {
      // Accounting
      number_format: "{{ travel_order_date|date('Y') }}{{ doc_type_id }}0",
      number_format_view: "{{ travel_order_date|date('Y') }}-{{ doc_type.key }}0-",
      number_format_length: 5,
      eur_per_km: 0.34,
      doc_type: null
    }
  }
]
