export default {
  layouts: {
    details: {
      title: "Glavne podrobnosti"
    }
  },
  views: {
    list: {
      header: {
        title: "Ponudbe",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj ponudbo"
      },
      table: {
        number: "Številka",
        number_view: "Prikaz številke",
        subject: "Stranka",
        status: "Status",
        order_date: "Datum",
        copy: "Kopiraj",

        selected_items: {
          selected: "Izbrano",
          items: "Računi",
          remove: "Odstrani izbrano",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          item1: "ponudba",

          // Duality
          selected2: "Izbrano",
          item2: "ponudbe",

          // Plural
          selected3: "Izbrano",
          item3: "ponudbe"
        },
        data_is_missing: "Seznam je prazen"
      }
    },
    details: {
      sync_in_progress: "Ponudba še ni v celoti prenešena iz Pantheona. Nekateri podatki še niso vidni in urejanje je onemogočeno.",
      header: {
        title: "Nova ponudba",
        back_button: "Seznam ponudb",
        document_preview_button: "Predogled dokumenta",
        invoice_button: "Ustvari račun",
        advance_button: "Ustvari predračun",
        send_to_customer_button: "Pošlji stranki"
      },
      footer: {
        remove_button: "Izbriši ponudbo",
        add_button: "Dodaj ponudbo",
        save_button: "Shrani ponudbo"
      },
      main: {
        title: "Glavne informacije"
      },
      tasks: {
        back_button: "Nazaj na ponudbo"
      },
      properties: {
        main: {
          title: "Nova ponudba",
          required_data: "Podatki ponudbe",
          number: "Številka ponudbe",
          number_view: "Prikaz številke",
          subject: {
            label: "Stranka",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvari novo stranko"
          },
          recipient: {
            label: "Prejemnik",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvarite novega prejemnika"
          },
          order_date: "Datum ponudbe",
          service_date: "Datum storitve",
          date_valid: "Končni datum",
          status: "Status",
          department: {
            label: "Oddelek",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvari nov oddelek"
          },
          note: "Opomba",
          doc_type: "Tip dokumenta",
          created_from_opportunity: "Izdelano iz priložnosti",
          responsible_person: "Odgovorna oseba",
          user: {
            no_results_message: "Ta uporabik ne obstaja",
            no_results_button: "Ustvari novega uporabnika"
          },
          subject_no_result_data: {
            no_results_message: "Ta stranka ne obstaja",
            no_results_button: "Ustvari novo stranko"
          },
          days_for_payment: "Dni za plačilo",
          due_date: "Končni datum"
        },
        delivery: {
          title: "Dostava",
          receiver: {
            label: "Dostavi",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvari nov subjekt"
          },
          delivery_deadline: "Dobavni rok",
          delivery_type: "Vrsta dostave",
          department_code: "Koda oddelka",
          sale_method: "prodajna metoda",
          amount: "Znesek"
        },
        accounting_summary: {
          title: "Računovodski povzetek"
        },
        accounting_items: {
          title: "Računovodske postavke"
        },
        accounting_costs: {
          title: "Računovodski stroški"
        }
      },
      relations: {
        title: "Povezani dokumenti",
        tabs: {
          invoices: "Računi",
          advances: "Predračuni"
        },
        add_buttons: {
          opportunities: "Nova priložnost"
        }
      },
      custom_fields: {
        title: "Polja po meri"
      },
      customer: {
        title: "Stranka",
        name: "Ime",
        address: "Naslov",
        email: "E-mail",
        phone: "Telefonska številka"
      },
      creation_info: {
        title: "Informacije o ustvarjanju"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbriši ponudbo",
      message: "Izbrisali boste naročilo {number}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    create_invoice: {
      title: "Ustvarite račun iz naročila",
      message: "Iz tega naročila bo ustvarjen nov račun. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Ustvari račun"
    },
    create_advance: {
      title: "Izdelaj predračun iz ponudbe",
      message: "Iz tega naročila bo ustvarjen nov predračun. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Ustvari predračun"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Ponudba shranjena",
    updated: "Ponudba posodobljena",
    deleted: "Ponudba {number} je bila izbrisana",
    deleted_multiple: "Ponudbe so bile izbrisane",
    not_found: "Navedene ponudbe ni mogoče najti"
  }
}
