import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/invoices",
    name: "Invoices",
    meta: { title: "Invoices" },
    component: () => import(/* webpackChunkName: "Invoice List" */ "@/Modules/Invoice/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/invoices/details/:id",
    name: "Invoice Details",
    component: () => import(/* webpackChunkName: "Invoice Details" */ "@/Modules/Invoice/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "task/new",
        name: "New Invoice Task"
      },
      // Task
      {
        path: "task/details/:taskId",
        name: "Invoice Task",
        children: [
          {
            path: "reminder/new",
            name: "Invoice Task New Reminder"
          },
          {
            path: "reminder/:reminderId",
            name: "Invoice Task Edit Reminder"
          }
        ]
      },
      {
        path: "user/new",
        name: "Invoice New User Details"
      },
      {
        path: "invoice-payment/details/new",
        name: "New Invoice Payment"
      },
      {
        path: "customer/new",
        name: "Invoice New Customer Details",
        children: [
          {
            path: "postal/new",
            name: "Invoice New Customer Details New Postal"
          }
        ]
      },
      {
        path: "invoice-payment/details/:paymentId",
        name: "Edit Invoice Payment"
      }
    ]
  },
  {
    path: "/invoices/new",
    name: "New Invoice",
    component: () => import(/* webpackChunkName: "Invoice Details" */ "@/Modules/Invoice/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "user/new",
        name: "Invoice New User"
      },
      {
        path: "customer/new",
        name: "Invoice New Customer",
        children: [
          {
            path: "postal/new",
            name: "Invoice New Customer New Postal"
          }
        ]
      }
    ]
  },

  /**
   * Advanced
  */
  {
    path: "/advanced-invoices",
    name: "Advanced Invoices",
    component: () => import(/* webpackChunkName: "Invoice Advanced List" */ "@/Modules/Invoice/Views/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/advanced-invoices/details/:id",
    name: "Advanced Invoice Details",
    component: () => import(/* webpackChunkName: "Invoice Advanced Details" */ "@/Modules/Invoice/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "task/new",
        name: "New Invoice Task"
      },
      // Task - Relations
      {
        path: "task/details/:taskId",
        name: "Invoice Task"
      }
    ]
  },
  {
    path: "/advanced-invoices/new",
    name: "New Advanced Invoice",
    component: () => import(/* webpackChunkName: "Invoice Advanced Details" */ "@/Modules/Invoice/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
