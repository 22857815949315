import Vue from "vue"
import Router from "vue-router"

// Get all routes from modules and flatten that array of routes
const routes = () => {
  const global = require("@/routes.js").default

  const req = require.context("@/Modules/", true, /Routes\/index\.(ts|js)$/i)
  const modules = req.keys().flatMap((key) => {
    return req(key).default
  })

  return [ ...modules, ...global ]
}

/**
 * Disables router errors
 */
const routerPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: routes()
})
