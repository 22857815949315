export default {
  views: {
    list: {
      header: {
        title: "Idents",
        search_placeholder: "Search ...",
        add_button: "Add ident"
      },
      table: {
        name: "Name",
        code: "Code",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        copy: "Copy",

        selected_items: {
          selected: "Selected",
          idents: "idents",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "ident",

          // Duality
          selected2: "Selected",
          item2: "idents",

          // Plural
          selected3: "Selected",
          item3: "idents"
        },
        data_is_missing: "Table is empty"
      }
    },
    edit: {
      sync_in_progress: "Ident has not yet been fully transferred from Pantheon. Some data is not yet visible and editing is disabled.",
      is_enabled: "Is enabled",
      header: {
        list_back_button: "Ident list",
        title: "New ident",
        save_button: "Save ident",
        add_button: "Add ident",
        remove_button: "Delete"
      },
      tabs: {
        properties: "Properties",
        tehnical_info: "Tehnical information",
        payment: "Payment",
        "creation-info": "Creation info"
      },
      footer: {
        save_button: "Save ident"
      },
      properties: {
        code: "Code",
        name: "Name",
        stock: "Stock",
        author: "Author"
      },
      tehnical_info: {
        weight_netto: "Weight netto",
        weight_brutto: "weight brutto",
        weight_unit: "Weight unit",
        depth: "Depth",
        height: "Height",
        width: "Width",
        dimension_unit: "Dimension unit"
      },
      payment: {
        buy_price: "Buy price with costs",
        transport_cost: "Transport cost",
        cost: "Cost",
        supplier_price: "Supplier price",
        supplier_price_netto: "Supplier price Netto",
        supplier_rebate: "Supplier rebate",
        wholesale_price: "VP1",
        wholesale_price2: "VP2",
        retail_price: "Retail price",
        sale_price: "Sale price",
        vat_rate: "VAT rate",
        vat: "VAT",
        unit: "Units",
        classif: "Classif",
        set_of_item: "Set of item",
        currency: "Currency",
        discount: "Discount"
      }
    }
  },
  popup: {
    title: "New Ident",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add ident",
    update_button: "Update ident"
  },
  prompts: {
    delete: {
      title: "Delete {name}",
      message: "You're about to delete {name}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Ident {name} created",
    updated: "Ident {name} updated",
    deleted: "Ident {name} was deleted",
    deleted_multiple: "Idents were deleted",
    not_found: "Unable to find specified ident"
  },
  components: {
    items: {
      title: "Products, Services",
      columns: {
        title: "Title",
        value: "Value",
        quantity: "Quantity",
        discount: "Discount",
        rebate: "Rebate",
        unit: "Unit",
        vat: "VAT Rate",
        total: "Total"
      },
      select_item: "Select item ...",
      total_sum: "Total sum",
      final_date: "Final date: {date}",
      set_discounts_button: "Rebates",
      discount_popup: {
        title: "Set rebate for all items",
        rebate: "Rebate",
        confirm_button: "Set rebates"
      },
      summary: {
        sum: "Total Sum",
        vat_base: "VAT Base",
        vat_amount: "VAT Amount",
        discounts: "Total Discounts",
        rebates: "Total Rebates"
      }
    },
    costs: {
      title: "Costs",
      columns: {
        title: "Title",
        value: "Value",
        quantity: "Quantity",
        unit: "Unit",
        note: "Note",
        total: "Total"
      },
      select_item: "Select item ...",
      total_sum: "Total sum",
      final_date: "Final date: {date}",
      summary: {
        sum: "Total Sum"
      }
    }
  }
}
