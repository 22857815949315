import Action from "@/providers/api/actions/action"

export default class ClearEditAction extends Action {
  /**
   * Clears the edit param
   */
  async handle ({ commit }) {
    commit("SET_EDIT")
  }
}
