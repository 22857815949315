import Action from "@/providers/api/actions/action"

export default class RemoveFilterAction extends Action {
  /**
   * Removes a filter
   *
   * @param {string} param - the filter namespace to remove
   */
  async handle ({ commit }, param) {
    commit("REMOVE_FILTER", param)
  }
}
