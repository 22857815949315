export default {
  layouts: {
    details: {
      title: "Details"
    }
  },
  views: {
    list: {
      header: {
        title: "Opportunities",
        search_placeholder: "Search ...",
        add_button: "Add opportunity"
      },
      table: {
        number: "Number",
        number_view: "Number view",
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        copy: "Copy",

        selected_items: {
          selected: "Selected",
          items: "opportunities",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "opportunity",

          // Duality
          selected2: "Selected",
          item2: "opportunities",

          // Plural
          selected3: "Selected",
          item3: "opportunities"
        },
        data_is_missing: "Table is empty"
      }
    },
    details: {
      header: {
        title: "New Opportunity",
        back_button: "Opportunities list",
        edit_button: "Edit opportunity",
        order_button: "Create order",
        task_button: "Create task",
        invoice_button: "Create invoice",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} opportunity here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        },
        information: {
          number: "Number",
          title: "Title",
          status: "Status",
          source: "Source",
          value: "Value",
          probability: "Probability",
          responsible_person: "Responsible person",
          subject: "Customer",
          description: "Description"
        },
        customer_details: {
          customer: "Customer",
          subject: "Customer",
          status: "Status"
        },
        responsible_details: {
          responsible_user: "Responsible user",
          user: "User"
        }
      },
      relations: {
        title: "Connected documents",
        tabs: {
          tasks: "Tasks",
          invoices: "Invoices",
          orders: "Orders"
        }
      },
      footer: {
        remove_button: "Remove Opportunity",
        save_button: "Save Opportunity"
      }
    }
  },
  popup: {
    title: "New Opportunity",
    number: "Number",
    title_field: "Title",
    status: "Status",
    source: "Source",
    value: "Value",
    probability: "Probability",
    description: "Description",
    subject: "Customer",
    responsible: "Responsible",
    opportunity_date: "Opportunity date",
    add_button: "Add opportunity",
    update_button: "Update opportunity",
    user: {
      no_results_message: "This User does not exist",
      no_results_button: "Create new user"
    },
    subject_no_result_data: {
      no_results_message: "This Customer does not exist",
      no_results_button: "Create new Customer"
    }
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    create_order: {
      title: "Create order from opportunity",
      message: "A new order will be created from this opportunity. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Create Order"
    },
    create_task: {
      title: "Create tasl from opportunity",
      message: "A new tasl will be created from this opportunity. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Create Task"
    },
    create_invoice: {
      title: "Create invoice from opportunity",
      message: "A new invoice will be created from this opportunity. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Create Invoice"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Opportunity {title} created",
    updated: "Opportunity {title} updated",
    deleted: "Opportunity {title} was deleted",
    deleted_multiple: "Opportunities were deleted",
    not_found: "Unable to find specified opportunity"
  }
}
