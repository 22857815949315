export default {
  sidebar: {
    title: "Koledar",
    timeline_title: "Načrtovanje",
    create_btn: "Ustvari",
    create_in_calendar: "Izberite koledar",
    today: "Danes",
    day: "Dan",
    week: "Teden",
    month: "Mesec",
    calendar: "Koledar",
    timeline: "Časovnica",
    months: {
      full: {
        1: "Januar",
        2: "Februar",
        3: "Marec",
        4: "Aprila",
        5: "Maja",
        6: "Junija",
        7: "Julija",
        8: "Avgusta",
        9: "Septembra",
        10: "Oktobra",
        11: "Novembra",
        12: "Decembra"
      },
      short: {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "Maj",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec"
      }
    },
    calendars: {
      title: "Koledarji",
      add_button: "Ustvari nov koledar",
      edit_button: "Uredi koledar {title}",
      target_popup: {
        title: "Izberite cilj za nov koledar"
      }
    }
  },
  calendar: {
    weekdays: {
      full: {
        1: "Nedelja",
        2: "Ponedeljek",
        3: "Torek",
        4: "Sreda",
        5: "Četrtek",
        6: "Petek",
        7: "Sobota"
      },
      short: {
        1: "Ned",
        2: "Pon",
        3: "Tor",
        4: "Sre",
        5: "Čet",
        6: "Pet",
        7: "Sob"
      }
    },
    no_event_title: "Brez naslova",
    cannot_be_edited: "Koledarja {calendar} ni mogoče urejati od tukaj",
    update_error: "Med posodabljanjem koledarja je prišlo do napake"
  },
  calendar_widget: {
    title: "Koledarji",
    add_button: "Ustvari nov koledar",
    empty_notice: "Seznam koledarjev je prazen",
    table: {
      title: "Naslov",
      color: "Barva"
    },
    notify: {
      stored: "Koledar {title} ustvarjen",
      updated: "Koledar {title} posodobljen",
      deleted: "Koledar {title} je bil izbrisan",
      deleted_multiple: "Koledarji so bili izbrisani",
      not_found: "Določenega koledarja ni mogoče najti"
    },
    prompts: {
      delete: {
        title: "Izbriši {title}",
        message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    }
  },
  popup: {
    title: "Nov koledar",
    fields: {
      title: {
        label: "Naslov"
      },
      event_title: {
        label: "Prikaz vsebine na koledarski kartici",
        placeholder: "Izberite lastnost, ki bo prikazana na koledarski kartici"
      },
      date_start: {
        label: "Datum",
        placeholder: "Izberite lastnost, ki predstavlja datum v koledarju"
      },
      date_end: {
        label: "Končni datum",
        placeholder: "Izberite lastnost, ki predstavlja končni datum v koledarju"
      },
      color: "Barva"
    },
    filter: {
      title: "Filter",
      description: "Za zoženje rezultatov uporabite filtre.",
      add_button: "Nastavi filtre",
      edit_button: "Urejanje filtrov",
      remove_button: "Odstranite filtre",
      popup_title: "Poiščite predmete, kjer ..."
    },
    add_button: "Dodaj koledar",
    update_button: "Posodobi koledar"
  },
  models: {
    order: "Ponudba",
    task: "Opravilo"
  },
  back_to_calendar: "Nazaj na Koledar",
  timeline: {
    users: "Prejemniki",
    projects: "Projekti",
    unassigned: "Nedodeljen",
    no_project: "Brez projekta",
    cannot_be_edited: "Opravila ni mogoče urediti"
  }
}
