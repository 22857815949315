export default {
  title: "Administracija",
  modules: {
    app_settings: {
      title: "Nastavitve aplikacije",
      description: "Spremenite nastavitve aplikacije za vse."
    },
    users: {
      title: "Uporabniki",
      description: "Ustvarjanje, brisanje in upravljanje uporabnikov."
    },
    user_groups: {
      title: "Uporabniške skupine",
      description: "Upravljajte uporabniške skupine in njihove člane."
    },
    codelists: {
      title: "Šifranti",
      description: "Vsi vnaprej določeni in prilagodljivi podatki v modulih."
    },
    newsletters: {
      title: "Seznami prejemnikov",
      description: "Seznami, ki se uporabljajo za pošiljanje novic"
    },
    document_templates: {
      title: "Predloge dokumentov",
      description: "Predloge, ki se uporabljajo za ustvarjanje dokumentov v vaši aplikaciji."
    },
    notifications: {
      title: "Obvestila",
      description: "Seznam poslanih obvestil"
    },
    notification_templates: {
      title: "Predloge obvestil",
      description: "Predloge, ki se uporabljajo za generiranje obvestil (sms in e-pošta) v vaši aplikaciji"
    },
    fields: {
      title: "Polja po meri",
      description: "Ustvarite lastna podatkovna polja in jih uporabite za modele."
    },
    mailbox: {
      title: "Poštni nabiralnik",
      description: "Seznam in podrobnosti e-poštnih sporočil, poslanih v nabiralnik."
    },
    mailbox_rules: {
      title: "Pravila poštnega predala",
      description: "Določite pravila za ujemanje e-pošte iz nabiralnika z izbrano entiteto."
    },
    layouts: {
      title: "Postavitve",
      description: "Ustvarite in uredite postavitve za poglede."
    }
  },
  app_settings: {
    title: "Nastavitve aplikacije",
    back_button: "Administracija",
    save_button: "Shrani nastavitve",
    LangAndRegion: {
      title: "Jezik in regija",
      general: "Splošno",
      language: "Jezik",
      timezone: "Časovni pas",
      format: "Oblika",
      date_format: "Format datuma",
      time_format: {
        label: "Format ure",
        options: {
          12: "12 ur",
          24: "24 ur"
        }
      },
      first_day_of_week: {
        label: "Prvi dan v tednu",
        options: {
          0: "Nedelja",
          1: "Ponedeljek",
          2: "Torek",
          3: "Sreda",
          4: "Četrtek",
          5: "Petek",
          6: "Sobota"
        }
      },
      decimal_places: "Decimalna mesta"
    },
    Users: {
      title: "Uporabnik"
    },
    Tables: {
      title: "Tabele",
      preferences: "Nastavitve",
      per_page: "Število vrstic na eni strani",
      height: {
        label: "Višina vrstice"
      }
    },
    Infobip: {
      title: "SMS",
      sender: "Pošiljatelj",
      sender_id: "Ime pošiljatelja",
      sender_number: "Številka pošiljatelja"
    },
    Tasks: {
      title: "Opravila",
      form: {
        general: "Splošno",
        default_title: "Privzeti naslov",
        default_description: "Privzeti opis",
        default_status: "Privzeti status",
        default_priority: "Privzeta prioriteta",
        reminders: "Opomniki"
      }
    },
    Projects: {
      title: "Projekti",
      form: {
        general: "Splošno",
        default_title: "Privzeti naslov",
        default_description: "Privzeti opis",
        default_status: "Privzeti status",
        default_responsible: "Privzeta odgovorna oseba"
      }
    },
    Invoices: {
      title: "Invoices",
      form: {
        general: "Splošno",
        default_status: "Privzeti status",
        default_responsible: "Privzeta odgovorna oseba",
        default_note: "Opomba",
        default_doc_type: "Privzeta vrsta dokumenta",

        invoice_accounting: "Računovodstvo",
        invoice_accounting_description: "Tukaj lahko ustvarite privzeti pogled številke računa"
      }
    },
    Orders: {
      title: "Ponudba",
      form: {
        general: "Splošno",
        default_status: "Privzeti status",
        default_responsible: "Privzeta odgovorna oseba",
        default_order_date_offset: "Privzeti odmik datuma ponudbe (v dnevih od danes)",
        default_note: "Opomba",
        default_doc_type: "Privzeta vrsta dokumenta",

        order_accounting: "Računovodstvo",
        order_accounting_description: "Tukaj lahko ustvarite privzeti pogled številke ponudbe"
      }
    },
    Supply: {
      title: "Prevzem",
      form: {
        general: "Splošno",
        default_note: "Privzeta opomba",
        default_status: "Privzeti status",
        default_responsible: "Privzeta odgovorna oseba",
        default_doc_type: "Privzeta vrsta dokumenta",

        supply_accounting: "Računovodstvo",
        supply_accounting_description: "Tukaj lahko ustvarite privzeti pogled številke prevzema"
      }
    },
    Assembly: {
      title: "Montaža",
      form: {
        general: "Splošno",
        default_note: "Privzeta opomba",
        default_status: "Privzeti status",
        default_responsible: "Privzeta odgovorna oseba",

        assembly_accounting: "Računovodstvo",
        assembly_accounting_description: "Tukaj lahko ustvarite privzeti pogled številke montaže"
      }
    },
    Opportunity: {
      title: "Priložnosti",
      form: {
        general: "Splošno",
        default_status: "Privzeti status",
        default_responsible: "Privzeta odgovorna oseba",
        default_description: "Privzeti opis",

        invoice_accounting: "Računovodstvo",
        invoice_accounting_description: "Tukaj lahko ustvarite privzeti pogled številke priložnosti"
      }
    },
    Checklist: {
      title: "Kontrolni seznam",
      form: {
        general: "Splošno",
        default_title: "Privzeti naslov",
        default_description: "Privzeti opis"
      }
    },
    Idents: {
      title: "Produkti",
      form: {
        general: "Splošno",
        subtitle: "Ni privzetih nastavitev.",
        currency: "Valuta"
      }
    },
    Claims: {
      title: "Reklamacije",
      form: {
        general: "Splošno",
        default_status: "Privzeti status",
        default_responsible: "Privzeta odgovorna oseba",
        default_note: "Opomba",
        default_doc_type: "Privzeta vrsta dokumenta",

        claim_accounting: "Računovodstvo",
        claim_accounting_description: "Tukaj lahko ustvarite privzeti pogled številke reklamacije",
        warranty_days: "Število garancijskih dni"
      }
    },
    Subjects: {
      title: "Stranka",
      form: {
        general: "Splošno",
        default_currency: "Privzeta valuta"
      }
    },
    Accounting: {
      title: "Računovodstvo",
      number_format: "Oblikuj številko",
      number_format_view: "Pogled oblike številke",
      number_format_length: "Oblikujte dolžino številke"
    },
    AutomaticNotifications: {
      title: "Avtomatizacija obvestil",
      add_new: "Dodajte novo nastavitev obvestil",
      update_existing: "Posodobite nastavitev obvestil",
      feature: "Funkcija",
      notify: "Obvestiti",
      template: "Predloga",
      delay: "Zamuda",
      condition: "Pogoj",
      form: {
        logo: "Logo",
        signature: "Podpis",
        delay: "Zamuda (v urah)",
        condition: "Pogoj pošiljanja",
        column: "Stolpec",
        comparison: "Primerjava",
        value: "Vrednost"
      },
      features: {
        task_create: {
          title: "Ustvari opravilo",
          relations: {
            responsible_person: "Odgovorna oseba",
            assignee: "Dodeljena oseba",
            created_by: "Ustvaril"
          },
          columns: {
            status: "Status"
          }
        },
        task_update: {
          title: "Posodobi opravilo",
          relations: {
            responsible_person: "Odgovorna oseba",
            assignee: "Dodeljena oseba",
            created_by: "Ustvaril"
          },
          columns: {
            status: "Status"
          }
        },
        user_create: {
          title: "Ustvari uporabnika"
        },
        claim_create: {
          title: "Ustvari reklamacijo",
          relations: {
            responsible_person: "Odgovorna oseba",
            customer: "Stranka"
          },
          columns: {
            responsible_person: "Odgovorna oseba",
            resolved_date: "Datum rešitve"
          }
        },
        claim_update: {
          title: "Posodobi reklamacijo",
          relations: {
            responsible_person: "Odgovorna oseba",
            customer: "Stranka"
          },
          columns: {
            responsible_person: "Odgovorna oseba",
            resolved_date: "Datum rešitve"
          }
        },
        opportunity_create: {
          title: "Ustvari priložnost",
          relations: {
            responsible_person: "Odgovorna oseba",
            assignee: "Dodeljena oseba",
            created_by: "Ustvaril"
          },
          columns: {
            responsible_person: "Odgovorna oseba",
            status: "Status",
            probability: "Verjetnost",
            subject: "Stranka"
          }
        },
        opportunity_update: {
          title: "Posodobi priložnost",
          relations: {
            responsible_person: "Odgovorna oseba",
            assignee: "Dodeljena oseba",
            created_by: "Ustvaril"
          },
          columns: {
            responsible_person: "Odgovorna oseba",
            status: "Status",
            probability: "Verjetnost",
            subject: "Stranka"
          }
        }
      },
      comparisons: {
        equal: "Je enaka",
        not_equal: "Ni enako",
        is_empty: "Je prazno",
        is_not_empty: "Ni prazen",
        has_changed: "Se je spremenilo"
      }
    },
    Licensing: {
      title: "Licenciranje",
      plan: "Licenčna shema",
      payment_info: "Podatki za obračun",
      email: "E-naslov",
      address: "Naslov",
      vat_number: "Davčna številka",
      selected: "Izbrano",
      day: "dan",
      days: "dni",
      free_trial: "brezplačen preizkus",
      plan_change_error: "Licenčne sheme ni mogoče spremeniti",
      plan_changed: "Licenčna shema je bil uspešno posodobljen",
      plan_canceled: "Naročnina je bila preklicana",
      unsubscribe: "Prekliči naročnino"
    },
    TravelOrders: {
      title: "Potni nalogi",
      general: "Splošno",
      default_eur_per_km: "Privzeto EUR na km",
      default_doc_type: "Privzeta vrsta dokumenta",

      accounting: "Računovodstvo",
      accounting_description: "Tukaj lahko ustvarite svoj privzeti pogled številke potnega naloga"
    },
    save_error: "Med shranjevanjem je prišlo do napake",
    save_success: "Uspešno shranjene {category} nastavitve",

    prompt: {
      confirmation: "Potrditev",
      message: "S spremembo formata oštevilčevanja potrjujete, da se bo oštevilčenje začelo šteti na novo z formatom, ki ste ga nastavili.",
      confirm_button: "Potrdi",
      cancel_button: "Prekliči"
    }
  },
  app_setup: {
    title: "Nastavitev aplikacije",
    back_button: "",
    save_button: "Shrani nastavitve",
    save_error: "Med shranjevanjem je prišlo do napake",
    save_success: "Uspešno shranjeno",
    Application: {
      title: "Osnovni podatki",
      label: {
        company: "Ime podjetja",
        url: "URL aplikacije",
        email: "E-naslov",
        phone: "Telefon",
        password: "Geslo",
        confirm_password: "Potrdi geslo"
      }
    },
    DataProviders: {
      title: "Ponudnik storitev",
      description: "Uvozite podatke podprtih ponudnikov.",
      label: {
        selected_provider: "Izberite ponudnika storitev",
        host: "Naslov gostitelja",
        port: "Port",
        database: "Ime baze podatkov",
        username: "Uporabniško ime baze podatkov",
        password: "Geslo baze podatkov",
        connection_test: "Preizkusite povezavo z bazo podatkov"
      }
    },
    Plugins: {
      title: "Vtičniki",
      description: "Trenutno podprti ponudniki. Več kmalu.",
      link: "Povezava",
      registration: "Registracija",
      newsletter: {
        title: "Email marketing",
        selected_provider: "Izberite ponudnika storitev",
        squalomail: {
          description: "SqualoMail je platforma za Email marketing.",
          link: "https://www.squalomail.com/sl/",
          registration: "https://register.squalomail.com/free-trial/sl/",
          key: "API KEY"
        }
      },
      sms: {
        title: "Pošiljanje SMS sporočil",
        selected_provider: "Izberite ponudnika storitev",
        smsapi: {
          description: "API za množično pošiljanje SMS-ov.",
          link: "https://www.smsapi.si/",
          registration: "https://www.smsapi.si/registracija",
          username: "Uporabniško ime",
          password: "Geslo",
          number: "Telefonska številka za prikaz v poslanem SMS sporočilu"
        }
      },
      business_data: {
        title: "Poslovni podatki",
        description: "Ponudnik poslovnih podatkov bo samodejno izpolnil podatke za poslovne stranke.",
        selected_provider: "Izberite ponudnika podatkov",
        bisnode: {
          description: "Bisnode je ponudnik poslovnih podatkov.",
          link: "https://bisnode.si/",
          registration: "https://accounts.bisnode.si/Authenticate/?product=0&language=en-US&returnUrl=https%3A%2F%2Faccounts.bisnode.si%2FHome%2F%3Fproduct%3D0%26language%3Dsl-SI%26basLanguage%3Den-US",
          username: "Uporabniško ime",
          password: "Geslo"
        }
      }
    }
  }
}
