<template>
    <div
        class="uik-select__wrapper"
        :class="{
            'uik-select__wrapper--open': state,
            'uik-select__wrapper--loading': loading
        }"
        :disabled="disabled"
    >
        <input type="hidden" :value="value">
        <div v-if="label || infoText" class="uik-input__label-container">
            <span v-if="label" class="uik-content-title__wrapper uik-input__label">
                {{ label }}
                {{ (required ? '*':'') }}
            </span>
            <UikTooltip
                v-if="infoText"
                :text="infoText"
                :icon="infoIcon"
                :noPointerEvents="infoNoPointerEvents"
            >
                <span class="uik-input__info">?</span>
            </UikTooltip>
        </div>
        <button
            class="uik-btn__base uik-select__valueRendered"
            :class="{
                'uik-select__valueRendered--error': errorMessage !== '' ? true : false,
                'uik-select__valueRendered--readonly': readonly,
                'uik-select__valueRendered--placeholder': valueRendered === placeholder
            }"
            type="button"
            :disabled="disabled"
            @click="(readonly || loading) ? null : open()"
        >
            <input
                v-if="state"
                ref="search"
                v-model="search"
                :placeholder="valueRendered"
                @input="handleInput"
                @change="handleChange"
                @focus="$emit('focus', $event.target.value)"
                @keydown.enter="$emit('keydown-enter', $event)"
                @keydown.esc="$emit('keydown-esc', $event)"
                @keyup.enter="$emit('keyup-enter', $event)"
                @keyup.esc="$emit('keyup-esc', $event)"
            />
            <span v-if="!state" class="uik-btn__content">
                <div class="uik-select__valueRenderedWrapper">
                    <Uikon v-if="icon" :icon="icon" class="uik-select__iconWrapper"/>
                    <div v-if="value !== ''" class="uik-select__valueWrapper">
                        {{ valueRendered }}
                    </div>
                    <div v-if="value == ''" class="uik-select__valueWrapper">{{ placeholder }}</div>
                    <div class="uik-select__arrowWrapper"></div>
                </div>
            </span>
        </button>

        <div
            v-if="state && (getOptions.length || loading || noResults)"
            :class="[
                'uik-select__optionListWrapper',
                'uik-select__' + dropPosition,
                {'uik-select__optionListWrapper--multiselect': multiselect}
            ]"
        >
            <div ref="scrollContainer" class="uik-select__optionList">
                <div class="uik-select__mobile-head">
                    <div class="uik-select__mobile-head-input">
                        <Uikon icon="search_left"/>
                        <input
                            v-if="state"
                            ref="mobileSearch"
                            v-model="search"
                            :placeholder="valueRendered"
                            @input="handleInput"
                            @change="handleChange"
                            @focus="$emit('focus', $event.target.value)"
                            @keydown.enter="$emit('keydown-enter', $event)"
                            @keydown.esc="$emit('keydown-esc', $event)"
                            @keyup.enter="$emit('keyup-enter', $event)"
                            @keyup.esc="$emit('keyup-esc', $event)"
                        />
                    </div>
                    <button
                        title="Close"
                        @click="close()"
                    >
                        <Uikon icon="close"/>
                    </button>
                </div>
                <div
                    v-for="(option,key) in getOptions"
                    :key="key"
                    :style="option[remap.indent] ? 'padding-left: ' + (option[remap.indent] ? option[remap.indent] * 5 + 15: 15) + 'px;': null"
                    class="uik-btn__base uik-select__option"
                    :class="{
                        'uik-select__option--user': avatar,
                        'uik-select__option--disabled' : option[remap.disabled]
                    }"
                    :selected="
                        (Array.isArray(getOptions) ? option[remap.key] : key) === value ? true : false ||
                            (multiselect && Array.isArray(value) && value.includes(Array.isArray(getOptions) ? option[remap.key] : key) ? true : false)
                    "
                    @click="select(option, key)"
                >
                    <span v-if="typeof option !== 'object' && !avatar" class="uik-select__optionContent">{{ option }}</span>
                    <span v-if="typeof option === 'object' && !avatar" class="uik-select__optionContent uik-btn__content--right">
                        <span>{{ option[remap.title] }}</span>
                        <span class="uik-select__label">{{ option[remap.label] }}</span>
                    </span>
                    <span v-if="avatar" class="uik-select__optionContent">
                        <UikAvatar
                            :imageUrl="option[remap.imageUrl]"
                            :title="option[remap.title]"
                            :name="option[remap.title]"
                            :textBottom="option[remap.subtitle]"
                            color="primary"
                        />
                    </span>
                </div>
                <div
                    v-if="!loading && noResults && (!getOptions || getOptions.length <= 0)"
                    class="uik-select__no-results"
                >
                    <p v-if="noResults.text">{{ parseText(noResults.text) }}</p>
                    <UikButton
                        v-if="noResults.button"
                        xs
                        primary
                        @click="
                            noResults.button.action()
                            close()
                        "
                    >{{ noResults.button.text }}</UikButton>
                </div>

                <div v-if="optionButton" class="resource-select__bottom-link">
                    <button @click="optionButton.action()">
                        <UikIcon v-if="optionButton.icon" :icon="optionButton.icon"/>
                        <span>{{ optionButton.text }}</span>
                    </button>
                </div>
            </div>
        </div>

        <UikOverlay :visible="state" :opacity="5" @close="close()"/>

        <p v-if="showErrorMessage && errorMessage" class="uik-input__errorMessage">{{ errorMessage }}</p>
    </div>
</template>

<script>
export default {
  name: "ResourceSelect",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    // Is this select disabled?
    disabled: { type: Boolean, default: false },
    // Where does the dropdown list appear
    dropPosition: { type: String, default: "bottomLeft" },
    // Should the error message be displayed?
    showErrorMessage: { type: Boolean, default: true },
    // The error message to display
    errorMessage: { type: String, default: "" },
    // The icon in the select to display
    icon: { type: String, default: "" },
    // The image folder to display the images from (images defined in options)
    imageFolder: { type: String, default: "" },
    // The info text to display in tooltip
    infoIcon: { type: String, default: "" },
    // The icon to be displayed for the info tooltip
    infoNoPointerEvents: { type: Boolean, default: false },
    // Should the tooltip hide when hovered with a mouse
    infoText: { type: String, default: "" },
    // The label to display
    label: { type: String, default: "" },
    // The options to display in select
    options: { type: [Object, Array], default: null },
    // The placeholder text to display
    placeholder: { type: String, default: "" },
    // Is this select readonly?
    readonly: { type: Boolean, default: false },
    // Is this select required
    required: { type: Boolean, default: false },
    // The value of the select
    value: { type: [String, Array, Number], default: "" },
    // Enables selecting multiple options - returns array
    multiselect: { type: Boolean, default: false },
    // An object that defines which options fields should be remaped to what
    remaps: { type: [Object, null], default: null },
    // The resource this table is showing data for
    resource: { type: [Object, null], default: null },
    // The resource this table is showing data for
    resourceAction: { type: String, default: "getList" },
    // The columns we want to search in
    searchColumns: { type: [Array, null], default: null },
    // Sort by column
    sort: { type: String, default: "" },
    // Number of results
    perPage: { type: [String, Number], default: 10 },
    // Codelist schema for codelistData resource
    codelistSchema: { type: String, default: "" },
    // Additional filter passed to resourceAction
    filter: { type: Object, default: null },
    /**
     * "No results" section config
     *
     * example:
        {
          text: 'No results found',
          button: {
            text: 'Add Entity',
            action: addEntity
          }
        }
     */
    noResults: { type: Object, default: null },
    // Display options as avatars
    avatar: { type: Boolean, default: false },
    hasNoneOption: { type: Boolean, default: true },
    selectLoggedInUserIfEmpty: { type: Boolean, default: false },
    optionButton: { type: Object, default: null }
  },
  data () {
    return {
      selected: "",
      state: false,
      search: "",
      timer: null,
      inputTriggeredSearch: false,
      lastRequest: null,
      list: [],
      endlessScroll: {
        itemsLoaded: this.perPage,
        allItemsLoaded: false
      }
    }
  },
  computed: {
    /**
     * An object containing remapvalues including custom ones
     * Default values are overwritten if custom values are provided
     */
    remap () {
      return {
        indent: "indent",
        type: "type",
        disabled: "disabled",
        key: "key",
        title: "title",
        label: "label",
        imageUrl: "imageUrl",
        name: "name",
        subtitle: "subtitle",
        ...this.remaps
      }
    },
    loading () {
      return this.resource?.loaders[this.resourceAction]
    },
    getOptions () {
      let options = this.options || this.list

      if (this.state && this.loading && options.length === 1 && this.value) return []

      if (this.hasNoneOption && !this.search) {
        options = [this.createOption(null, this.t("Global.none"))].concat(options)
      }

      return options
    },
    valueRendered () {
      this.$emit("render", this.value)
      let generateOutput = (value) => {
        let originalValue
        if (Array.isArray(this.getOptions)) {
          originalValue = this.getOptions.find(e => e[this.remap.key] === value)
        } else {
          originalValue = this.getOptions && this.getOptions[value] ? this.getOptions[value] : ""
        }
        if (typeof (originalValue) === "object" && originalValue[this.remap.type] !== "user") {
          return originalValue[this.remap.title]
        } else if (typeof (originalValue) === "object" && originalValue[this.remap.type] === "user") {
          return originalValue[this.remap.name]
        } else {
          return originalValue
        }
      }
      if (this.multiselect && this.value) {
        let values = []
        for (let i = 0; i < this.value.length; i++) {
          values.push(generateOutput(this.value[i]))
        }
        return values.join(", ")
      } else {
        return generateOutput(this.value) || this.placeholder
      }
    }
  },
  watch: {
    value () {
      this.getList(this.value)
    }
  },
  created () {
    if (this.sort) this.resource.setListSort(this.sort)
    if (this.codelistSchema) this.resource.listDefaultParams.schemaKey = this.codelistSchema
    if (!this.value && this.selectLoggedInUserIfEmpty) {
      this.$emit("change", this.$api?.Auth?.user[this.remap.key] ?? null)
    }
    this.getList(this.value)
  },
  methods: {
    createOption (keyVal, titleVal) {
      const title = this.remaps?.title ?? "title"
      const key = this.remaps?.key ?? "key"
      const extraOption = {}

      extraOption[title] = titleVal
      extraOption[key] = keyVal

      return extraOption
    },
    open () {
      if (!this.state) {
        this.$emit("open")
        this.getList()
        this.state = true
        this.$nextTick(() => {
          if (screen.width <= 768) {
            this.$refs.mobileSearch.focus()
          } else {
            this.$refs.search.focus()
          }

          this.setScrollListener()
        })
      }
    },
    close (refreshList = true) {
      this.$emit("close")
      this.state = false
      this.search = ""
      this.resetFilter(refreshList)
    },
    select (option, key) {
      if (!option[this.remap.disabled]) {
        this.handleSelectedOption(Array.isArray(this.getOptions) ? option[this.remap.key] : key, option)
      }
    },
    handleSelectedOption (key, item) {
      if (this.loading) return
      if (this.search !== "") this.search = ""

      if (this.multiselect) {
        let arr = Array.isArray(this.value) ? [...this.value] : []
        if (arr.includes(key)) {
          let index = arr.indexOf(key)
          if (index > -1) arr.splice(index, 1)
        } else {
          arr.push(key)
        }
        this.$emit("change", arr)
      } else {
        this.$emit("change", key)
        this.$emit("changeItem", item)
        this.close(key === this.value) // close and refresh list if selected key is already selected
      }
    },
    handleInput (event) {
      let search = event.target.value
      this.$emit("input", search)
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.triggerSearch(search)
        this.inputTriggeredSearch = true
      }, 300)
    },
    handleChange (event) {
      let search = event.target.value
      if (this.timer) clearTimeout(this.timer)
      if (!this.inputTriggeredSearch) this.triggerSearch(search)
      this.inputTriggeredSearch = false
    },
    triggerSearch (search) {
      if (!this.resource) return
      if (search === "") {
        this.removeSearchFilter()
        return this.getList()
      }
      let filterParams = {
        module: this.$route.name,
        key: "search",
        params: {
          search: [this.searchColumns, search]
        }
      }
      this.resource.setFilter(filterParams)
      this.getList()
    },
    removeSearchFilter () {
      this.resource.removeFilter({ module: this.$route.name, key: "search" })
    },
    resetFilter (refreshList) {
      this.removeSearchFilter()
      this.removeAdditionalFilter()
      if (refreshList) this.getList(this.value)
    },
    setAdditionalFilter () {
      const filterParams = {
        module: this.$route.name,
        key: "additionalFilter",
        params: this.filter
      }
      this.resource.setFilter(filterParams)
    },
    removeAdditionalFilter () {
      this.resource.removeFilter({ module: this.$route.name, key: "additionalFilter" })
    },
    async getList (value, add = false) {
      let params = {}

      let perPage = this.perPage
      const itemsCount = this.list.length

      if (add) {
        this.endlessScroll.itemsLoaded += this.perPage
        perPage = this.endlessScroll.itemsLoaded
      } else {
        this.endlessScroll.itemsLoaded = this.perPage
        this.endlessScroll.allItemsLoaded = false
        if (this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollTop = 0
        }
      }

      if (perPage) {
        if (this.codelistSchema) {
          if (!params.params) params.params = {}
          params.params.per_page = perPage
        } else {
          params.per_page = perPage
        }
      }

      if (value) {
        const paramValue = { selected: [["where", this.remap.key, value]] }
        if (this.codelistSchema) {
          if (!params.params) params.params = {}
          params.params.filter = paramValue
        } else {
          params.filter = paramValue
        }
      } else if (this.filter) this.setAdditionalFilter()

      const timestamp = new Date().getTime()
      const lastRequest = timestamp + (value ? ("-" + value) : "")
      this.lastRequest = lastRequest
      if (this.resource.loaders[this.resourceAction]) return

      const response = await this.resource[this.resourceAction](params)
      const items = response.data.data
      this.list = items
      this.$emit("set-options", this.list)

      if (add) {
        if (items.length === itemsCount) this.endlessScroll.allItemsLoaded = true
        else this.endlessScroll.allItemsLoaded = false
      }

      if (this.lastRequest !== lastRequest) {
        const value = this.lastRequest.split("-")[1]
        this.getList(value, add)
      }
    },
    loadMoreItems () {
      this.getList(null, true)
    },
    setScrollListener () {
      this.$refs.scrollContainer.addEventListener("scroll", this.handleScroll)
    },
    handleScroll () {
      const scrollContainer = this.$refs.scrollContainer
      if (scrollContainer) {
        const scrollTop = scrollContainer.scrollTop
        const scrollHeight = scrollContainer.scrollHeight - scrollContainer.getBoundingClientRect().height
        const bottom = scrollHeight - scrollTop
        if (bottom <= 15 && !this.endlessScroll.allItemsLoaded && !this.loading) this.loadMoreItems()
      }
    },
    parseText (text) {
      try {
        let matches = text.match(/\{{(.*?)\}}/g)
        for (let i = 0; i < matches.length; i++) {
          const property = matches[i].replace("{{", "").replace("}}", "").trim()
          text = text.replace(matches[i], this[property])
        }
      } catch {}
      return text
    }
  }
}
</script>

<style lang="scss">
.uik-select__wrapper {
    position: relative;
    font-size: 0.875rem;
    display: inline-block;
    .uik-select__valueRendered {
        width: 100%;
        text-align: left; // important for other selectors - :hover etc.
        position: relative;
        background: #ffffff;
        border: 1px solid $borderColorDarker;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
        padding: 0 18px 0 15px;
        &:hover {
            border-color: darken($borderColorDarker, 5%);
        }
        .uik-select__label {
            position: absolute;
            top: -18px;
            font-size: 1rem;
            left: 14px;
            pointer-events: none;
        }
        .uik-select__valueRenderedWrapper {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: center;
            width: 100%;
            & > * {
                flex-grow: 1;
                max-width: 100%;
                min-width: 1px;
            }
            .uik-select__iconWrapper {
                position: absolute;
                left: 12px;
                color: $borderColorDarker;
                font-size: 16px;
                line-height: 1;
            }
            .uik-select__iconWrapper + .uik-select__valueWrapper {
                padding-left: 23px;
            }
            .uik-select__arrowWrapper {
                flex-grow: 0;
                transition: 0.2s color;
                position: relative;
                margin-left: 12px;
                width: 6px;
                min-width: 6px;
                height: 2px;
                &:before,
                &:after {
                    display: block;
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 0;
                    border-left: 3px solid transparent;
                    border-right: 3px solid transparent;
                    border-bottom: 3px solid $textSecondary;
                }
                &:after {
                    top: -4px;
                }
                &:before {
                    top: 2px;
                    transform: rotate(180deg);
                }
            }
        }
    }
    .uik-select__valueWrapper {
        text-overflow: ellipsis;
        white-space: nowrap;
        &.uik-select__placeholderEmpty {
            color: $textSecondary;
        }
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.25s !important;
    }

    .uik-btn__base {
        input {
            z-index: 11;
            width: 100%;
            background: transparent;
            border: 0;
            font-size: inherit;
            outline: none;
        }
    }
    &--loading {
      .uik-select__optionListWrapper {
        position: relative;
        pointer-events: none;
        &::before {
          content: "";
          position: absolute;
          width: 0%;
          height: 2px;
          background: $primary;
          top: 0px;
          left: 0;
          right: 0;
          margin: 0 auto;
          transition: all 0.25s;
          z-index: 3;
          animation: calendar-board-event-loading 2s linear infinite;
          @keyframes calendar-board-event-loading {
            0% {
              width: 0%;
              margin-left: 0;
            }
            50% {
              margin-left: 0;
              width: 100%;
            }
            100% {
              margin-left: 100%;
              width: 0%;
            }
          }
        }
        .uik-select__optionList {
          opacity: 0.5;
        }
      }
    }
}

.uik-form-input-group__vertical,
.uik-form-input-group__horizontal {
    .uik-select__wrapper {
        .uik-select__valueRendered {
            box-shadow: none;
            border-color: $borderColor;
            &:focus {
                border-color: $primary;
            }
            &--error {
                border-color: $error;
            }
            &--readonly {
                cursor: default;
                &:focus {
                    border-color: $borderColor;
                }
            }
            &--placeholder {
              .uik-btn__content {
                color: rgba($textBase, 0.75);
              }
            }
        }
    }
}

.uik-form-input-group__vertical,
.uik-form-input-group__horizontal {
    .uik-select__wrapper--open {
        .uik-select__valueRendered {
            z-index: 100;
            border: 1px solid $borderColorDarker;
            border-color: $borderColorDarker;
        }
    }
}
.uik-select__wrapper--open {
    .uik-select__valueRendered {
        z-index: 100;
        border: 1px solid $borderColorDarker;
        border-color: $borderColorDarker;
    }
}

.uik-select__optionListWrapper {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid $borderColorDarker;
    background: white;
    border-radius: 4px;
    position: absolute !important;
    z-index: 1000;
    top: 100%;
    right: 0;
    transform: translateY(6px);
    max-height: 306px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    min-width: 140px;
    animation: uik-select-options 0.15s;
    @keyframes uik-select-options {
        from {
            opacity: 0;
            transform: translateY(-10px);
        }
        to {
            opacity: 1;
            transform: translateY(-1px);
        }
    }
    @keyframes uik-select-options-top {
        from {
            opacity: 0;
            transform: translateY(16px);
        }
        to {
            opacity: 1;
            transform: translateY(6px);
        }
    }
    & > * {
        min-height: 1px;
    }
    &.uik-select__bottomLeft {
        transform: translateY(-1px);
        left: 0;
        right: auto;
        width: 100%;
        margin-top: 4px;
    }
    &.uik-select__topLeft,
    &.uik-select__topRight {
        left: 0;
        right: auto;
        bottom: 100%;
        top: auto;
        width: 100%;
        margin-top: 0;
        margin-bottom: 4px;
        animation: uik-select-options-top 0.15s;
    }
    &.uik-select__topRight {
        left: auto;
        width: 100%;
        right: 0;
    }
    .uik-select__optionList {
        width: 100%;
        min-height: 1px;
        max-height: 265px;
        position: relative;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar-track {
            display: initial;
        }
        &::-webkit-scrollbar {
            display: initial;
        }
        & > * {
            width: 100%;
        }
        .uik-select__option,
        .uik-select__option:focus {
            outline: none;
            cursor: pointer;
            width: 100%;
            background: transparent;
            border: none;
            text-align: left;
            border-radius: 0;
            min-height: 38px;
            padding: 1px 15px 0 15px;
            transition: all 0.15s;
            box-shadow: none;
            &:hover {
                background: $primary;
                .uik-select__optionContent {
                    color: white;
                }
            }
            .uik-select__optionContent {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                transition: all 0.15s;
                & > * {
                    min-width: 1px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                & > *:first-child {
                    flex-grow: 1;
                    flex-shrink: 1;
                }
            }
            .uik-btn__content--right {
                justify-content: space-between;
                .uik-select__label {
                    color: $textSecondary;
                    font-size: 12px;
                    transition: all 0.15s;
                }
            }
            .uik-select__check {
                color: $success;
                margin-left: 12px;
                flex-shrink: 0;
                position: relative;
                width: 5px;
                height: 5px;
                background: $success;
                border-radius: 50%;
            }
            &:hover {
                border: none !important;
                .uik-btn__content--right {
                    .uik-select__label {
                        color: white;
                    }
                }
            }
            &:not(:last-child),
            &:not(:last-child):hover {
                border-bottom: $borderDefault !important;
            }
        }
        .uik-select__option:focus {
            color: $textMain;
        }
        .uik-select__option:first-child {
            border-radius: 4px 4px 0 0;
        }
        .uik-select__option:last-child {
            border-radius: 0 0 4px 4px;
        }
        .uik-select__option--user {
            width: 100%;
            height: 60px;
            &:hover {
                background: $bg;
            }
        }
        .uik-select__no-results {
          width: 100%;
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          padding: 10px;
          padding-bottom: 15px;
          p {
            text-align: center;
            & + * {
              margin-top: 10px;
            }
          }
        }
    }
    .uik-select__option[selected] {
        font-weight: 500;
        border-left: solid 3px $primary;
        &:hover {
            border-left: solid 3px $primary !important;
            background: white !important;
            .uik-select__optionContent {
                color: $textMain;
            }
            .uik-btn__content--right {
                .uik-select__label {
                    color: $textSecondary;
                }
            }
        }
    }
    .uik-select__option--disabled,
    .uik-select__option--disabled:hover,
    .uik-select__option--disabled[selected] {
        opacity: 0.5;
        cursor: default;
        border: none;
        background: white !important;
        color: $textMain !important;
        span {
            color: $textMain !important;
        }
    }
    .uik-select__option.uik-select__option--user[selected] {
        padding-left: 11px;
        .uik-avatar__avatarWrapper {
            height: 42px;
            width: 42px;
            .uik-avatar__avatar {
                border: solid 3px $primary;
            }
        }
    }
    &--multiselect {
      .uik-select__option[selected] {
        &:hover {
          background: $borderColor !important;
        }
      }
    }
    .uik-select__mobile-head {
      display: none;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      background: $bg;
      height: 46px;
      padding: 0 10px 0 0;
      border-bottom: $borderDefault;
      position: sticky;
      top: 0;
      z-index: 1;
      .uik-select__mobile-head-input {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        position: relative;
        .uikon {
          font-size: 14px;
          color: rgba($textBase, 0.75);
          margin-right: 0;
          position: absolute;
          left: 15px;
        }
        input {
          padding: 0 10px 0 39px;
          flex-grow: 1;
          font-size: 14px;
          color: $textBase;
          font-weight: 500;
          background: transparent;
          height: 100%;
          outline: none;
          line-height: 1.4;
          border: none;
          transition: all 0.15s;
          &:focus {
            color: $textMain;
          }
        }
      }
      button {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 4px;
        border: $borderDefault;
        padding: 5px;
        transition: all 0.1s;
        .uikon {
          font-size: 14px;
          color: $textBase;
        }
        &:hover, &:active {
          transform: scale(0.9);
        }
      }
    }
}

.uik-select__placeholderAndOther {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > *:first-child {
        min-width: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .uik-select__plusValue {
        background: $borderColorDarker;
        border: $borderDefault;
        height: 20px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        min-width: 30px;
        margin-left: 10px;
        font-size: 0.7rem;
    }
}

.resource-select__bottom-link {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white;
  padding-bottom: 5px;

  button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    color: $primary;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    border: none;
    background: transparent;
    width: 100%;
    padding: 8px 20px;
    margin-bottom: -5px;
    transition: all 0.2s;

    &:hover {
      cursor: pointer;
      transition: none;
      background: $borderColor;
    }

    &:active {
      background: $borderColorDarker;
    }

    .uik-icon {
      & + span {
        margin-left: 5px;
      }
    }
  }
}

@media only #{$mediaMobile} {
  .uik-select__wrapper {
    .uik-select__optionListWrapper {
      position: fixed !important;
      bottom: 0;
      top: unset;
      left: 0;
      right: unset;
      transform: none;
      max-height: unset;
      min-height: unset;
      height: 100%;
      animation: uik-select-mobile 0.5s;
      @keyframes uik-select-mobile {
        from {
          opacity: 1;
          transform: translateY(100%);
        }
        to {
          opacity: 1;
          transform: none;
        }
      }
      .uik-select__optionList {
        max-height: unset !important;
        .uik-select__mobile-head {
          display: flex;
        }
      }
    }
  }
}
</style>
