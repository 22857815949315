export default {
  views: {
    list: {
      header: {
        title: "Maps",
        search_placeholder: "Search ...",
        add_button: "Add map"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    details: {
      header: {
        back_button: "Maps list",
        edit_button: "Edit map",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} map here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    }
  },
  popup: {
    title: "New Map",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add map",
    update_button: "Update map"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Map {title} created",
    updated: "Map {title} updated",
    deleted: "Map {title} was deleted",
    deleted_multiple: "Maps were deleted",
    not_found: "Unable to find specified map"
  }
}
