import Vue from "vue"
import Extension from "@/providers/api/helpers/extension"

export default class LoadersExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      state: {
        loaders: {}
      },
      mutations: {
        /**
         * Sets a loader value
         * For array loaders, if the value param is truthy,
         * the arrayValue will be added to array.
         * For array loaders, if the value param is not truthy,
         * the arrayValue will be removed from array
         *
         * @param {object} state - the module state
         * @param {object} params - the params to set
         * @param {string} params.namespace - the namespace of loader to set
         * @param {any} params.value - the value to set
         * @param {?any} params.arrayValue - (only for array loaders) the value to add/remove from array
         */
        SET_LOADER (state, params) {
          // Check if required params are set
          if (!params.namespace || params.value === undefined) return
          // If the arrayValue param is set assume this is an array loader
          if (params.arrayValue) {
            // If loader doesn't exist create loader
            if (!state.loaders[params.namespace]) Vue.set(state.loaders, params.namespace, [])
            // If the value is truthy and the loader array doesn't include arrayValue
            if (params.value && !state.loaders[params.namespace].includes(params.arrayValue)) {
              // Add the loaderValue to the array
              state.loaders[params.namespace].push(params.arrayValue)
            // If the value is not truthy, and the array includes the arrayValue
            } else if (!params.value && state.loaders[params.namespace].includes(params.arrayValue)) {
              // Remove the arrayLoader from the loader array
              state.loaders[params.namespace].splice(state.loaders[params.namespace].indexOf(params.arrayValue), 1)
            }
            return
          }
          // If no arrayValue is provided assume this is for a normal loader
          // If the loader doesn't exist create the loader
          if (!state.loaders[params.namespace]) Vue.set(state.loaders, params.namespace, params.value)
          // Set the loader's value
          state.loaders[params.namespace] = params.value
        }
      },
      /**
       * Toggle a loader's state
       * For normal loaders if they are currently in the truthy state
       * they will be set to false, and if they are in a non truthy state
       * they will be set to true.
       * For array loaders, if the arrayValue is in the array
       * the arrayValue will be removed from array,
       * if the arrayValue is not in the array,
       * it will be added to the array.
       *
       * @param {object} state - the module state
       * @param {object} params - the params to set
       * @param {string} params.namespace - the namespace of loader to set
       * @param {?any} params.arrayValue - (only for array loaders) the value to add/remove from array
       */
      TOGGLE_LOADER (state, params) {
        // Check if required params are set
        if (!params.namespace) return
        // If the arrayValue is provided assume this is for an array loader
        if (params.arrayValue) {
          // If no loader exists create it
          if (!state.loaders[params.namespace]) Vue.set(state.loaders, params.namespace, [])
          // If the arrayValue is in not the loader array add it to the array
          if (!state.loaders[params.namespace].includes(params.arrayValue)) {
            state.loaders[params.namespace].push(params.arrayValue)
          // If the array value is in the loader array remove it from the array
          } else if (state.loaders[params.namespace].includes(params.arrayValue)) {
            state.loaders[params.namespace].splice(state.loaders[params.namespace].indexOf(params.arrayValue), 1)
          }
          return
        }
        // If the arrayValue option is not set assume this is for a normal loader
        // If the loader doesn't exist set it to true end end execution
        if (!state.loaders[params.namespace]) return Vue.set(state.loaders, params.namespace, true)
        // If the loader is truthy set it to false if the loader is not truthy set it to true
        state.loaders[params.namespace] = !state.loaders[params.namespace]
      },
      getters: {
        GetLoader (state) {
          return (namespace, key) => {
            if (Array.isArray(state.loaders[namespace])) {
              return state.loaders[namespace].includes(key)
            }
            return state.loaders[namespace]
          }
        }
      }
    })
  }
}
