<template>
    <div class="navigation__group">
        <div class="navigation__group-title" @click="toggleOpened()">
            <UikIcon :icon="['far', opened ? 'folder-open' : 'folder']" />
            <span>{{ t(`views.${link.title}`) }}</span>
        </div>
        <div v-if="opened" class="navigation__group-subitems">
            <NavLink
                v-for="(sublink, index) in visibleLinks"
                :key="`sublink-${index}`"
                :link="sublink"
            />
        </div>
    </div>
</template>

<script>
import NavLink from "./NavLink.vue"

export default {
  langKey: "Global.menu",
  components: { NavLink },
  props: {
    link: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      opened: false
    }
  },
  computed: {
    visibleLinks () {
      return this.link.children.filter(sublink => {
        return Boolean(this.$api.Settings.userSettings?.NavigationViews?.[sublink.route])
      })
    }
  },
  mounted () {
    const savedState = Boolean(localStorage.getItem(this.link.title + "-opened"))
    if (savedState !== null) {
      this.opened = savedState
    }
  },
  methods: {
    toggleOpened () {
      this.opened = !this.opened
      localStorage.setItem(this.link.title + "-opened", this.opened ? "true" : "")
    }
  }
}
</script>

<style lang="scss">
.navigation__group {
    display: flex;
    flex-flow: column nowrap;
    padding: 16px 25px;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    color: #9EA0A5;

    &:hover {
        color: white;
        background: rgba(59, 59, 63, 0.6);
        cursor: pointer;
    }

    .navigation__group-title {
        display: flex;

        span {
            margin-left: 16px;
        }
    }

    .navigation__group-subitems {
        display: flex;
        flex-flow: column nowrap;
        margin-top: 15px;
    }
}
</style>
