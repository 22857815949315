import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/idents",
    name: "Idents",
    meta: { title: "Idents" },
    component: () => import(/* webpackChunkName: "Idents List" */ "@/Modules/Ident/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/idents/new",
    name: "New Ident",
    meta: { title: "New Ident" },
    component: () => import(/* webpackChunkName: "New Ident" */ "@/Modules/Ident/Views/EditPanel"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/idents/details/:id",
    name: "Edit Ident",
    component: () => import(/* webpackChunkName: "Edit Ident" */ "@/Modules/Ident/Views/EditPanel"),
    beforeEnter: ifAuthenticated
  }
]
