export default {
  views: {
    list: {
      header: {
        title: "Kpi-ji",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj kpi"
      },
      table: {
        title: "Naslov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Nazadnje spremenjeno",
        active: "Aktiven",
        data_is_missing: "Tabela je prazna"
      }
    },
    details: {
      current_value_table: {
        current_value: "Trenutna vrednost",
        table: {
          kpi_value: "Kpi vrednost"
        }
      },
      header: {
        back_button: "Seznam Kpi-jov",
        edit_button: "Uredi kpi",
        show_comparison: "Prikaži primerjavo",
        show_or_hide_comparison: "Pokaži ali skrij primerjalne grafikone",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tukaj lahko {action} kpi"
        }
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Nazadnje spremenjeno"
        }
      },
      historic_data_comparison: {
        kpi_line_chart: "Črtni grafikon Kpi"
      },
      historic_data_table: {
        title: "Zgodovinske vrednote",
        table: {
          kpi_value: "Kpi vrednost",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno"
        }
      }
    }
  },
  popup: {
    title: "Novi Kpi",
    fields: {
      title: {
        label: "Naslov"
      },
      store_historic_data: {
        label: "Shranite zgodovinske podatke"
      },
      polling_period: {
        label: "Perioda začetka/konca"
      },
      formula: {
        label: "Formula"
      }
    },
    add_button: "Dodaj kpi",
    update_button: "Posodobite kpi",
    components: {
      variable_form: {
        fields: {
          store_history: {
            label: "Shranite zgodovino"
          },
          title: {
            label: "Naslov"
          },
          key: {
            label: "Ključ"
          },
          description: {
            label: "Opis"
          },
          model: {
            label: "Model"
          },
          formula: {
            label: "Formula"
          }
        }
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Kpi {title} ustvarjen",
    updated: "Kpi {title} posodobljen",
    deleted: "Kpi {title} je bil izbrisan",
    deleted_multiple: "Kpi-ji so bili izbrisani",
    not_found: "Izbranega kpi-ja ni mogoče najti"
  }
}
