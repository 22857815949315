// import { ifAuthenticated } from "@/Modules/User/Routes/guards"

export default [
  {
    path: "/reports",
    name: "Reports",
    meta: { title: "Reports" },
    component: () => import(/* webpackChunkName: "Reports List" */ "@/Modules/Report/Views/List"),
    children: [
      {
        path: "new",
        name: "New Report",
        meta: { title: "New Report" }
      },
      {
        path: "edit/:id",
        name: "Edit Report (List)"
      }
    ]
    // beforeEnter: ifAuthenticated
  },
  {
    path: "/reports/details/:id",
    name: "Report Details",
    component: () => import(/* webpackChunkName: "Reports Details" */ "@/Modules/Report/Views/Details"),
    children: [
      {
        path: "edit",
        name: "Edit Report (Details)"
      }
    ]
    // beforeEnter: ifAuthenticated
  }
]
