export default [
  {
    path: "/subscription-not-paid",
    name: "SubscriptionNotPaid",
    meta: { title: "Subscription not paid" },
    component: () => import(/* webpackChunkName: "Subscription not found" */ "@/Modules/SubscriptionRedirects/Views/SubscriptionNotPaid.vue")
  },
  {
    path: "/trial-expired",
    name: "TrialExpired",
    meta: { title: "Trial expired" },
    component: () => import(/* webpackChunkName: "Trial expired" */ "@/Modules/SubscriptionRedirects/Views/TrialExpired.vue")
  },
  {
    path: "/tenant-does-not-exist",
    name: "TenantDoesNotExist",
    meta: { title: "Tenant does not exist" },
    component: () => import(/* webpackChunkName: "Tenant does not exist" */ "@/Modules/SubscriptionRedirects/Views/TenantDoesNotExist.vue")
  }
]
