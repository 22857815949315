import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/assemblies",
    name: "Assemblies",
    meta: { title: "Assemblies" },
    component: () => import(/* webpackChunkName: "Assemblies List" */ "@/Modules/Assembly/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/assemblies/details/:id",
    name: "Assembly Details",
    component: () => import(/* webpackChunkName: "Assembly Details" */ "@/Modules/Assembly/Views/Details"),
    children: [
      {
        path: "finalize",
        name: "Finalize Assembly"
      },
      {
        path: "customer/new",
        name: "Assembly New Customer Details",
        children: [
          {
            path: "postal/new",
            name: "Assembly New Customer Details New Postal"
          }
        ]
      },

      // User contacts
      {
        path: "add-user-contacts",
        name: "Add User Contacts Assembly"
      },
      {
        path: "edit-subject-contacts/:uid",
        name: "Edit Subject Contacts Assembly"
      },
      {
        path: "add-subject-contacts",
        name: "Add Subject Contacts Assembly"
      }

    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/assemblies/new",
    name: "New Assembly",
    component: () => import(/* webpackChunkName: "Assembly Details" */ "@/Modules/Assembly/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "customer/new",
        name: "Assembly New Customer",
        children: [
          {
            path: "postal/new",
            name: "Assembly New Customer New Postal"
          }
        ]
      }
    ]
  }
]
