import RequestAction from "@/providers/api/sourceDrivers/webApiDriver/actionTypes/requestAction"

export default class RemoveAction extends RequestAction {
  /**
   * Deletes a item record
   *
   * @param {object} ctx - The vuex context object
   * @param {number} id - Id of the item record we wish to delete
   * @param {number} params - Additional duplicate params
   *
   * @returns {object} Response status and data
   */
  async handle ({ state }, { id, params }) {
    const res = await this.request({ namespace: "duplicate" }).post(`/${state.route}/duplicate/${id}`, { ...params })
    return res
  }
}
