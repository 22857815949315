export default {
  layouts: {
    line_chart: {
      title: "Črtni grafikon"
    },
    area_chart: {
      title: "Območni grafikon"
    },
    bar_chart: {
      title: "Palični grafikon"
    },
    column_chart: {
      title: "Stolpični grafikon"
    },
    pie_chart: {
      title: "Krožni diagram"
    },
    treemap_chart: {
      title: "Drevesni zemljevid"
    },
    report_line_chart: {
      title: "Črtni grafikon poročila"
    },
    report_area_chart: {
      title: "Območni grafikon poročila"
    },
    report_bar_chart: {
      title: "Palični grafikon poročila"
    },
    report_column_chart: {
      title: "Stolpični grafikon poročila"
    },
    report_pie_chart: {
      title: "Krožni diagram poročila"
    },
    kpi_line_chart: {
      title: "KPI črtni grafikon"
    },
    kpi_area_chart: {
      title: "KPI obmocči grafikon"
    },
    kpi_bar_chart: {
      title: "KPI palični grafikon"
    },
    kpi_column_chart: {
      title: "KPI stolpični grafikon"
    },
    kpi_indicator: {
      title: "KPI indikator"
    },
    kpi_highlight_card: {
      title: "KPI poudarna kartica"
    }
  },
  title: "Nadzorna plošča",
  missing_configurations: "Manjka konfiguracija",
  no_configuration: "Brez konfiguracije",
  views: {
    kpi_charts: {
      kpi_chart_config: {
        title: "Naslov",
        kpi: "Kpi"
      }
    },

    report_charts: {
      one_categories_multiple_series: {
        title: "Naslov",
        report: "Poročilo"
      },
      one_categories_one_series: {
        title: "Naslov",
        report: "Poročilo"
      }
    },

    data_visualization_components: {
      kpi_highlight_card: {
        kpi: "Kpi",
        no_kpi_found: "Kpi ni bil najden",
        background: "Ozadje",
        layout: "Postavitev"
      },
      kpi_indicator_config: {
        no_kpi_found: "Kpi ni bil najden",
        kpi: "Kpi"
      }
    },

    charts: {
      multiple_series: {
        title: "Naslov",
        model: "Model"
      },
      one_series: {
        title: "Naslov",
        model: "Model"
      }
    },

    chart_config: {
      auto_categories: {
        categories_property: "Glavni podatek",
        empty_property: "Kliknite za izbor glavnega podatka",
        select_model: "Izberi model",
        date_format: "Format datuma"
      },
      auto_series: {
        name_property: "Števni podatek",
        empty_property: "Kliknite za izbor imena podatka",
        select_model: "Izberite model",
        date_format: "Format datuma števnega podatka",
        value_property: "Lastnost števnega podatka",
        select_value_properties: "Kliknite za izbor števnega podatka",
        aggregate_function: "Agregatna funkcija vrednosti serije"
      },
      predefined_series: {
        series_name: "Ime serije",
        value_property: "Lastnost serije",
        empty_property: "Klikni za izbor lastnosti serije",
        select_model: "Izberi model",
        aggregate_function: "Agregatna funkcija vrednosti serije"
      },
      series_specification: {
        series_type: "Tip serije",
        remove: "Odstrani"
      },
      series_specification_form: {
        add_series_specification: "Dodajte specifikacijo serije"
      }

    }
  }
}
