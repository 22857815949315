import Extension from "@/providers/api/helpers/extension"

import StoreAction from "./actions/store"

export default class StoreExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      actions: {
        /**
         * Creates a new item record from provided params
         */
        async store (ctx, params) {
          return new StoreAction(ctx, params, repository.api).run()
        }
      }
    })
  }
}
