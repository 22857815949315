export default {
  views: {
    list: {
      header: {
        title: "Predloge dokumentov",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj predlogo dokumenta"
      },
      table: {
        title: "Naslov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnja sprememba",
        active: "Aktiven",
        copy: "Kopija"
      }
    },
    details: {
      header: {
        back_button: "Seznam predlog dokumentov",
        edit_button: "Uredi predlogo dokumenta",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tukaj lahko {action} predlogo dokumenta"
        }
      },
      upload: {
        file: "Naloži datoteko"
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Zadnja sprememba"
        }
      }
    },
    edit: {
      header: {
        details_back_button: "Nazaj na seznam predlog",
        list_back_button: "Seznam predlog dokumentov",
        title: "Nova predloga dokumenta",
        save_button: "Shrani predlogo",
        add_button: "Dodaj predlogo",
        remove_button: "Izbriši"
      },
      footer: {
        save_button: "Shrani predlogo"
      },
      scope: {
        table: {
          model: "Model",
          feature: "Funkcija",
          response_key: "Dinamični ključ",
          remove_button: "Odstrani"
        },
        example: {
          title: "Izberite primer podatkov",
          no_results_message: "Ni rezultatov za model.",
          placeholder: "Izberite primer",
          table: {
            model: "Model",
            resource: "Vir"
          }
        },
        add_model: "Dodaj model",
        add_relation: "Dodajte razmerje",
        welcome_message: "Tukaj lahko izberete podatke, ki jih želite uporabiti v predlogi."
      }
    }
  },
  popup: {
    title: "Nova predloga dokumenta",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj predlogo dokumenta",
    update_button: "Posodobi predlogo dokumenta"
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Predloga dokumenta {title} ustvarjena",
    updated: "Predloga dokumenta {title} posodobljena",
    deleted: "Predloga dokumenta {title} je bila izbrisana",
    deleted_multiple: "Predloge dokumentov so bile izbrisane",
    not_found: "Navedene predloge dokumenta ni mogoče najti"
  }
}
