export default {
  layouts: {
    details: {
      title: "Main details"
    },
    creation_info: {
      title: "Creation info"
    }
  },
  views: {
    list: {
      header: {
        title: "Reclamations",
        search_placeholder: "Search ...",
        add_button: "Add reclamation"
      },
      table: {
        number: "Number",
        number_view: "Number view",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        copy: "Copy",
        warranty: "Warranty",
        in_warranty: "In warranty",
        out_of_warranty: "Out of warranty",

        selected_items: {
          selected: "Selected",
          items: "reclamations",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "reclamation",

          // Duality
          selected2: "Selected",
          item2: "reclamations",

          // Plural
          selected3: "Selected",
          item3: "reclamations"
        },
        data_is_missing: "Table is empty"
      }
    },
    details: {
      sync_in_progress: "Reclamation has not yet been fully transferred from Pantheon. Some data is not yet visible and editing is disabled.",
      header: {
        back_button: "Reclamation list",
        edit_button: "Edit reclamation",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} reclamation here"
        },
        in_warranty: "In warranty",
        out_of_warranty: "Out of warranty"
      },
      main: {
        title: "Main Details",
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        },
        basic: {
          headline: "Information",
          title: "Title"
        },
        customer: {
          title: "Customer",
          name: "Name",
          address: "Address"
        },
        edit: {
          title: "New Reclamation",
          is_confirmed: "Confirmed",
          number: "Number",
          number_view: "Number view",
          reclamation_date: "Reclamation date",
          due_date: "Due date",
          resolved_date: "Finished date",
          note: "Note",
          subject: {
            title: "Customer",
            no_results_message: "No result of customer",
            no_results_button: "Create new customer"
          },
          doc_type: "Document type",

          order_info_title: "Order info",
          receiver: "Receiver",
          status: "Status",
          responsible_person: "Responsible person",

          document_title: "Document types",

          delivery_title: "Delivery types",
          delivery_type: "Delivery type",
          department: "Department",

          payment_title: "Payment",
          user: {
            no_results_message: "This User does not exist",
            no_results_button: "Create new user"
          }
        }
      },
      footer: {
        remove_button: "Remove reclamation",
        save_button: "Save reclamation",
        save_button_confirmed: "Save and confirm",
        resolve: "Resolve",
        confirm_resolved: "Confirm resolved"
      },
      "creation-info": {
        resolved_date: "Resolved date",
        confirmed_date: "Confirmed date"
      },
      original_relation: {
        title: "Created from",
        relation_description: "Reclamation was created from the Invoice linked below",
        no_relation_description: "Select the Invoice this Reclamation was created from",
        type: "Type",
        types: {
          opportunity: "Opportunity",
          order: "Order",
          invoice: "Invoice"
        }
      }
    },
    edit: {
      title: "New Reclamation",
      back_button: "Reclamations list",
      tabs: {
        properties: "Properties"
      },
      footer: {
        add_button: "Add reclamation",
        save_button: "Save reclamation"
      },
      properties: {
        title: "Title"
      }
    }
  },
  popup: {
    title: "New Reclamation",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add reclamation",
    update_button: "Update reclamation"
  },
  "resolve-popup": {
    title: "Resolve Reclamation",
    resolved_title: "Confirm resolved Reclamation",
    status: "Status",
    resolve_date: "Resolve date",
    name: "Confirmer",
    not_confirmed: "This Reclamation has not been confirmed as resolved jet",
    confirm: "Confirm",
    completed: "Completed",
    success_message_resolve: "Reclamation has been successfully resolved",
    success_message_confirm: "Reclamation has been successfully confirmed"
  },
  prompts: {
    delete: {
      title: "Delete {number}",
      message: "You're about to delete {number}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Reclamation {number} created",
    updated: "Reclamation {number} updated",
    deleted: "Reclamation {number} was deleted",
    deleted_multiple: "Reclamations were deleted",
    not_found: "Unable to find specified reclamation"
  }
}
