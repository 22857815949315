import Action from "@/providers/api/actions/action"

export default class RequestAction extends Action {
  api

  constructor (ctx, params, api) {
    super(ctx, params)
    this.api = api
    this.request = api.request
  }
}
