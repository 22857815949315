import store from "@/providers/store"

async function initStateHelper () {
  try {
    const response = await store.state.init
    return response
  } catch (e) {

  }
}

export default [
  {
    path: "/",
    beforeEnter: async (to, from, next) => {
      await initStateHelper()

      if (!store.state.Auth.user) {
        return next()
      }

      const navigationPermissions = store.state.Auth.user.navigation_permissions || {}

      if (
        navigationPermissions.dashboard ||
        Object.keys(navigationPermissions).length === 0 ||
        !Object.values(navigationPermissions).includes(true)
      ) {
        return next({ path: "/dashboard" })
      } else {
        for (let i = 0; i < Object.keys(navigationPermissions).length; i++) {
          const key = Object.keys(navigationPermissions)[i]
          if (navigationPermissions[key]) {
            return next({ path: "/" + key })
          }
        }
      }
    } // default route
  },
  {
    path: "*",
    redirect: "/not-found" // 404
  }
]
