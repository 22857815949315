import Vue from "vue"
import Extension from "@/providers/api/helpers/extension"

export default class ErrorsExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      state: {
        errors: {}
      },
      mutations: {
        SET_ERROR: (state, { error, key }) => {
          let payload = {
            status: error.response.status,
            data: error.response.data
          }
          Vue.set(state.errors, key, payload)
        },
        CLEAR_ERROR: (state, key) => {
          Vue.delete(state.errors, key)
        },
        CLEAR_ERRORS: (state) => {
          Vue.set(state, "errors", {})
        }
      }
    })
  }
}
