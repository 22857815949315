/**
 * A repository template holds the state, getters, actions and mutations
 * that are used in source and storage drivers to create handlers for
 * these properties.
 *
 * @param {object|RepositoryTemplate} template - The template object to
 *                                               use as a basis for the
 *                                               generated template
 *
 * @return {RepositoryTemplate} The generated repository template
 */
export default class RepositoryTemplate {
  template = {
    namespaced: true,
    plugins: [],
    state: {},
    getters: {},
    mutations: {},
    actions: {}
  }

  // Create a template from an object or another template
  constructor (
    template
  ) {
    if (template) {
      if (template instanceof this.constructor) {
        this.extend(template.template)
      } else {
        this.extend(template)
      }
    }
  }

  // Extend the template with the properties of another template or an object
  extend (templateExtension = {}) {
    if (templateExtension.template) templateExtension = templateExtension.template
    this.template = {
      namespaced: templateExtension.namespaced !== undefined ? templateExtension.namespaced : this.template.namespaced,
      plugins: [
        ...this.template.plugins,
        ...templateExtension.plugins || []
      ],
      state: {
        ...this.template.state,
        ...templateExtension.state || {}
      },
      getters: {
        ...this.template.getters,
        ...templateExtension.getters || {}
      },
      mutations: {
        ...this.template.mutations,
        ...templateExtension.mutations || {}
      },
      actions: {
        ...this.template.actions,
        ...templateExtension.actions || {}
      }
    }
    return this.template
  }
}
