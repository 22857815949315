export default {
  layouts: {
    upload: {
      title: "Media"
    }
  },
  views: {
    list: {
      header: {
        title: "Datoteke",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj datoteke"
      },
      table: {
        title: "Naslov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnja sprememba",
        active: "Aktiven"
      }
    },
    details: {
      header: {
        back_button: "Seznam datotek",
        edit_button: "Uredi datoteke",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tu lahko {action} datoteke"
        }
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Zadnja sprememba"
        }
      }
    }
  },
  popup: {
    title: "Nova datoteka",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj datoteko",
    update_button: "Posodobi datoteko"
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Datoteka {title} ustvarjena",
    updated: "Datoteka {title} posodobljena",
    deleted: "Datoteka {title} je bila izbrisana",
    deleted_multiple: "Datoteka je bila izbrisana",
    not_found: "Določene datoteke ni mogoče najti"
  },
  widget: {
    title: "Datoteke",
    upload: "Naloži",
    generate: "Ustvari iz predloge",
    table: {
      title: "Naslov",
      collection: "Zbirka",
      data_is_missing: "Seznam je prazen"
    }
  },
  preview: {
    loading: "Vaš dokument je v pripravi",
    close: "Zapri",
    delete: "Izbriši dokument",
    discard: "Zavrzi dokument",
    downloadDrawing: "Prenesi s sliko",
    saveDrawing: "Shrani kopijo s sliko",
    sign: {
      signature: "Podpis dokumenta"
    },
    edit: {
      title: "Uredi dokument",
      description: "Urejanje dokumenta z zunanjim orodjem. Dokument bo samodejno posodobljen ob shranjevanju."
    },
    save: {
      title: "Shrani v aplikaciji",
      description: "Ustvarjeno datoteko shranite v dokumente in na voljo bo v aplikaciji."
    },
    download: {
      title: "Prenos dokumenta",
      description: "Prenesite dokument v svojo napravo.",
      downloadPdf: "Prenesite predogled (PDF)",
      downloadWork: "Prenesite dokument (Word)"
    },
    send: {
      title: "Pošlji datoteko",
      description: "Datoteka, ki je trenutno prikazana v predogledu, bo poslana na e-poštni naslov trenutno izbrane stranke."
    },
    prompts: {
      discard: {
        title: "Zavrzi dokument",
        message: "Ste prepričani, da želite zavreči dokument?",
        confirmBtn: "Zavrzi",
        cancelBtn: "Prekliči"
      },
      close: {
        title: "Dokument ni shranjen",
        message: "Ustvarjenega dokumenta niste shranili v aplikaciji.Če okno zapustite sedaj, bo dokument zavržen.",
        confirmBtn: "Zavrzi dokument",
        cancelBtn: "Prekliči"
      }
    }
  }
}
