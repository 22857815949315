import RequestAction from "@/providers/api/sourceDrivers/webApiDriver/actionTypes/requestAction"

export default class UpdateAction extends RequestAction {
  /**
   * Updates a item record with provided params
   *
   * @param {number} id - Id of the item we wish to update
   * @param {object} params - Params we wish to update the record with
   *
   * @returns {object} Response status and data
   */
  async handle ({ state }, { id, params }) {
    let res = await this.request({ namespace: "update", loaderArrayValue: id }).put(`${state.route}/${id}`, params)
    return res
  }
}
