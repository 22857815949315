export default {
  layouts: {
    notifications: {
      title: "Obvestila"
    }
  },
  views: {
    list: {
      header: {
        title: "Obvestila",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj obvestilo"
      },
      table: {
        preview: "Predogled",
        type: "Tip",
        recipient: "Prejemnik",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        data_is_missing: "Seznam je prazen"
      }
    },
    details: {
      header: {
        back_button: "Seznam obvestil"
      },
      main: {
        recipient: "Prejemnik"
      }
    }
  },
  components: {
    NotificationWidget: {
      notifications: "Obvestila",
      send_notification: "Pošlji obvestilo",
      popup: {
        title: "Pošlji novo obvestilo",
        template: "Preloga obvestila",
        cancel_button: "Prekliči",
        confirm_button: "Pošlji",
        error_message: "Da bi ta funkcija delovala, mora izbrana stranka imeti e-pošto ali telefonsko številko."
      }
    }
  },
  notify: {
    stored: "Obvestilo {title} ustvarjeno",
    updated: "Obvestilo {title} posodobljeno",
    deleted: "Obvestilo {title} je bilo izbrisano",
    deleted_multiple: "Obvestila so bila izbrisana",
    not_found: "Določenega obvestila ni mogoče najti"
  }
}
