<template>
    <transition name="loading-animation">
        <div v-if="!state" class="loading-animation">
            <div class="loading-animation__logo">
                <Logo />
            </div>
        </div>
    </transition>
</template>

<script>
import Logo from "@/Assets/Logo.vue"
export default {
  components: { Logo },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.loading-animation {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    background: $bgDark;
    background: linear-gradient(0deg, $bgDark, $bgDarkLighter);
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    .loading-animation__logo {
        animation: loading-animation-appear 0.45s 0.05s backwards;
        @keyframes loading-animation-appear {
            0% {
                opacity: 0;
                transform: scale(0.5);
            }
            60% {
                opacity: 1;
                transform: scale(1.2);
            }
            100% {
                opacity: 1;
                transform: none;
            }
        }
        svg {
            height: 25px;
            font-size: 125px;
            @media only #{$mediaTablet} {
                font-size: 105px;
            }
            color: $bg;
            // animation: loading-animation-spin 3.5s linear infinite;
            // @keyframes loading-animation-spin {
            //     from {
            //         transform: none;
            //     }
            //     to {
            //         transform: rotate(360deg);
            //     }
            // }
        }
    }
}
.loading-animation-enter, .loading-animation-leave-to {
    opacity: 0;
    left: -100vw;
}
.loading-animation-enter-to, .loading-animation-leave {
    opacity: 1;
}
.loading-animation-enter-active {
    transition: all 0.25s ease-in;
}
.loading-animation-leave-active {
    transition: left 0.2s ease-in;
}
</style>
