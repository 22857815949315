import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/calendar",
    name: "Calendar",
    meta: { title: "Calendar" },
    component: () => import(/* webpackChunkName: "Calendar" */ "@/Modules/Calendar/Views/index.vue"),
    beforeEnter: ifAuthenticated,
    children: [
      /**
       * Place routes for items in calendar here.
       *
       * Create 2 routes for each item - Create (New) and Edit (Details).
       * Each route must have 2 properties: "path" and "name".
       *
       * IMPORTANT
       * - Always use "eventId" as param for item's ID in route (no matter what the identifier property is).
       * - Use unique paths. Don't use paths like ":eventId" or "event/:eventId".
       * - Use unique names. Don't use names like "Edit <Module>" or "<Module> Details" since it might (now or in the future) be used in it's module.
       *
       * Example:
       * { path: "entity/details/:eventId", name: "Calendar Entity" },
       * { path: "entity/create", name: "New Calendar Entity" }
       */
      { path: "order/details/:eventId", name: "Calendar Order" },
      { path: "order/create", name: "New Calendar Order" },
      { path: "task/details/:eventId", name: "Calendar Task" },
      { path: "task/create", name: "New Calendar Task" }
    ]
  },
  {
    path: "/planning",
    name: "Timeline",
    meta: { title: "Planning" },
    component: () => import(/* webpackChunkName: "Calendar" */ "@/Modules/Calendar/Views/index.vue"),
    beforeEnter: ifAuthenticated,
    children: [
      { path: "task/details/:eventId", name: "Timeline Task" },
      { path: "task/create", name: "New Timeline Task" }
    ]
  }
]
