export default {
  title: "Administration",
  modules: {
    app_settings: {
      title: "Application Settings",
      description: "Change application settings for everyone."
    },
    users: {
      title: "Users",
      description: "Create, delete and manage users."
    },
    user_groups: {
      title: "User Groups",
      description: "Manage user groups and their members."
    },
    codelists: {
      title: "Codelists",
      description: "All predefined and customizable data in modules."
    },
    newsletters: {
      title: "Newsletter Lists",
      description: "Lists used to send newsletters"
    },
    document_templates: {
      title: "Document Templates",
      description: "Templates used for generating documents throughout your application."
    },
    notifications: {
      title: "Notifications",
      description: "List of sent application notifications"
    },
    notification_templates: {
      title: "Notification Templates",
      description: "Templates used for generating notifications (sms, email, and in-app) throughout you application"
    },
    fields: {
      title: "Custom Fields",
      description: "Create your own data fields and apply them to models."
    },
    mailbox: {
      title: "Mailbox",
      description: "List and details of emails sent to the mailbox."
    },
    mailbox_rules: {
      title: "Mailbox Rules",
      description: "Define rules for matching mails from the mailbox to a selected entity."
    },
    layouts: {
      title: "Layouts",
      description: "Create and edit layouts for views."
    }
  },
  app_settings: {
    title: "Application Settings",
    back_button: "Administration",
    save_button: "Save settings",
    LangAndRegion: {
      title: "Language & Region",
      general: "General",
      language: "Language",
      timezone: "Timezone",
      format: "Format",
      date_format: "Date format",
      time_format: {
        label: "Time Format",
        options: {
          12: "12 hour",
          24: "24 hour"
        }
      },
      first_day_of_week: {
        label: "First day of week",
        options: {
          0: "Sunday",
          1: "Monday",
          2: "Tuesday",
          3: "Wednesday",
          4: "Thursday",
          5: "Friday",
          6: "Saturday"
        }
      },
      decimal_places: "Decimal places"
    },
    Users: {
      title: "User"
    },
    Tables: {
      title: "Tables",
      preferences: "Preferences",
      per_page: "Number of rows on one page",
      height: {
        label: "Row height"
      }
    },
    Infobip: {
      title: "SMS",
      sender: "Sender",
      sender_id: "Sender name",
      sender_number: "Sender number"
    },
    Tasks: {
      title: "Tasks",
      form: {
        general: "General",
        default_title: "Default title",
        default_description: "Default description",
        default_status: "Default status",
        default_priority: "Default priority",
        reminders: "Reminders"
      }
    },
    Projects: {
      title: "Projects",
      form: {
        general: "General",
        default_title: "Default title",
        default_description: "Default description",
        default_status: "Default status",
        default_responsible: "Default responsible"
      }
    },
    Invoices: {
      title: "Invoices",
      form: {
        general: "General",
        default_status: "Default status",
        default_responsible: "Default responsible",
        default_note: "Note",
        default_doc_type: "Default Document Type",

        invoice_accounting: "Accounting",
        invoice_accounting_description: "Here you can generate your default invoice number view"
      }
    },
    Orders: {
      title: "Orders",
      form: {
        general: "General",
        default_status: "Default status",
        default_responsible: "Default responsible",
        default_order_date_offset: "Default order date offset (in days from today)",
        default_note: "Note",
        default_doc_type: "Default Document Type",

        order_accounting: "Accounting",
        order_accounting_description: "Here you can generate your default order number view"
      }
    },
    Supply: {
      title: "Supply",
      form: {
        general: "General",
        default_note: "Default note",
        default_status: "Default status",
        default_responsible: "Default responsible",
        default_doc_type: "Default Document Type",

        supply_accounting: "Accounting",
        supply_accounting_description: "Here you can generate your default supply number view"
      }
    },
    Assembly: {
      title: "Assembly",
      form: {
        general: "General",
        default_note: "Default note",
        default_status: "Default status",
        default_responsible: "Default responsible",

        assembly_accounting: "Accounting",
        assembly_accounting_description: "Here you can generate your default assembly number view"
      }
    },
    Opportunity: {
      title: "Opportunities",
      form: {
        general: "General",
        default_status: "Default status",
        default_responsible: "Default responsible",
        default_description: "Default description",

        invoice_accounting: "Accounting",
        invoice_accounting_description: "Here you can generate your default opportunity number view"
      }
    },
    Checklist: {
      title: "Checklist",
      form: {
        general: "General",
        default_title: "Default title",
        default_description: "Default description"
      }
    },
    Idents: {
      title: "Idents",
      form: {
        general: "General",
        subtitle: "No default settings.",
        currency: "Currency"
      }
    },
    Claims: {
      title: "Reclamations",
      form: {
        general: "General",
        default_status: "Default status",
        default_responsible: "Default responsible",
        default_note: "Note",
        default_doc_type: "Default Document Type",

        claim_accounting: "Accounting",
        claim_accounting_description: "Here you can generate your default claim number view",
        warranty_days: "Warranty days"
      }
    },
    Subjects: {
      title: "Customer",
      form: {
        general: "General",
        default_currency: "Default currency"
      }
    },
    Accounting: {
      title: "Accounting",
      number_format: "Format number",
      number_format_view: "Format number view",
      number_format_length: "Format number length"
    },
    AutomaticNotifications: {
      title: "Notifications",
      add_new: "Add new notification setting",
      update_existing: "Update notification setting",
      feature: "Feature",
      notify: "Notify",
      template: "Template",
      delay: "Delay",
      condition: "Condition",
      form: {
        logo: "Logo",
        signature: "Signature",
        delay: "Delay (in hours)",
        condition: "Send condition",
        column: "Column",
        comparison: "Comparison",
        value: "Value"
      },
      features: {
        task_create: {
          title: "Create task",
          relations: {
            responsible_person: "Responsible person",
            assignee: "Assignee",
            created_by: "Created by"
          },
          columns: {
            status: "Status"
          }
        },
        task_update: {
          title: "Update task",
          relations: {
            responsible_person: "Responsible person",
            assignee: "Assignee",
            created_by: "Created by"
          },
          columns: {
            status: "Status"
          }
        },
        user_create: {
          title: "Create user"
        },
        claim_create: {
          title: "Create claim",
          relations: {
            responsible_person: "Responsible person",
            customer: "Customer"
          },
          columns: {
            responsible_person: "Responsible person",
            resolved_date: "Resolved date"
          }
        },
        claim_update: {
          title: "Update claim",
          relations: {
            responsible_person: "Responsible person",
            customer: "Customer"
          },
          columns: {
            responsible_person: "Responsible person",
            resolved_date: "Resolved date"
          }
        },
        opportunity_create: {
          title: "Create opportunity",
          relations: {
            responsible_person: "Responsible person",
            assignee: "Assignee",
            created_by: "Created by"
          },
          columns: {
            responsible_person: "Responsible person",
            status: "Status",
            probability: "Probability",
            subject: "Customer"
          }
        },
        opportunity_update: {
          title: "Update opportunity",
          relations: {
            responsible_person: "Responsible person",
            assignee: "Assignee",
            created_by: "Created by"
          },
          columns: {
            responsible_person: "Responsible person",
            status: "Status",
            probability: "Probability",
            subject: "Customer"
          }
        }
      },
      comparisons: {
        equal: "Is equal",
        not_equal: "Is not equal",
        is_empty: "Is empty",
        is_not_empty: "Is not empty",
        has_changed: "Has changed"
      }
    },
    Licensing: {
      title: "Licensing",
      plan: "Plan",
      payment_info: "Billing Information",
      email: "E-mail",
      address: "Address",
      vat_number: "VAT number",
      selected: "Selected",
      day: "day",
      days: "days",
      free_trial: "free trial",
      plan_change_error: "Plan cannot be changed",
      plan_changed: "Plan successfully updated",
      plan_canceled: "Subscription has been canceled",
      unsubscribe: "Cancel subscription"
    },
    TravelOrders: {
      title: "Travel orders",
      general: "General",
      default_eur_per_km: "Default EUR per km",
      default_doc_type: "Default Document Type",

      accounting: "Accounting",
      accounting_description: "Here you can generate your default travel order number view"
    },
    save_error: "An error occurred while saving",
    save_success: "Successfully saved {category} settings",

    prompt: {
      confirmation: "Confirmation",
      message: "By changing the format numbering you are confirming that the numbering will start counting anew with format you set.",
      confirm_button: "Confirm",
      cancel_button: "Cancle"
    }
  },
  app_setup: {
    title: "Application setup",
    back_button: "",
    save_button: "Save settings",
    save_error: "An error occurred while saving",
    save_success: "Successfully saved",
    Application: {
      title: "Basic data",
      label: {
        company: "Company name",
        url: "Application URL",
        email: "Email",
        phone: "Phone",
        password: "Password",
        confirm_password: "Confirm password"
      }
    },
    DataProviders: {
      title: "Data provider",
      description: "Import data from supported providers.",
      label: {
        selected_provider: "Select data provider",
        host: "Host address",
        port: "Port",
        database: "Database name",
        username: "Database username",
        password: "Database password",
        connection_test: "Test database connection"
      }
    },
    Plugins: {
      title: "Plugins",
      description: "Currenlty supported providers. More coming soon.",
      link: "Link",
      registration: "Registration",
      newsletter: {
        title: "Newsletter",
        selected_provider: "Select data provider",
        squalomail: {
          description: "SqualoMail is an email marketing platform.",
          link: "https://www.squalomail.com/sl/",
          registration: "https://register.squalomail.com/free-trial/sl/",
          key: "API KEY"
        }
      },
      sms: {
        title: "Sending SMS",
        selected_provider: "Select data provider",
        smsapi: {
          description: "Mass SMS sending API.",
          link: "https://www.smsapi.si/",
          registration: "https://www.smsapi.si/registracija",
          username: "Username",
          password: "Password",
          number: "Phone number to show on sent SMS"
        }
      },
      business_data: {
        title: "Business data",
        description: "Business data provider will autofill data for business customers.",
        selected_provider: "Select data provider",
        bisnode: {
          description: "Bisnode is provider of local compnay data.",
          link: "https://bisnode.si/",
          registration: "https://accounts.bisnode.si/Authenticate/?product=0&language=en-US&returnUrl=https%3A%2F%2Faccounts.bisnode.si%2FHome%2F%3Fproduct%3D0%26language%3Dsl-SI%26basLanguage%3Den-US",
          username: "Username",
          password: "Password"
        }
      }
    }
  }
}
