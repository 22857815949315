export default {
  views: {
    list: {
      header: {
        title: "Checklists",
        search_placeholder: "Search ...",
        add_button: "Add checklist"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        copy: "Copy",

        selected_items: {
          selected: "Selected",
          items: "checklists",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "checklist",

          // Duality
          selected2: "Selected",
          item2: "checklists",

          // Plural
          selected3: "Selected",
          item3: "checklists"
        },
        data_is_missing: "Table is empty"
      }
    },
    details: {
      header: {
        back_button: "Checklists list",
        edit_button: "Edit checklist",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} checklist here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        },
        information: {
          title: {
            label: "Title"
          },
          values: {
            label: "Values"
          },
          description: {
            label: "Description"
          },
          confirmer_id: {
            label: "Confirmer ID"
          },
          confirmer_type: {
            label: "Confirmer type"
          },
          assignee_id: {
            label: "Assignee ID"
          },
          assignee_type: {
            label: "Assignee type"
          }
        }
      }
    }
  },
  popup: {
    title: "New Checklist",
    fields: {
      title: {
        label: "Title"
      },
      values: {
        label: "Values"
      },
      description: {
        label: "Description"
      }
    },
    values: {
      add_button: "Add new value",
      remove_button: "Remove value",
      title: "Item title"
    },
    add_button: "Add checklist",
    update_button: "Update checklist"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Checklist {title} created",
    updated: "Checklist {title} updated",
    deleted: "Checklist {title} was deleted",
    deleted_multiple: "Checklists were deleted",
    not_found: "Unable to find specified checklist"
  }
}
