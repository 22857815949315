import Action from "@/providers/api/actions/action"

export default class ClearDetailsAction extends Action {
  /**
   * Commits the mutation that clears the details object
   */
  async handle ({ commit }) {
    commit("CLEAR_DETAILS")
  }
}
