import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration/notifications",
    name: "Notifications",
    meta: { title: "Notifications" },
    component: () => import(/* webpackChunkName: "Notifications List" */ "@/Modules/Notification/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/notifications/details/:id",
    name: "Notification Details",
    component: () => import(/* webpackChunkName: "Notification Details" */ "@/Modules/Notification/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
