export default {
  "views": {
    "list": {
      "header": {
        "search_placeholder": "Search ...",
        "add_button": "New custom fields"
      },
      "table": {
        data_is_missing: "Table is empty",
        "columns": {
          "title": "Title",
          "description": "Description",
          "fields_count": "Num. of fields",
          "models_count": "Used on",
          "created_at": "Created",
          "updated_at": "Last modified"
        },
        "actions": {
          "open": "Open",
          "delete": "Delete",
          "duplicate": "Duplicate"
        }
      },
      "prompts": {
        "delete": {
          "title": "Delete {title}",
          "content": "You're about to delete group {title} and all custom fields it contains. This action will also remove all data stored in said fields on active models. Would you like to proceed with this action?",
          "confirm_button": "Delete",
          "cancel_button": "Cancel"
        }
      },
      "notify": {
        "duplicate_error": "An error occured while duplicating",
        "duplicate_success": "{title} successfully added",
        "delete_success": "{title} successfully deleted"
      },
      "duplicate_title_addition": " (duplicated)",
      "popup": {
        "title": "New Custom Fields",
        "section_title": "Group properties",
        "section_subtitle": "You're creating a new custom fields group.",
        "inputs": {
          "title": {
            "label": "Title",
            "placeholder": "Add a title for your group"
          },
          "description": {
            "label": "Description",
            "placeholder": "Describe the purpose of custom fields in this group."
          },
          "use_providers": {
            "label": "Use external providers in fields",
            "description": "Fields will not be editable, but used only to display data from external providers."
          }
        },
        "notify": {
          "delete_success": "{title} successfully deleted",
          "update_success": "{title} successfully updated",
          "save_error": "An error occured while saving",
          "add_success": "{title} successfully added",
          "save_success": "{title} successfully saved"
        },
        "remove_button": "Delete group",
        "confirm_button": "Add group",
        "update_button": "Update group",
        "cancel_button": "Close",
        "prompts": {
          "delete": {
            "title": "Delete {title}",
            "content": "You're about to delete group {title} and all custom fields it contains. This action will also remove all data stored in said fields on active models. Would you like to proceed with this action?",
            "confirm_button": "Delete",
            "cancel_button": "Cancel"
          }
        }
      }
    },
    "details": {
      "header": {
        "back_button": "Custom fields list",
        "edit_button": "Edit group"
      },
      "general": {
        "title": "General",
        "subtitle": "Fields group properties",
        "data": {
          "title": "Title",
          "description": "Description"
        }
      },
      "models": {
        models: {
          "assembly": "Assembly",
          "user": "User",
          "subject": "Customer",
          "task": "Task",
          "order": "Order",
          "invoice": "Invoice",
          "opportunity": "Opportunity",
          "claim": "Claim",
          "project": "Project"
        },
        "title": "Models",
        "subtitle": "Apply fields on data models",
        "remove_button_title": "Remove {title}",
        "add_text": "Select model ...",
        "prompts": {
          "remove": {
            "title": "Remove model {title}",
            "content": "You're about to remove model {title}. All data stored in custom fields under this setting will also be permanently deleted. Would you like to proceed with this action?",
            "confirm_button": "Remove",
            "cancel_button": "Cancel"
          }
        },
        "notify": {
          "remove_success": "{schema} fields have been removed from model {title}",
          "remove_error": "An error occured while removing model {title}",
          "add_success": "{schema} fields have been applied to model {title}",
          "add_error": "An error occured while applying model {title}"
        }
      },
      "fields": {
        "title": "Fields",
        "untitled_field": "Untitled field",
        "add_field_button": "Add new field",
        "preview_fields_button": "Preview all fields",
        "delete_field_button": "Delete field",
        "types": {
          "string": "Text",
          "enum": "Select",
          "integer": "Integer",
          "decimal": "Decimal",
          "boolean": "Toggle",
          "date": "Date"
        },
        "properties": {
          "title": {
            "label": "Title",
            "placeholder": "Title diplayed above the field"
          },
          "key": {
            "label": "Key",
            "placeholder": "Unique key for storing data",
            "errors": {
              "empty": "Key is required",
              "duplicated": "This key already exists"
            }
          },
          "type": {
            "label": "Type"
          },
          "codelist": {
            "label": "Options",
            "placeholder": "Codelist with select options",
            "no_results_message": "Your search for {{ search }} did not have any matches"
          },
          "description": {
            "label": "Description",
            "placeholder": "Text displayed below title"
          },
          "places": {
            "label": "Decimal places",
            "placeholder": "Number of maximum decimal places"
          },
          "required": {
            "label": "Required",
            "options": {
              "y": "Yes",
              "n": "No"
            }
          },
          "placeholder": {
            "label": "Placeholder",
            "placeholder": "Text displayed in input when there's no value"
          },
          "element": {
            "label": "Visualization",
            "options": {
              "input": "Single line",
              "textarea": "Multi line"
            }
          },
          "maxlength": {
            "label": "Max length",
            "placeholder": "Maximum characters allowed (including spaces)"
          },
          "min": {
            "label": "Min value",
            "placeholder": "Minimum value allowed"
          },
          "max": {
            "label": "Max value",
            "placeholder": "Maximum value allowed"
          },
          "default": {
            "label": "Default value",
            "placeholder": "Field's initial value"
          },
          "default_connection": {
            "title": "Default Connection",
            "description": "Automatically fill this field with value from another custom field.",
            "enabled": "Default Connection enabled",
            "disabled": "Default Connection disabled",
            "model": "Model",
            "relation": "Relation",
            "group": "Group",
            "field": "Field"
          },
          "provider": {
            "label": "External provider (for data sync)",
            "options": {
              "n": "No provider",
              "pantheon": "Pantheon",
              "bisnode": "Bisnode"
            }
          },
          "provider_key": {
            "label": "Provider key",
            "placeholder": "Select provider field"
          }
        },
        "prompts": {
          "delete": {
            "title": "Delete {title}",
            "content": "You're about to delete field {title}. Would you like to proceed with this action?",
            "confirm_button": "Delete",
            "cancel_button": "Cancel"
          }
        }
      },
      "notify": {
        "update_success": "{title} successfully updated",
        "save_error": "An error occured while saving"
      },
      "footer": {
        "undo_button": "Undo all changes",
        "save_button": "Save changes",
        "save_button_saved": "Saved",
        "notify": {
          "error_empty_key": "Unable to save due to a field without a key",
          "error_duplicated_key": "Unable to save due to duplicated keys"
        }
      },
      "before_leave_alert": {
        "title": "Changes you made are not saved",
        "message": "You're about to leave this page without saving. Your changes will be discarded. Are you sure you'd like to leave?",
        "confirm_button": "Leave",
        "cancel_button": "Stay on this page"
      }
    }
  },
  "components": {
    "title": "Custom Fields",
    "field": {
      "select": {
        "no_results": "Your search for {{ search }} did not have any matches"
      }
    },
    "render": {
      "toggle": {
        "true": "Yes",
        "false": "No"
      },
      "dummy": {
        "title": "Custom Fields Group",
        "label": "Custom field",
        "value": "..."
      },
      "notify_removed_group": "A custom fields group you were using in your layout, has been removed from data model. We've also removed it from your layout to avoid any issues.",
      "notify_removed_fields": "A Custom Fields widget in your layout didn't have any fields to display. We've removed it from your layout to avoid any issues.",
      "settings": {
        "groups": "Show groups",
        "all_groups": "All groups",
        "type": "Display groups in",
        "type_widgets": "Separate widgets",
        "type_sections": "One widget"
      }
    }
  }
}
