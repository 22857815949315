import VuexWebApiConnector from "@/providers/api/connectors/VuexWebApiConnector"
import Api from "@/providers/api/helpers/api"
import Errors from "./LaravelRepository/extensions/Errors/extension"

let getApiUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return (!process.env.VUE_APP_NO_LOCATION_API_URL ? location.origin + "/" : "") + process.env.VUE_APP_API
  }
  return process.env.VUE_APP_API
}

export default class LaravelRepository extends VuexWebApiConnector {
  extensions = {
    Errors
  }

  constructor () {
    super()
    this.loadClassExtensions()
    this.api = new Api(this.ctx, {
      baseURL: getApiUrl(),
      headers: {
        common: {
          "X-Requested-With": "XMLHttpRequest"
        },
        post: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      },
      withCredentials: true
    })
    this.request = this.api.request
  }

  register () {
    this.template.extend({ state: { route: this.route } })
    this.template.extend({
      namespaced: this.namespaced,
      plugins: this.plugins || [],
      state: this.state || {},
      getters: this.getters || {},
      actions: this.actions || {},
      mutations: this.mutations || {}
    })
    return this
  }
}
