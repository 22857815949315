export default {
  confirm: {
    title: "Confirming the sms sender id.",
    description: "Working on the sms sender confirmation, this can take a couple of seconds."
  },
  confirm_success: {
    title: "Success!",
    description: "The sms sender id has been successfully confirmed and will now be used when sending."
  },
  confirm_error: {
    title: "Error.",
    description: "There was a problem when trying to confirm the sms sender id.",
    error_title: "Error message:",
    contact_admin: "For more information, please contact the administrator."
  }
}
