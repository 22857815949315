export default {
  layouts: {
    details: {
      title: "Glavne podrobnosti"
    },
    customer: {
      title: "Stranka"
    }
  },
  actions: {
    claim: "Ustvarite reklamacijo",
    cancellation: "Stornacija",
    preview: "Predogled dokumenta"
  },
  views: {
    list: {
      header: {
        title: "Računi",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj račun"
      },
      table: {
        number: "Številka",
        number_view: "Prikaz številke",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Nazadnje spremenjeno",
        active: "Aktiven",
        copy: "Kopirati",
        selected_items: {
          selected: "Izbrano",
          items: "računi",
          remove: "Odstrani izbiro",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          item1: "račun",

          // Duality
          selected2: "Izbrano",
          item2: "računa",

          // Plural
          selected3: "Izbrano",
          item3: "računov"
        },
        subject: "Stranka",
        status: "Status",
        invoice_date: "Datum",
        data_is_missing: "Tabela je prazna",
        accounted: "Obračunano"
      }
    },
    details: {
      sync_in_progress: "Račun še ni v celoti prenešen iz Pantheona. Nekateri podatki še niso vidni in urejanje je onemogočeno.",
      header: {
        title: "Nov račun",
        back_button: "Seznam računov",
        invoice_is_canceled: "Storniraj",
        invoice_button: "Ustvarite račun",
        send_to_customer_button: "Pošlji stranki"
      },
      footer: {
        remove_button: "Izbriši račun",
        add_button: "Dodaj račun",
        save_button: "Shranite račun",
        save_button_confirmed: "Shranite in potrdite",
        confirmed: "Potrjeno"
      },
      main: {
        title: "Glavne informacije"
      },
      tasks: {
        back_button: "Nazaj na račun"
      },
      properties: {
        main: {
          title: "Nov račun",
          required_data: "Podatki o računu",
          number: "Številka računa",
          number_view: "Prikaz številke",
          subject: {
            label: "Stranka",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvari novo stranko"
          },
          invoice_date: "Datum računa",
          service_date: "Datum storitve",
          status: "Status",
          department: {
            label: "Oddelek",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvari nov oddelek"
          },
          note: "Opomba",
          doc_type: "Vrsta dokumenta",
          created_from_opportunity: "Ustvarjen iz priložnosti",
          responsible_person: "Odgovorna oseba",
          confirmed: {
            label: "Potrjeno",
            yes: "Račun je potrjen",
            no: "Račun ni potrjen"
          },
          user: {
            no_results_message: "Ta uporabnik ne obstaja",
            no_results_button: "Ustvari novega uporabnika"
          },
          subject_no_result_data: {
            no_results_message: "Ta stranka ne obstaja",
            no_results_button: "Ustvari novo stranko"
          },
          days_for_payment: "Dnevi za plačilo",
          due_date: "Datum plačila"
        },
        status: {
          title: "Status",
          status: {
            draft: "Osnutek",
            "in-progress": "V izdelavi",
            complete: "Dokončano"
          }
        },
        delivery: {
          title: "Dostava",
          receiver: {
            label: "Dostaviti",
            no_results_message: "Vaše iskanje {{search}} ni imelo nobenega zadetka",
            no_results_button: "Ustvari novo stranko"
          },
          delivery_deadline: "Dobavni rok",
          delivery_type: "Vrsta dostave",
          department_code: "Šifra oddelka",
          sale_method: "Način prodaje",
          amount: "Znesek"
        },
        accounting_summary: {
          title: "Računovodski povzetek"
        },
        accounting_items: {
          title: "Računovodske postavke"
        }
      },
      relations: {
        tabs: {
          opportunities: "Priložnosti"
        },
        add_buttons: {
          opportunities: "Nova priložnost"
        }
      },
      original_relation: {
        title: "Ustvarjeno iz",
        relation_description: "Račun je bil ustvarjen iz {type} na spodnji povezavi",
        no_relation_description: "Izberite {vrsto}, iz katere je bil ustvarjen ta račun",
        type: "Vrsta",
        types: {
          opportunity: "Priložnost",
          order: "Ponudba",
          invoice: "Račun"
        }
      },
      custom_fields: {
        title: "Polja po meri"
      },
      customer: {
        title: "Stranka",
        customer: "Stranka"
      },
      creation_info: {
        title: "Informacije o ustvarjanju"
      }
    }
  },
  prompts: {
    delete: {
      title: "Izbriši račun",
      message: "Izbrisali boste račun {številka}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    create_invoice: {
      title: "Ustvarite račun iz računa",
      message: "Iz tega računa bo ustvarjen nov račun. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Ustvari račun"
    },
    cancellation: {
      title: "Prekliči ta račun",
      message: "Ali res želite preklicati {number} račun?",
      cancel: "Prekliči",
      confirm: "Potrdi"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Račun shranjen",
    updated: "Račun posodobljen",
    deleted: "Račun je bil izbrisan",
    deleted_multiple: "Računi so bili izbrisani",
    not_found: "Navedenega računa ni mogoče najti"
  },
  status: {
    draft: "Osnutek",
    "in-progress": "V izdelavi",
    completed: "Plačilo sprejeto",
    confirmed: "Potrjeno",
    not_confirmed: "Ni potrjeno"
  },
  accounted: {
    yes: "Obračunano",
    no: "Ni obračunano"
  }
}
