export default {
  layouts: {
    contacts: {
      title: "Contacts"
    },
    tasks: {
      title: "Tasks"
    },
    newsletters: {
      title: "Newsletters"
    }
  },
  views: {
    list: {
      header: {
        title: "Customers",
        search_placeholder: "Search ...",
        add_button: "Add customer"
      },
      table: {
        name: "Name",
        code: "Code",
        last_modified: "Last modified",
        active: "Active",
        copy: "Copy",

        selected_items: {
          selected: "Selected",
          items: "customers",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "customer",

          // Duality
          selected2: "Selected",
          item2: "customers",

          // Plural
          selected3: "Selected",
          item3: "customers"
        },
        data_is_missing: "Table is empty"
      }
    },
    details: {
      sync_in_progress: "Customer has not yet been fully transferred from Pantheon. Some data is not yet visible and editing is disabled.",
      header: {
        back_button: "Customers list",
        edit_button: "Edit customer",
        send_screenshare_link: "Send screenshare link",
        screenshare: {
          title: "The screenshare link was sent",
          message: "You can access the call here:",
          link: "Go to call",
          close: "Close"
        },
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} customer here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        tabs: {
          basic: "Basic",
          postal: "Postal",
          payment: "Payment",
          supplier: "Supplier",
          contact: "Contact"
        },
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        },
        basic: {
          title: "Title",
          title_long: "Title long",
          code: "Code",
          vat_number: "VAT Number",
          registration_number: "Registration number",
          is_company: "Customer is company",
          is_not_company: "Customer is not company",
          is_customer_company: "Is customer company?",
          is_supplier: "Is customer supplier?",
          is_enabled: "Is customer enabled?",
          is_buyer: "Is customer buyer?",
          is_vat_payer: "Is customer VAT payer?",
          yes: "Yes",
          no: "No",
          responsible_person: "Responsible person"
        },
        payment: {
          rebate: "Rebate",
          rebate_max: "Rebate max",
          days_for_payment: "Days for payment",
          currency: "Currency",
          price_rate: "Price rate",
          payment_method: "Payment method",
          sale_method: "Sale method",
          limit: "Limit",
          delivery_type: "Delivery type",
          buyer_type: "Buyer type"
        },
        supplier: {
          supplier_rebate: "Supplier rebate",
          supplier_days_for_payment: "Days for payment",
          supplier_purchase_method: "Purchase method",
          supplier_delivery_type: "Delivery type",
          supplier_payment_method: "Payment method"
        }
      },
      contacts: {
        contacts: "Contacts",
        users: "Users",
        title: "Title",
        name: "Name",
        full_name: "Full name",
        email: "Email",
        phone: "Phone",
        default: "Default",
        add_user_button: "Add users",
        add_subject_button: "Add contacts",
        remove_button: "Remove",
        table_empty: "Table is empty",
        tabs: {
          subjects: "Contacts",
          users: "Conected users"
        }
      },
      newsletters: {
        list: "Newsletters list",
        add: "Add to newsletter"
      },
      tasks: {
        tasks: "Tasks",
        add_new: "Add task",
        table: {
          "title": "Title",
          "status": "Status",
          "priority": "Priority",
          "due_date": "Due date",
          data_is_missing: "Table is empty"
        },
        back_button: "Back to customer"
      },
      relations: {
        title: "Connected documents",
        tabs: {
          orders: "Orders",
          opportunities: "Opportunities",
          invoices: "Invoice",
          claims: "Reclamation"
        },
        add_buttons: {
          orders: "New order",
          invoices: "New invoice",
          opportunities: "New Opportunity",
          claims: "New Reclamation"
        },
        orders: {
          details: {
            back_button: "Back to customer"
          }
        },
        invoices: {
          details: {
            back_button: "Back to customer"
          }
        },
        claims: {
          details: {
            back_button: "Back to customer"
          }
        }
      },
      users: {
        title: "Users",
        add_button: "Create a new user",
        data_is_missing: "Table is empty"
      }
    },
    edit: {
      sync_in_progress: "Customer has not yet been fully transferred from Pantheon. Some data is not yet visible and editing is disabled.",
      header: {
        details_back_button: "Back to details",
        list_back_button: "Customers list",
        title: "New Customer",
        save_button: "Save customer",
        add_button: "Add customer",
        remove_button: "Delete"
      },
      main: {
        general: "General",
        basic_info: "Basic information",
        title: "Title",
        title_long: "Title long",
        name: "Name",
        surname: "Surname",
        code: "Code",
        vat_number: "VAT Number",
        vat_tooltip_text: "By pressing Enter after filling in the VAT number field, the customer data will be filled from the external provider Bisnode.",
        registration_number: "Registration number",
        registration_tooltip_text: "By pressing Enter after filling in the registration number field, the customer data will be filled from the external provider Bisnode.",

        responsible_person: "Responsible person",
        buyer_type: "Buyer type",
        delivery_type: "Delivery type",
        payment_method: "Payment method",
        sale_method: "Sale method",
        price_rate: "Price rate",

        payment: "Payment",
        information: "Information",
        rebate: "Rebate",
        rebate_max: "Rebate max",
        limit: "Limit",
        days_for_payment: "Days for payment",
        currency: "Currency",
        ext_id: "Extension",
        ext_provider: "Extension provider",

        is_vat_payer: "Is VAT payer",
        is_company: "Is company",
        is_buyer: "Is buyer",
        is_supplier: "Is supplier",
        is_enabled: "Is enabled",

        supplier: "Supplier",
        supplier_rebate: "Rebate",
        supplier_days_for_payment: "Days for payment",
        supplier_purchase_method: "Purchase method",
        address_contacts: "Address & Contact"
      }
    }
  },
  popup_users: {
    title: "Title",
    email: "Email",
    phone: "Phone",
    search_placeholder: "Search users...",
    number_of_selected_users: "Number of selected users: ",
    remove_button: "Remove selected",
    add_button: "Add user",
    update_button: "Update user"
  },
  popup_subjects: {
    name_title: "Name title",
    title: "Add new contact",
    full_name: "Full name",
    email: "Email",
    phone: "Phone",
    is_default: "Set as default",
    update_button: "Update",
    add_button: "Add contact"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Customer {title} created",
    updated: "Customer {title} updated",
    deleted: "Customer {title} was deleted",
    deleted_multiple: "Customers were deleted",
    not_found: "Unable to find specified customer"
  },
  contacts: {
    prompts: {
      delete: {
        title: "Delete {full_name}",
        message: "You're about to delete {full_name}. Would you like to proceed with this action?",
        cancel: "Cancel",
        confirm: "Delete"
      }
    },
    notify: {
      stored: "Contact {full_name} created",
      updated: "Contact {full_name} updated",
      deleted: "Contact {full_name} was deleted",
      not_found: "Unable to find specified contact"
    }
  }
}
