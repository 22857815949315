export default {
  layouts: {
    mailbox: {
      title: "Poštni nabiralnik"
    }
  },
  views: {
    list: {
      header: {
        title: "Poštni predal",
        search_placeholder: "Iskanje ..."
      },
      table: {
        subject: "Stranka",
        sender: "Pošiljatelj",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        last_modified: "Nazadnje spremenjeno",
        data_is_missing: "Tabela je prazna"
      }
    },
    details: {
      header: {
        back_button: "Seznam poštnih predalov"
      },
      main: {
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Nazadnje spremenjeno"
        },
        contents: {
          title: "Vsebina"
        }
      }
    }
  },
  components: {
    MailboxWidget: {
      mailbox_list: "Povezana e-poštna sporočila",
      sync: "Sinhroniziraj"
    }
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Nabiralnik {title} ustvarjen",
    updated: "Nabiralnik {title} posodobljen",
    deleted: "Nabiralnik {title} je bil izbrisan",
    deleted_multiple: "Nabiralniki so bili izbrisani",
    not_found: "Določenega poštnega predala ni mogoče najti"
  }
}
