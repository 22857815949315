import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/projects",
    name: "Projects",
    meta: { title: "Projects" },
    component: () => import(/* webpackChunkName: "Projects List" */ "@/Modules/Project/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/projects/details/:id",
    name: "Project Details",
    component: () => import(/* webpackChunkName: "Project Details" */ "@/Modules/Project/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "task/new",
        name: "New Project Task"
      },
      // Task
      {
        path: "task/details/:taskId",
        name: "Project Task"
      },
      {
        path: "customer/new",
        name: "Project New Customer Details",
        children: [
          {
            path: "postal/new",
            name: "Project New Customer Details New Postal"
          }
        ]
      },
      {
        path: "user/new",
        name: "Project New User Details"
      },
      {
        path: "access",
        name: "Project User Access"
      }
    ]
  },
  {
    path: "/projects/new",
    name: "New Project",
    component: () => import(/* webpackChunkName: "Project Details" */ "@/Modules/Project/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "customer/new",
        name: "Project New Customer",
        children: [
          {
            path: "postal/new",
            name: "Project New Customer New Postal"
          }
        ]
      },
      {
        path: "user/new",
        name: "Project New User"
      }
    ]
  }
]
