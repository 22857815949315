export default {
  user: {
    layouts: {
      users_list: {
        title: "Seznam uporabnikov"
      }
    },
    views: {
      list: {
        header: {
          title: "Uporabniki",
          search_placeholder: "Iskanje ...",
          add_button: "Dodaj uporabnika"
        },
        table: {
          title: "Naslov",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          author: "Avtor",
          last_modified: "Zadnja sprememba",
          active: "Aktiven"
        }
      },
      details: {
        header: {
          back_button: "Seznam uporabnikov",
          edit_button: "Uredi uporabnika",
          toggle: {
            title: "Aktiven",
            activate: "aktiviraj",
            deactivate: "deaktiviraj",
            description: "Tu lahko {action} uporabnika"
          }
        },
        main: {
          active: "Aktiven",
          inactive: "Neaktiven",
          overview: {
            author: "Avtor",
            created_at: "Ustvarjeno",
            updated_at: "Posodobljeno",
            last_modified: "Zadnja sprememba"
          }
        },
        resetPassword: {
          title: "Spremeni geslo",
          subtitle: "Varnost in avtentikacija",
          form: {
            currentPassword: "Trenutno geslo",
            newPassword: "Novo geslo",
            changePassword: "Spremeni geslo"
          },
          feedback: {
            succcess: "Uspešno ste spremenili uporabniško geslo.",
            changeItAgain: "Želite ponovno spremeniti?"
          }
        },
        users_list: {
          title: "Dodani uporabniki",
          add_user: "Dodaj uporabnika",
          table: {
            name: "Ime",
            data_is_missing: "Table is empty"
          }
        },
        navigationPermissions: {
          title: "Navigacijska dovoljenja",
          subtitle: "Kateri moduli so skriti v navigaciji za tega uporabnika",
          module: "Modul",
          licence: "Licenca",
          show: "Prikaži",
          plans: {
            starter_licence: "Osnovno",
            standard_licence: "Napredno",
            map: "Zemljevid",
            claims: "Reklamacije",
            travel_orders: "Potni nalogi",
            assembly: "Sestava",
            advanced_invoices: "Avansni račun",
            supply: "Dobava",
            projects: "Projekti",
            opportunities: "Priložnosti"
          }
        },
        plans: {
          title: "Licence",
          subtitle: "Katere licence so dodeljene temu uporabniku",
          plan: "Načrtujte",
          available: "Na voljo",
          assigned: "Dodeljeno",
          plans: {
            starter_licence: "Osnovno",
            standard_licence: "Napredno",
            map: "Zemljevid",
            claims: "Reklamacije",
            travel_orders: "Potni nalogi",
            assembly: "Montaža",
            advanced_invoices: "Avansni računi",
            supply: "Montaža",
            projects: "Projekti",
            opportunities: "Priložnosti"
          }
        }
      }
    },
    popup: {
      title: "Nov uporabnik",
      active_user: "Aktiven uporabnik",
      active_user_desc: "Aktiviranemu uporabniku ne bo treba potrditi svojega emaila.",
      fields: {
        name: "Polno ime",
        email: "E-naslov"
      },
      add_button: "Dodaj uporabnika",
      update_button: "Posodobi uporabnika"
    },
    prompts: {
      delete: {
        title: "Izbriši {name}",
        message: "Izbrisali boste {name}. Ali želite nadaljevati s tem dejanjem?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    },
    notify: {
      stored: "Uporabnik {name} ustvarjen",
      updated: "Uporabnik {name} posodobljen",
      deleted: "Uporabnik {name} je bil izbrisan",
      not_found: "Določenega uporabnika ni mogoče najti"
    }
  },
  login: {
    auth: {
      failed: "Te poverilnice se ne ujemajo z našo evidenco.",
      throttle: "Preveč poskusov prijave. Poskusite ponovno čez :seconds sekund.",
      signup_success: "Uspešno ustvarjen uporabnik!",
      login_failed: "Te poverilnice se ne ujemajo z našo evidenco.",
      logout_success: "Uspešno odjavljen",
      token_invalid: "Aktivacijski žeton ni veljaven.",
      email_signup_activate_subject: "Potrdite svoj račun",
      email_signup_activate_line1: "Hvala za prijavo! Preden začnete, morate potrditi svoj račun.",
      email_signup_activate_action: "Potrdi račun",
      email_signup_activate_line2: "Hvala, ker uporabljate našo aplikacijo!",
      email_signup_activated_subject: "Dobrodošli v :appName",
      email_signup_activated_line1: "Zaključili ste registracijo! Vaš račun je aktiven.",
      email_signup_activated_line2: "Sedaj se lahko prijavite"
    }
  },
  auth: {
    login: {
      title: "Vpiši se",
      sub_title: "AcronX",
      username: "Uporabniško ime",
      password: "Geslo",
      remember_me: "Zapomni si me",
      login: "Vpiši se",
      forgot_password: "Ste pozabili geslo?",
      forgot_password_text: "Ne skrbi. Vaše geslo je mogoče ponastaviti.",
      reset_password: "Ponastavitev gesla",
      trouble_loggin_in_text: "Imate težave pri prijavi?",
      contact_administrator: "Obrnite se na skrbnika",
      auth: {
        login_failed: "Uporabnik ne obstaja, je neaktiven ali pa je kombinacija uporabniškega imena in gesla napačna. Prosim poskusite ponovno."
      }
    },
    reset_password: {
      title: "Novo geslo",
      sub_title: "Vnesite svoje novo geslo.",
      email: "E-naslov",
      new_password: "Novo geslo",
      change_password: "Spremeni geslo",

      remembered_your_password: "Ste se pravkar spomnili svojega gesla?",
      try_again: "Vrnite se na prijava in poskusite znova. Vedno se lahko vrnete in zahtevate novo geslo.",
      login: "Vpiši se"
    },
    forgot_password: {
      title: "Ponastavitev gesla",
      sub_title: "Povejte nam svoj e-poštni naslov in poslali vam bomo e-poštno sporočilo s povezavo za ponastavitev gesla.",
      email: "E-naslov",
      send: "Pošlji",
      email_text: "E-pošta bi morala kmalu prispeti v vaš nabiralnik.",
      receiving_email_text: "Kliknite tukaj, da poskusite znova, če e-poštno sporočilo ni prispelo.",

      remember_title: "Ste se pravkar spomnili svojega gesla?",
      go_back_to_login: "Vrnite se na prijava in poskusite znova. Vedno se lahko vrnete in zahtevate novo geslo.",
      login: "Vpiši se",

      no_access_text: "Nimate dostopa za prijavo, a mislite, da bi ga morali?",
      contact_administrator: "Obrnite se na skrbnika"
    }
  },
  permissions: {
    title: "Dovoljenja",
    add_button: "Dodaj dovoljenja",
    table: {
      columns: {
        permission: "Dovoljenje",
        module: "Modul"
      },
      remove_button: "Odstrani dovoljenje"
    },
    module: {
      Administration: "Administracija",
      Assembly: "Montaža",
      Auth: "Auth",
      Calendar: "Koledar",
      Chart: "Gafikon",
      Checklist: "Kontrolni seznam",
      Claim: "Reklamacija",
      Codelist: "Seznam šifrantov",
      DocumentTemplate: "Predloga dokumenta",
      Field: "Polje",
      Ident: "Izdelki",
      Invoice: "Račun",
      Kpi: "Kpi",
      Layout: "Postavitev",
      Media: "Mediji",
      NotificationTemplate: "Predloga obvestila",
      Opportunity: "Priložnost",
      Order: "Ponudba",
      Project: "Projekt",
      PurchaseOrder: "Naročilnica",
      Report: "Poročilo",
      Subject: "Stranka",
      Supply: "Dobava",
      Task: "Opravila",
      TravelOrder: "Potni nalog",
      UserSettings: "Uporabniške nastavitve",
      WorkOrder: "Delovni nalog",
      Sync: "Sync"
    },
    model: {
      AdministrationUser: "Uporabnik administracije",
      Assembly: "Montaža",
      Permission: "Dovoljenje",
      Role: "Vloga",
      User: "Uporabnik",
      Calendar: "Koledar",
      Chart: "Grafikon",
      Checklist: "Kontrolni seznam",
      Claim: "Reklamacija",
      CodelistData: "Podatki šifranta",
      DocumentTemplate: "Predloga dokumenta",
      Field: "Polje",
      Ident: "Izdelek",
      Invoice: "Račun",
      Kpi: "Kpi",
      Layout: "Postavitev",
      Media: "Mediji",
      NotificationTemplate: "Predloga obvestila",
      Opportunity: "Priložnost",
      Order: "Ponudba",
      Project: "Projekt",
      PurchaseOrder: "Naročilnica",
      Report: "Poročilo",
      Subject: "Stranka",
      Supply: "Dobava",
      Task: "Opravila",
      DirectExpense: "Neposredni stroški",
      TravelExpense: "Potni stroški",
      TravelOrder: "Potni nalog",
      UserSettings: "Uporabniške nastavitve",
      WorkOrder: "Delovni nalog",
      Sync: "Sync"
    },
    empty_notice: "Za {name} ni dovoljenj.",
    scope_drawer: {
      title: "Dodaj obseg v {name}",
      cancel_button: "Prekliči",
      confirm_button: "Nastavite obseg"
    },
    add_drawer: {
      title: "Dodaj dovoljenja za {name}",
      search_placeholder: "Iskanje ...",
      permissions_count: "{count} izbrano",
      table: {
        columns: {
          permission: "Dovoljenje",
          module: "Modul"
        }
      },
      cancel_button: "Prekliči",
      confirm_button: "Dodaj izbrana dovoljenja",
      notify: {
        success: "{count} dovoljenj, dodanih {name}",
        error: "Med dodajanjem dovoljenj za {name} je prišlo do napake"
      }
    }
  },
  access_drawer: {
    title: "Upravljanje uporabnikov",
    search: "Iskanje...",
    tag: "Število uporabnikov",
    table: {
      name: "Ime"
    },
    cancel_button: "Prekliči",
    save_button: "Shrani"
  }
}
