import store from "@/providers/store"
import vuexI18n from "vuex-i18n"

const registerLangs = Vue => {
  const modules = require.context("@/Modules/", true, /Langs\/[^\\]*\.(ts|js)$/i)
  modules.keys().flatMap(key => {
    const module = key.split("/")[1]

    let language = key.split("/")
    language = language[language.length - 1].split(".")[0]

    const translations = modules(key).default

    Vue.i18n.add(language, { [module]: translations })
  })

  const global = require.context("@/Langs/", true, /[^\\]*\.(ts|js)$/i)
  global.keys().flatMap(key => {
    let language = key.split("/")
    language = language[language.length - 1].split(".")[0]

    const translations = global(key).default

    Vue.i18n.add(language, { Global: translations })
  })
}

export default {
  install (Vue) {
    Vue.use(vuexI18n.plugin, store)

    registerLangs(Vue)

    Vue.i18n.set("en-EN")

    Vue.mixin({
      methods: {
        t (key) {
          // Clone arguments and return all but first argument
          let otherArgs = [...arguments]
          otherArgs.shift()
          // If the langKey component option is set prepend it to the key
          if (this.$options.langKey) return this.$t(this.$options.langKey + "." + key, ...otherArgs)
          return this.$t(key, ...otherArgs)
        }
      }
    })
  }
}
