export default {
  views: {
    list: {
      header: {
        title: "Seznam masovnega marketinga",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj nov seznam pošiljanja"
      },
      table: {
        name: "Naslov",
        description: "Opis",
        color: "Barva",
        data_is_missing: "Seznam je prazen"
      },
      popup: {
        subscribe: "Naročite se na"
      }
    },
    details: {
      header: {
        back_button: "Seznam list pošiljanja"
      },
      main: {
        overview: {
          description: "Opis"
        }
      },
      subscribed_list: {
        title: "Naročeni maili",
        id: "Id",
        email: "Email"
      }
    }
  },
  popup: {
    title: "Nov seznam pošiljanja",
    fields: {
      title: {
        label: "Naslov"
      },
      description: {
        label: "Opis"
      }
    },
    add_button: "Dodaj seznam pošiljanja"
  },
  notify: {
    stored: "Seznam pošiljanja {title} ustvarjen",
    updated: "Seznam pošiljanja {title} posodobljen",
    deleted: "Seznam pošiljanja {title} je bil izbrisan",
    deleted_multiple: "Seznami pošiljanja so bili izbrisani",
    not_found: "Ni mogoče najti določenega seznama pošiljanja"
  }
}
