import Action from "@/providers/api/actions/action"

export default class SetEditAction extends Action {
  /**
   * Sets the edit param
   *
   * @param {string} param - the edit param to set
   */
  async handle ({ commit }, param) {
    commit("SET_EDIT", param)
  }
}
