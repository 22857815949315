<template>
    <div v-if="false"/>
</template>

<script>
export default {
  beforeCreate () { this.$emit("beforeCreate") },
  created () { this.$emit("created") },
  beforeMount () { this.$emit("beforeMount") },
  mounted () { this.$emit("mounted") },
  beforeDestroy () { this.$emit("beforeDestroy") },
  destroyed () { this.$emit("destroyed") }
}
</script>
