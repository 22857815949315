export default class Mutation {
  ctx
  params

  constructor (state, params) {
    this.state = state
    this.params = params
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handle (ctx, params) {
    //
  }

  run () {
    return this.handle(this.state, this.params)
  }
}
