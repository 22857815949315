import RepositoryTemplate from "@/providers/api/helpers/repositoryTemplate"
import SourceDriver from "@/providers/api/sourceDrivers/sourceDriver"
import StorageDriver from "@/providers/api/storageDrivers/storageDriver"

/**
 * The resource is responsible for bringing a storage driver and a
 * source driver together. You can mix and match drivers as you wish.
 * It's also responsible for providing a unified interface from where
 * you can use both source and storage methods.
 * This is the base resource class, based on a javascript object state
 * in the storage driver instance. This class is meant to be extended
 * for more cusom behaviour.
 *
 */
export default class Connector {
  // The connector default template
  template = new RepositoryTemplate()

  // Source and storage drivers (Instances created on register)
  sourceDriver = null
  storageDriver = null

  /**
   * ? Context explanation
   * The context object serves the same purpose as the vuex context.
   * All connectors, drivers and repositories work with the vuex module
   * tmeplate structure, meanint they use actions, mutations, getters and
   * a state. What this entails, is that we need to create a context object
   * so that we can pass is to actions (and parts of it to mutations and
   * getters). The context object stores the commit and dispatch handlers,
   * as well as proxys to the state and getters. You can read more about
   * the logic of the context object in the vuex documentation. If creating
   * your own, remember that it should work like the vuex implementation.
   * The ctxProxy object is a proxy to the context object, this is because
   * when passing the ctx to drivers we don't want to pass the reference to
   * a specific ctx object, because when the extendCtx method is called,
   * it creates a new ctx object and saves it to the this.ctx property.
   * By passing a proxy that points to the this.ctx property to the drivers,
   * we ensure that no matter how many times this.ctx changes, drivers will
   * always have acces to the latest ctx object.
   */
  ctx = {}
  ctxProxy = new Proxy(this.ctx, {
    get: function (target, prop) {
      return target[prop]
    }
  })

  /**
   * Creates or updates the context object
   */
  extendCtx () {
    this.ctx = Object.assign(this.ctx, {
      state: new Proxy(this.storageDriver.state, {
        get: function (target, prop) {
          return target[prop]
        },
        set: function () {
          return Reflect.set(...arguments)
        }
      }),
      getters: new Proxy(this.storageDriver.getters, {
        get: function (target, prop) {
          return target[prop]()
        }
      }),
      commit: (mutation, params) => {
        return this.storageDriver.mutations[mutation](params)
      },
      dispatch: (action, params) => {
        return this.sourceDriver.actions[action](params)
      }
    })
  }

  /**
   * ? Registration explanation
   * Connectors and therefor repositories can be registered with different
   * methods. This is used in the helperGlobals file, or when you call the
   * Vue.$api.register() method. When registering a repository this helper
   * will use the method defined in the registrationMethod property (the
   * same goes for Vue.$api.unregister() and the unregistrationMethod
   * property). The Vue.$api.register() method accepts a RId as the second
   * parameter (registration id). When a repository is registered, if the
   * RId is provided it will be added to the registrationIds array. When
   * the same repository is unregistered, it will be only unregistered if
   * the only RId left in the registrationIds array is the RId provided
   * to the Vue.$api.unregister() method, or if the registrationIDs array
   * is empty. This allows us to register the repository in multiple
   * places and unregister it in multiple places at the same time and the
   * only time the repository unregister method is called and the
   * repository is removed from the Vue.$api prototype only when the last
   * unregister method is called. The most common usecase of this is when
   * registering the same resource in two views, and unregistering it in
   * the destroy hook. Because of the way Vue garbage collection works
   * when changing routes from the first to the second view, the destroy
   * hook of the first view is actually called after the created hook of
   * the second view's created hook. If we didn't do this, the repo
   * would be registered in the first view, registered in the second
   * view, and then unregistered in the first view's destroy hook,
   * leaving the second view without a registered repository.
   */
  registrationMethod = "register"
  unregistrationMethod = "unregister"
  registrationIds = []
  get RIds () {
    return this.registrationIds
  }
  set RIds (registrationIds) {
    this.registrationIds = registrationIds
  }

  register (template, ctx) {
    this.sourceDriver = new SourceDriver()
    this.storageDriver = new StorageDriver()
    this.extendRepository(template, ctx)
  }

  unregister () {}

  /**
   * ? Extensions explanation
   * When adding class extensions the this.extensions property
   * is always used. When defining this.extensions in child classes,
   * the parent's definition is overwritten.
   * That's why if you add extensions in the extensions object you must also
   * call the this.loadClassExtensions() in the constructor to store
   * them in the loadedExtensions object, so they persist even when
   * the this.extensions object is overwriten in child classes.
   * This way, when the super() method is called in the constructor, the extensions
   * of each class will be moved to the loadedExtensions object which allows us to
   * overwrite the extensions property as many times as we want.
   * this.loadedExtenions should never be defined in child classes unless you
   * want to remove all parent extensions.
   */
  loadedExtensions = {}
  extensions = {}
  get sourceDriverLoadedExtensions () {
    if (!this.sourceDriver) return {}
    return this.sourceDriver.loadedExtensions
  }
  get storageDriverLoadedExtensions () {
    if (!this.storageDriver) return {}
    return this.storageDriver.loadedExtensions
  }
  get allLoadedExtensions () {
    return {
      ...this.sourceDriverLoadedExtensions,
      ...this.storageDriverLoadedExtensions,
      ...this.loadedExtensions
    }
  }

  /**
   * Moves the extensions from the extensions object to the loadedExtensions object
   */
  loadClassExtensions () {
    this.loadedExtensions = {
      ...this.loadedExtensions,
      ...this.extensions
    }
  }

  /**
   * Extends the class template with extension templates
   * then with the template repositories defined on the repository class
   * then with the template provided in the function arguments
   *
   * @param {object|RepositoryTemplate} template - the template we want to extend
   *                                               the class template with
   *
   * @return {RepositoryTemplate} the class template
   */
  extendTemplate (template = {}) {
    Object.values(this.allLoadedExtensions).forEach(Extension => this.template.extend(new Extension(this)))

    this.template.extend({
      plugins: this.plugins || [],
      state: this.state || {},
      getters: this.getters || {},
      actions: this.actions || {},
      mutations: this.mutations || {}
    })

    this.template.extend(template)

    return this.template
  }

  extendRepository (template, ctx) {
    this.extendTemplate(template)
    this.sourceDriver.register(this.template, this.ctxProxy)
    this.storageDriver.register(this.template, this.ctxProxy)
    this.extendCtx(ctx)
    this.sourceDriver.mapAllProperties(this)
    this.storageDriver.mapAllProperties(this)
  }
}
