import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/orders",
    name: "Orders",
    meta: { title: "Orders" },
    component: () => import(/* webpackChunkName: "Orders List" */ "@/Modules/Order/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/orders/details/:id",
    name: "Order Details",
    component: () => import(/* webpackChunkName: "Order Details" */ "@/Modules/Order/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "task/new",
        name: "New Order Task"
      },
      // Task
      {
        path: "task/details/:taskId",
        name: "Order Task"
      },
      {
        path: "user/new",
        name: "Order New User Details"
      },
      {
        path: "order-payment/details/new",
        name: "New Order Payment"
      },
      {
        path: "customer/new",
        name: "Order New Customer Details",
        children: [
          {
            path: "postal/new",
            name: "Order New Customer Details New Postal"
          }
        ]
      },
      {
        path: "order-payment/details/:paymentId",
        name: "Edit Order Payment"
      }
    ]
  },
  {
    path: "/orders/new",
    name: "New Order",
    component: () => import(/* webpackChunkName: "Order Details" */ "@/Modules/Order/Views/Details"),
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: "user/new",
        name: "Order New User"
      },
      {
        path: "customer/new",
        name: "Order New Customer",
        children: [
          {
            path: "postal/new",
            name: "Order New Customer New Postal"
          }
        ]
      }
    ]
  }
]
