export default {
  MissingPermission: {
    title: "Missing permission",
    subtitle: "Oops ...",
    content: "It seems you don't have the permission to access this module. Please contact your administrator.",
    to_home: "Home"
  },
  MissingLicence: {
    title: "Missing licence",
    subtitle: "Oops ...",
    content: "It seems you haven't been assigned a licence. Please contact your administrator.",
    to_home: "Home"
  }
}
