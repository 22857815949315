export default {
  layouts: {
    details: {
      title: "Details"
    },
    progress: {
      title: "Progress"
    },
    milestones: {
      title: "Milestones"
    }
  },
  views: {
    list: {
      header: {
        title: "Projects",
        search_placeholder: "Search ...",
        add_button: "Add project"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        copy: "Copy",

        selected_items: {
          selected: "Selected",
          items: "projects",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "project",

          // Duality
          selected2: "Selected",
          item2: "projects",

          // Plural
          selected3: "Selected",
          item3: "projects"
        },
        data_is_missing: "Table is empty"
      }
    },
    details: {
      header: {
        back_button: "Projects list",
        edit_button: "Edit project",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} project here"
        }
      },
      footer: {
        remove_button: "Delete project",
        add_button: "Add project",
        save_button: "Save project"
      },
      main: {
        title: "New Project",
        fields: {
          title: {
            label: "Title"
          },
          description: {
            label: "Description"
          },
          start_date: {
            label: "Start date"
          },
          due_date: {
            label: "Due date"
          },
          status: {
            label: "Status"
          },
          progress: {
            label: "Progress"
          },
          user: {
            label: "Responsible"
          }
        },
        user: {
          no_results_message: "This User does not exist",
          no_results_button: "Create new user"
        }
      },
      tasks: {
        back_button: "Back to project"
      },
      project_details: {
        title: "Progress",
        status: "Status"
      },
      milestones: {
        title: "Milestone",
        title_label: "Title",
        state: "State",
        text: "Item",
        add_new: "Add a milestone",
        edit: "Edit milestone"
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Project {title} created",
    updated: "Project {title} updated",
    deleted: "Project {title} was deleted",
    deleted_multiple: "Projects were deleted",
    not_found: "Unable to find specified project"
  }
}
