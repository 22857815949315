import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/administration/newsletters",
    name: "Newsletters",
    meta: { title: "Newsletters" },
    component: () => import(/* webpackChunkName: "Newsletters List" */ "@/Modules/Newsletter/Views/List/List"),
    children: [
      {
        path: "new",
        name: "New Newsletter",
        meta: { title: "New Newsletter" }
      }
    ],
    beforeEnter: ifAuthenticated
  },
  {
    path: "/administration/newsletters/details/:id",
    name: "Newsletter Details",
    component: () => import(/* webpackChunkName: "Newsletter Details" */ "@/Modules/Newsletter/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
