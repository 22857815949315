export default {
  layouts: {
    contacts: {
      title: "Kontakti"
    },
    tasks: {
      title: "Opravila"
    },
    newsletters: {
      title: "Glasila"
    }
  },
  views: {
    list: {
      header: {
        title: "Stranke",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj stranko"
      },
      table: {
        name: "Ime",
        code: "Koda",
        last_modified: "Zadnja sprememba",
        active: "Aktivno",
        copy: "Kopiraj",

        selected_items: {
          selected: "Izbrano",
          items: "stranke",
          remove: "Odstrani izbiro",
          delete: "Izbriši",

          // Singular
          selected1: "Izbrano",
          item1: "stranka",

          // Duality
          selected2: "Izbrano",
          item2: "stranki",

          // Plural
          selected3: "Izbrano",
          item3: "stranke"
        },
        data_is_missing: "Seznam je prazen"
      }
    },
    details: {
      sync_in_progress: "Stranka še ni v celoti prenešena iz Pantheona. Nekateri podatki še niso vidni in urejanje je onemogočeno.",
      header: {
        back_button: "Seznam strank",
        edit_button: "Uredi stranko",
        send_screenshare_link: "Pošlji povezavo za deljenje zaslona",
        screenshare: {
          title: "Povezava za deljenje zaslona je bila poslana",
          message: "Do klica lahko dostopate tukaj:",
          link: "Pojdi na klic",
          close: "Zapri"
        },
        toggle: {
          title: "Aktiven",
          activate: "aktivirajte",
          deactivate: "deaktivirajte",
          description: "Tukaj lahko {action} stranko"
        }
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        tabs: {
          basic: "Osnovno",
          postal: "Poštna številka",
          payment: "Plačilo",
          supplier: "Dobavitelj",
          contact: "Kontakt"
        },
        overview: {
          author: "Avtor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          last_modified: "Zadnja sprememba"
        },
        basic: {
          title: "Naslov",
          title_long: "Dolgi naslov",
          code: "Koda",
          vat_number: "DDV številka",
          registration_number: "Registracijska številka",
          is_company: "Stranka je podjetje",
          is_not_company: "Stranka ni podjetje",
          is_customer_company: "Je stranka podjetje?",
          is_supplier: "Ali je stranka dobavitelj?",
          is_enabled: "Je stranka omogočena?",
          is_buyer: "Je stranka kupec?",
          is_vat_payer: "Je stranka DDV plačnik",
          yes: "Ja",
          no: "Ne",
          responsible_person: "Odgovorna oseba"
        },
        payment: {
          rebate: "Rabat",
          rebate_max: "Rabat max",
          days_for_payment: "Dni za plačilo",
          currency: "Valuta",
          price_rate: "Cena",
          payment_method: "Način plačila",
          sale_method: "Način prodaje",
          limit: "Omejitev",
          delivery_type: "Vrsta dostave",
          buyer_type: "Tip kupca"
        },
        supplier: {
          supplier_rebate: "Dobaviteljev rabat",
          supplier_days_for_payment: "Dni za plačilo",
          supplier_purchase_method: "Način nakupa",
          supplier_delivery_type: "Vrsta dostave",
          supplier_payment_method: "Način plačila"
        }
      },
      contacts: {
        contacts: "Kontakti",
        users: "Uporabniki",
        title: "Naslov",
        name: "Ime",
        full_name: "Polno ime",
        email: "Email",
        phone: "Telefon",
        default: "Splošno",
        add_user_button: "Dodaj uporabnike",
        add_subject_button: "Dodaj kontakte",
        remove_button: "Odstrani",
        table_empty: "Seznam je prazen",
        tabs: {
          subjects: "Kontakti",
          users: "Povezani uporabniki"
        }
      },
      newsletters: {
        list: "Seznam list",
        add: "Dodaj v listo"
      },
      tasks: {
        tasks: "Opravila",
        add_new: "Dodaj opravilo",
        table: {
          "title": "Naslov",
          "status": "Status",
          "priority": "Prioriteta",
          "due_date": "Končni datum",
          data_is_missing: "Seznam je prazen"
        },
        back_button: "Nazaj na stranko"
      },
      relations: {
        title: "Povezani dokumenti",
        tabs: {
          orders: "Ponudbe",
          opportunities: "Priložnosti",
          invoices: "Račun",
          claims: "Reklamacija"
        },
        add_buttons: {
          orders: "Nova ponudba",
          invoices: "Nov račun",
          opportunities: "Nova priložnost",
          claims: "Nova reklamacija"
        },
        orders: {
          details: {
            back_button: "Nazaj na stranko"
          }
        },
        invoices: {
          details: {
            back_button: "Nazaj na stranko"
          }
        },
        claims: {
          details: {
            back_button: "Nazaj na stranko"
          }
        }
      },
      users: {
        title: "Uporabniki",
        add_button: "Ustvari novega uporabnika",
        data_is_missing: "Seznam je prazen"
      }
    },
    edit: {
      sync_in_progress: "Stranka še ni v celoti prenešena iz Pantheona. Nekateri podatki še niso vidni in urejanje je onemogočeno.",
      header: {
        details_back_button: "Nazaj na podrobnosti",
        list_back_button: "Seznam strank",
        title: "Nova stranka",
        save_button: "Shranite stranko",
        add_button: "Dodaj stranko",
        remove_button: "Izbriši"
      },
      main: {
        general: "Splošno",
        basic_info: "Osnovni podatki",
        title: "Naslov",
        title_long: "Dolgi naslov",
        name: "Ime",
        surname: "Priimek",
        code: "Koda",
        vat_number: "DDV številka",
        vat_tooltip_text: "S pritiskom na Enter po izpolnitvi polja DDV številka se izpolnijo podatki o kupcu preko zunanjega ponudnika Bisnode.",
        registration_number: "Registracijska številka",
        registration_tooltip_text: "S pritiskom na Enter po izpolnitvi polja registrske številke se avtomatsko izpolnijo podatki o stranki preko zunanjega ponudnika Bisnode.",

        responsible_person: "Odgovorna oseba",
        buyer_type: "Tip kupca",
        delivery_type: "Način dostave",
        payment_method: "Način plačila",
        sale_method: "Način prodaje",
        price_rate: "Cena",

        payment: "Plačilo",
        information: "Informacija",
        rebate: "Rabat",
        rebate_max: "Rabat max",
        limit: "Omejitev",
        days_for_payment: "Dni za plačilo",
        currency: "Valuta",
        ext_id: "Razširitev",
        ext_provider: "Ponudnik razširitve",

        is_vat_payer: "Je DDV plačnik",
        is_company: "Je podjetje",
        is_buyer: "Je kupec",
        is_supplier: "Je dobavitelj",
        is_enabled: "Je omogočena",

        supplier: "Dobavitelj",
        supplier_rebate: "Rabat",
        supplier_days_for_payment: "Dni za plačilo",
        supplier_purchase_method: "Način nakupa",
        address_contacts: "Naslov in kontakt"
      }
    }
  },
  popup_users: {
    title: "naslov",
    email: "Email",
    phone: "Telefon",
    search_placeholder: "Iščite uporabnike...",
    number_of_selected_users: "Število izbranih uporabnikov: ",
    remove_button: "Odstrani izbrane",
    add_button: "Dodaj uporabnika",
    update_button: "Posodobi uporabnika"
  },
  popup_subjects: {
    name_title: "Ime naslov",
    title: "Dodajte nov kontakt",
    full_name: "Polno ime",
    email: "Email",
    phone: "Telefon",
    is_default: "Nastavi kot privzeto",
    update_button: "Posodobi",
    add_button: "Dodaj kontakt"
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Stranka {title} ustvarjena",
    updated: "Stranka {title} posodobljena",
    deleted: "Stranka {title} je bila izbrisana",
    deleted_multiple: "Stranke so bile izbrisane",
    not_found: "Navedene stranke ni mogoče najti"
  },
  contacts: {
    prompts: {
      delete: {
        title: "Izbriši {full_name}",
        message: "Izbrisali boste {full_name}. Ali želite nadaljevati s tem dejanjem?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    },
    notify: {
      stored: "Kontakt {full_name} ustvarjen",
      updated: "Kontakt {full_name} posodobljen",
      deleted: "Kontakt {full_name} je bil izbrisan",
      not_found: "Določenega stika ni mogoče najti"
    }
  }
}
