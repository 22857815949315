export default {
  views: {
    list: {
      header: {
        title: "Notification Templates",
        search_placeholder: "Search ...",
        add_button: "Add notification template"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        data_is_missing: "Table is empty"
      }
    },
    details: {
      header: {
        back_button: "Notification Templates list",
        edit_button: "Edit notification template",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} notification template here"
        }
      },
      main: {
        title: "Main Details",
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        },
        basic: {
          headline: "Information",
          title: "Title",
          type: "Type",
          subject: "Subject"
        }
      }
    },
    edit: {
      header: {
        details_back_button: "Back to details",
        list_back_button: "Notification Templates list",
        title: "New Notification Template",
        save_button: "Save notification template",
        add_button: "Add notification template",
        remove_button: "Delete"
      },
      menu: {
        title: "Title",
        customer: "Email subject"
      },
      footer: {
        save_button: "Save notification template"
      }
    }
  },
  popup: {
    title: "New Notification Template",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add notification template",
    update_button: "Update notification template"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Notification template {title} created",
    updated: "Notification template {title} updated",
    deleted: "Notification template {title} was deleted",
    deleted_multiple: "Notification templates were deleted",
    not_found: "Unable to find specified notification template"
  }
}
