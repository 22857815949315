import Extension from "@/providers/api/helpers/extension"

import ListWithEditRowGetter from "./getters/listWithEditRow"

import SetEditMutation from "./mutations/setEdit"

import SetEditAction from "./actions/setEdit"
import ClearEditAction from "./actions/clearEdit"
import StoreEditItemAction from "./actions/storeEditItem"

export default class TableEditExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      state: {
        /**
         * The id of the item being edited in edit row,
         * if null no item is being edited, if 0 new item is being created
         */
        editId: null,
        /**
         * The data for the edit row being edited/created
         */
        editRow: {},
        /**
         * The template of a new row (also used when extracting edit params)
         */
        editNewRow: {}
      },
      getters: {
        /**
         * Returns list with the edit row data injected
         * used in UIK table's v-for
         */
        ListWithEditRow (state) {
          return new ListWithEditRowGetter(state).run()
        }
      },
      mutations: {
        /**
         * Sets the row being edited
         */
        SET_EDIT (state, param) {
          return new SetEditMutation(state, param).run()
        }
      },
      actions: {
        /**
         * Sets the edit param
         */
        setEdit (ctx, param) {
          return new SetEditAction(ctx, param).run()
        },
        /**
         * Clears the edit param
         */
        clearEdit (ctx) {
          return new ClearEditAction(ctx).run()
        },
        /**
         * Stores the edit item
         */
        async storeEditItem (ctx) {
          return new StoreEditItemAction(ctx).run()
        }
      }
    })
  }
}
