export default {
  MissingPermission: {
    title: "Manjka dovoljenje",
    subtitle: "Oops ...",
    content: "Videti je, da nimate dovoljenja za dostop do tega modula. Obrnite se na skrbnika",
    to_home: "Domov"
  },
  MissingLicence: {
    title: "Manjka dovoljenje",
    subtitle: "Oops ...",
    content: "Videti je, da vam ni bila dodeljena licenca. Obrnite se na skrbnika.",
    to_home: "Domov"
  }
}
