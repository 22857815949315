export default [
  {
    path: "/missing-permission",
    name: "MissingPermission",
    meta: { title: "Missing permission" },
    component: () => import(/* webpackChunkName: "Missing permission" */ "@/Modules/PermissionRedirects/Views/MissingPermission.vue")
  },
  {
    path: "/missing-licence",
    name: "MissingLicence",
    meta: { title: "Missing licence" },
    component: () => import(/* webpackChunkName: "Missing licence" */ "@/Modules/PermissionRedirects/Views/MissingLicence.vue")
  }
]
