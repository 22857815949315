export default {
  layouts: {
    mailbox: {
      title: "Mailbox"
    }
  },
  views: {
    list: {
      header: {
        title: "Mailbox",
        search_placeholder: "Search ..."
      },
      table: {
        subject: "Customer",
        sender: "Sender",
        created_at: "Created at",
        updated_at: "Updated at",
        last_modified: "Last modified",
        data_is_missing: "Table is empty"
      }
    },
    details: {
      header: {
        back_button: "Mailbox list"
      },
      main: {
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        },
        contents: {
          title: "Contents"
        }
      }
    }
  },
  components: {
    MailboxWidget: {
      mailbox_list: "Related emails",
      sync: "Synchronize"
    }
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Mailbox {title} created",
    updated: "Mailbox {title} updated",
    deleted: "Mailbox {title} was deleted",
    deleted_multiple: "Mailboxes were deleted",
    not_found: "Unable to find specified mailbox"
  }
}
