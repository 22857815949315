export default {
  views: {
    list: {
      title: "Šifrant",
      key: "Ključ",
      data: "Podatek",
      additional_fields_schema: "Shema dodatnih polj",
      color: "Barva",
      icon: "Ikona",
      updated_at: "Posodobljeno",
      created_at: "Ustvarjeno",
      save: "Shrani",
      remove: "Odstrani",

      remove_category_msg: "Šifrant {title} je bil uspešno odstranjen.",
      remove_msg: "Šifrant je bil uspešno odstranjen.",
      remove_error: "Med odstranjevanjem elementa je prišlo do napake.",
      updated_msg: "Element šifranta je bil uspešno posodobljen.",
      update_error: "Med posodabljanjem elementa je prišlo do napake.",
      store_error: "Med shranjevanjem elementa je prišlo do napake."
    },
    header: {
      add: "Dodaj"
    },
    tree: {
      title: "Šifrant",
      add: "Add",
      category_add: "Dodaj novo kategorijo",
      empty: "Tukaj ni podatkov. Dodajte nov šifrant."
    },
    category_popup: {
      edit: "Uredi šifrant",
      new: "Dodaj nov šifrant",
      title: "Naslov",
      schema_active: "Shema je aktivna",
      schema_not_active: "Shema ni aktivna",
      schema_desc: "Ko je aktiviran, lahko napišete svojo shemo.",
      schema_title: "Shema",

      schema_error: "Shema je neveljavna. Poskusite napisati novo shemo.",
      saved: "Šifrant je bil uspešno shranjen.",
      updated: "Šifrant je bil uspešno posodobljen.",

      error_update: "Pri posodabljanju je prišlo do napake.",
      error_store: "Med shranjevanjem je prišlo do napake.",

      remove: "Odstrani",
      cancel: "Prekliči",
      save: "Shrani",
      add: "Dodaj",

      prompt: {
        title: "Odstrani šifrant",
        remove_msg: "Ali ste prepričani, da želite izbrisati",
        remove: "Odstrani",
        cancel: "Prekliči"
      }
    },
    add_postal_popup: {
      title: "Dodaj pošto",
      fields: {
        name: "Mesto",
        code: "Številka",
        region: "Regija"
      },
      store_error: "Pri shranjevanju je prišlo do napake."
    }
  }
}
