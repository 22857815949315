import Vue from "vue"
import Mutation from "@/providers/api/mutations/mutation"

export default class SetFilterMutation extends Mutation {
  /**
   * Writes filter options to the state
   *
   * @param {object} state - the module state
   * @param {string} module - The namespace of the module this filter is for
   * @param {string} key - The filter namespace
   * @param {object} params - Filter options to set
   */
  handle (state, { module = null, key = null, params = null }) {
    if (module === null || key === null || params === null) return
    if (!(typeof params === "object" || Array.isArray(params))) return

    if (!state.filters[module]) Vue.set(state.filters, module, {})

    Vue.set(state.filters[module], key, JSON.parse(JSON.stringify(params)))

    let sessionstorageFilters = JSON.parse(window.sessionStorage.getItem("filters")) || {}
    sessionstorageFilters[state.namespace] = state.filters
    window.sessionStorage.setItem("filters", JSON.stringify(sessionstorageFilters))
  }
}
