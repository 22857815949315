import Vue from "vue"
import Mutation from "@/providers/api/mutations/mutation"

export default class SetListMutation extends Mutation {
  /**
   * Writes provided list of items to the state and
   * sets the listLoaded flag to true
   *
   * @param {object} state - the module state
   * @param {object[]} param - List of items
   */
  async handle (state, param) {
    Vue.set(state, "list", param)
    state.listLoaded = true
  }
}
