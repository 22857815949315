export default {
  layouts: {
    notifications: {
      title: "Notifications"
    }
  },
  views: {
    list: {
      header: {
        title: "Notifications",
        search_placeholder: "Search ...",
        add_button: "Add notification"
      },
      table: {
        preview: "Preview",
        type: "Type",
        recipient: "Recipient",
        created_at: "Created at",
        updated_at: "Updated at",
        data_is_missing: "Table is empty"
      }
    },
    details: {
      header: {
        back_button: "Notifications list"
      },
      main: {
        recipient: "Recipient"
      }
    }
  },
  components: {
    NotificationWidget: {
      notifications: "Notifications",
      send_notification: "Send notification",
      popup: {
        title: "Send new notification",
        template: "Notification template",
        cancel_button: "Cancel",
        confirm_button: "Send",
        error_message: "In order for this feature to work, the selected customer has to have an email or phone number."
      }
    }
  },
  notify: {
    stored: "Notification {title} created",
    updated: "Notification {title} updated",
    deleted: "Notification {title} was deleted",
    deleted_multiple: "Notifications were deleted",
    not_found: "Unable to find specified notification"
  }
}
