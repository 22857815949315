export default {
  methods: {
    getProperty (property, data) {
      let value = null

      if (property && data) {
        const sanitize = ["(", ")", "="]
        for (let i = 0; i < sanitize.length; i++) {
          property = property.replace(sanitize[i], "")
        }

        try {
          if (property[0] === "[") {
            // eslint-disable-next-line
            value = eval(`data${property}`)
          } else {
            // eslint-disable-next-line
            value = eval(`data.${property}`)
          }
        } catch {}
      }

      return value
    }
  }
}
