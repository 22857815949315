import Action from "@/providers/api/actions/action"

import router from "@/providers/router"
import Filter from "../helpers/Filter"

export default class SetFilterAction extends Action {
  /**
   * Sets the filter param
   *
   * @param {string} params - the filter params to set
   */
  handle ({ state, commit }, params) {
    if (params instanceof Filter) params = params.generateFilterObject()

    if (!params.module) params.module = router.currentRoute.name

    if (state.filters[module] && !params.key) {
      let i = 1
      params.key = "defaultFilterKey" + String(i)
      while (Object.hasOwnProperty(state.filters[module], params.key)) {
        i++
      }
    } else if (!params.key) {
      params.key = "defaultFilterKey1"
    }

    commit("SET_FILTER", params)

    return { module: params.module, key: params.key }
  }
}
