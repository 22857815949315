import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"
export default [
  {
    path: "/settings",
    name: "settings",
    meta: { title: "Settings" },
    component: () => import(/* webpackChunkName: "Settings" */ "@/Modules/Auth/Settings/Views/Details"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/settings/app-settings",
    name: "App User Settings",
    meta: { title: "Application User Settings" },
    component: () => import(/* webpackChunkName: "User App Settings" */ "@/Modules/Administration/Views/AppUserSettings/Settings"),
    beforeEnter: ifAuthenticated
  }
]
