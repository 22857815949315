import RequestAction from "@/providers/api/sourceDrivers/webApiDriver/actionTypes/requestAction"

export default class StoreAction extends RequestAction {
  /**
   * Creates a new item record from provided params
   *
   * @param {object} params - item info to be stored
   *
   * @returns {object} Response status and data
   */
  async handle ({ state }, params) {
    let res = await this.request({ namespace: "store" }).post(`${state.route}`, params)
    return res
  }
}
