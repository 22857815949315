export default {
  views: {
    list: {
      header: {
        title: "Zemljevidi",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj zemljevid"
      },
      table: {
        title: "Naslov",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnja sprememba",
        active: "Aktiven"
      }
    },
    details: {
      header: {
        back_button: "Seznam zemljevida",
        edit_button: "Uredi zemljevid",
        toggle: {
          title: "Aktiven",
          activate: "aktiviraj",
          deactivate: "deaktiviraj",
          description: "Tu lahko {action} zemljevid"
        }
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        overview: {
          author: "Avtor",
          created_at: "Ustvarjen",
          updated_at: "Posodobljen",
          last_modified: "Zadnja sprememba"
        }
      }
    }
  },
  popup: {
    title: "Nov zemljevid",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj zemljevid",
    update_button: "Posodobi zemljevid"
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Zemljevid {title} ustvarjen",
    updated: "Zemljevid {title} posodobljen",
    deleted: "Zemljevid {title} je bil izbrisan",
    deleted_multiple: "Zemljevidi so bili izbrisani",
    not_found: "Določenega zemljevida ni mogoče najti"
  }
}
