import Action from "@/providers/api/actions/action"

export default class ClearListAction extends Action {
  /**
   * Commits the mutation that clears the list
   */
  async handle ({ commit }) {
    commit("CLEAR_LIST")
  }
}
