export default {
  layouts: {
    upload: {
      title: "Media"
    }
  },
  views: {
    list: {
      header: {
        title: "Media",
        search_placeholder: "Search ...",
        add_button: "Add media"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active"
      }
    },
    details: {
      header: {
        back_button: "Media list",
        edit_button: "Edit media",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} media here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    }
  },
  popup: {
    title: "New Media",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add media",
    update_button: "Update media"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Media {title} created",
    updated: "Media {title} updated",
    deleted: "Media {title} was deleted",
    deleted_multiple: "Media was deleted",
    not_found: "Unable to find specified media"
  },
  widget: {
    title: "Files",
    upload: "Upload",
    generate: "Generate from template",
    table: {
      title: "Title",
      collection: "Collection",
      data_is_missing: "Table is empty"
    }
  },
  preview: {
    loading: "Your document is being prepared",
    close: "Close",
    delete: "Delete document",
    discard: "Discard document",
    downloadDrawing: "Download with drawing",
    saveDrawing: "Save copy with drawing",
    sign: {
      signature: "Document signature"
    },
    edit: {
      title: "Edit document",
      description: "Document editing with an external tool. The document will be automatically updated on save."
    },
    save: {
      title: "Save in the application",
      description: "Save the created file in documents and it will be available in the application."
    },
    download: {
      title: "Document download",
      description: "Download the document to your device.",
      downloadPdf: "Download the preview (PDF)",
      downloadWork: "Download the document (Word)"
    },
    send: {
      title: "Send file",
      description: "File that is preview right now will be sent to email of current selected customer."
    },
    prompts: {
      discard: {
        title: "Discard document",
        message: "Are you sure you want to discard this document?",
        confirmBtn: "Discard",
        cancelBtn: "Cancel"
      },
      close: {
        title: "The document is not saved",
        message: "The document has not yet been saved in the application. If you leave this view, it will be discarded.",
        confirmBtn: "Discard document",
        cancelBtn: "Cancel"
      }
    }
  }
}
