import Vue from "vue"
import Vuex from "vuex"
import space from "./store/layout"
import Auth from "@/Modules/Auth/Auth/Resources/Auth"

Vue.use(Vuex)

let getApiUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return (!process.env.VUE_APP_NO_LOCATION_API_URL ? location.origin + "/" : "") + process.env.VUE_APP_API
  }
  return process.env.VUE_APP_API
}

export default new Vuex.Store({
  state: {
    apiUrl: getApiUrl(),
    init: null,
    tenantExists: true
  },
  actions: {
    async init ({ state }) {
      if (state.init !== null) return
      state.init = window.axios.get("/auth/user")
      state.init.catch(async () => {
        const subdomain = window.location.host.split(".")[0]
        this.tenantExists = window.axios.post("/licensing/tenants/exists", { url_subdomain: subdomain })
      })
    }
  },
  modules: {
    space,
    Auth
  }
})
