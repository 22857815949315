import Extension from "@/providers/api/helpers/extension"

import ClearListMutation from "./mutations/clearList"
import SetListMutation from "./mutations/setList"
import SetPaginationMutation from "./mutations/setPagination"
import SetListSortMutation from "./mutations/setListSort"

import GetListAction from "./actions/getList"
import ExportListAction from "./actions/exportList"
import ClearListAction from "./actions/clearList"
import SetListSortAction from "./actions/setListSort"
import ClearListSortAction from "./actions/clearListSort"

export default class LoadersExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      state: {
        /**
         * Stores an array of resource item objects
         */
        list: [],
        /**
         * Is the list already loaded?
         */
        listLoaded: false,
        /**
         * The default parameters to be sent with getList
         */
        listDefaultParams: {},
        /**
         * The sort parameter to be sent with getList
         */
        listSortParam: null,
        /**
          * Stores list pagination settings
          */
        pagination: {}
      },
      mutations: {
        /**
         * Writes provided list of items to the state
         */
        CLEAR_LIST (state) {
          return new ClearListMutation(state).run()
        },
        /**
         * Writes provided list of items to the state
         */
        SET_LIST (state, param) {
          return new SetListMutation(state, param).run()
        },
        /**
         * Writes current pagination params to the state
         */
        SET_PAGINATION (state, params) {
          return new SetPaginationMutation(state, params).run()
        },
        /**
         * Sets the list sort param
         */
        SET_LIST_SORT (state, param) {
          return new SetListSortMutation(state, param).run()
        }
      },
      actions: {
        /**
         * Retrieves list of items from api
         */
        getList: (ctx, params) => {
          return new GetListAction(ctx, params, repository.api).run()
        },
        /**
         * Exports the list of items from api
         */
        exportList: (ctx, params) => {
          return new ExportListAction(ctx, params, repository.api).run()
        },
        /**
         * Commits the mutation that clears the list
         */
        async clearList (ctx, params) {
          return new ClearListAction(ctx, params).run()
        },
        /**
         * Sets the sort param
         *
         * @param {string} param - the sort param to set
         */
        setListSort (ctx, param) {
          return new SetListSortAction(ctx, param).run()
        },
        /**
         * Clears the sort param
         */
        clearListSort (ctx, params) {
          return new ClearListSortAction(ctx, params).run()
        }
      }
    })
  }
}
