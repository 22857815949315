export default {
  views: {
    list: {
      header: {
        title: "Izdelki",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj izdelek"
      },
      table: {
        name: "Ime",
        code: "Koda",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        author: "Avtor",
        last_modified: "Zadnja sprememba",
        active: "Aktiven",
        copy: "Kopiraj",

        selected_items: {
          selected: "Izbrano",
          idents: "izdelki",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Izbrano",
          item1: "izdelek",

          // Duality
          selected2: "Izbrano",
          item2: "izelka",

          // Plural
          selected3: "Izbrano",
          item3: "izdelki"
        },
        data_is_missing: "Tabela je prazna"
      }
    },
    edit: {
      sync_in_progress: "Izdelek še ni v celoti prenešen iz Pantheona. Nekateri podatki še niso vidni in urejanje je onemogočeno.",
      is_enabled: "Je omogočeno",
      header: {
        list_back_button: "Lista izdelkov",
        title: "Nov izdelek",
        save_button: "Shrani izdelek",
        add_button: "Dodaj izdelek",
        remove_button: "Izbriši"
      },
      tabs: {
        properties: "Lastnosti",
        tehnical_info: "Tehnične informacije",
        payment: "Plačila",
        "creation-info": "Podatki o kreiranju"
      },
      footer: {
        save_button: "Shrani izdelek"
      },
      properties: {
        code: "Koda",
        name: "Ime",
        stock: "Zaloga",
        author: "Avtor"
      },
      tehnical_info: {
        weight_netto: "Neto teža",
        weight_brutto: "Bruto teža",
        weight_unit: "Enota teže",
        depth: "Globina",
        height: "Višina",
        width: "Širina",
        dimension_unit: "Enota mere"
      },
      payment: {
        buy_price: "Nakupna cena s stroški",
        transport_cost: "Stroški prevoza",
        cost: "Ostali stroški",
        supplier_price: "Cena dobavitelja",
        supplier_price_netto: "Cena dobavitelja (neto)",
        supplier_rebate: "Dobaviteljev rabat",
        wholesale_price: "VP1",
        wholesale_price2: "VP2",
        retail_price: "Veleprodajna cena",
        sale_price: "Akcijska cena",
        vat_rate: "stopnja DDV",
        vat: "DDV",
        unit: "Enote",
        classif: "Classif",
        set_of_item: "Set of item",
        currency: "Valuta",
        discount: "Popust"
      }
    }
  },
  popup: {
    title: "Nov izdelek",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj izdelek",
    update_button: "Posodobi izdelek"
  },
  prompts: {
    delete: {
      title: "Izbriši {name}",
      message: "Izbrisali boste {name}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    },
    delete_multiple: {
      title: "Izbriši izbrano",
      message: "Izbrisali boste več elementov. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Izdelek {name} ustvarjen",
    updated: "Izdelek {name} posodobljen",
    deleted: "Izdelek {name} je bil izbrisan",
    deleted_multiple: "Izdelki so bili izbrisani",
    not_found: "Določenega izdelka ni mogoče najti",
    "The given data was invalid.": "The code has already been taken. Please choose different code."
  },
  components: {
    items: {
      title: "Izdelki, storitve",
      columns: {
        title: "Naslov",
        value: "Vrednost",
        quantity: "Količina",
        discount: "Popust",
        rebate: "Rabat",
        unit: "Enota",
        vat: "Stopnja DDV",
        total: "Skupaj"
      },
      select_item: "Izberite element ...",
      total_sum: "Skupna vsota",
      final_date: "Končni datum: {date}",
      set_discounts_button: "Rabati",
      discount_popup: {
        title: "Nastavite rabat za vse artikle",
        rebate: "Rabat",
        confirm_button: "Nastavite rabate"
      },
      summary: {
        sum: "Skupna vsota",
        vat_base: "Osnova za DDV",
        vat_amount: "Znesek DDV",
        discounts: "Skupni popusti",
        rebates: "Skupni rabati"
      }
    },
    costs: {
      title: "Stroški",
      columns: {
        title: "Naslov",
        value: "Vrednost",
        quantity: "Količina",
        unit: "Enota",
        note: "Opomba",
        total: "Skupaj"
      },
      select_item: "Izberite element ...",
      total_sum: "Skupna vsota",
      final_date: "Končni datum: {date}",
      summary: {
        sum: "Skupna vsota"
      }
    }
  }
}
