export default class Action {
  ctx
  params

  constructor (ctx, params) {
    this.ctx = ctx
    this.params = params
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handle (ctx, params) {
    //
  }

  run () {
    return this.handle(this.ctx, this.params)
  }
}
