export default [
  {
    path: "/confirm-sms/:token",
    name: "InfobipConfirm",
    meta: { title: "Infobip" },
    component: () => import(/* webpackChunkName: "Infobip Confirm" */ "@/Modules/Infobip/Views/Confirm/Confirm")
  },
  {
    path: "/confirm-sms-success",
    name: "ConfirmSuccess",
    meta: { title: "Infobip" },
    component: () => import(/* webpackChunkName: "Infobip Confirm Success" */ "@/Modules/Infobip/Views/Confirm/ConfirmSuccess")
  },
  {
    path: "/confirm-sms-error",
    name: "ConfirmError",
    meta: { title: "Infobip" },
    component: () => import(/* webpackChunkName: "Infobip Confirm Error" */ "@/Modules/Infobip/Views/Confirm/ConfirmError")
  }
]
