export default {
  layouts: {
    details: {
      title: "Glavne podrobnosti"
    }
  },
  views: {
    list: {
      header: {
        title: "Sestave",
        search_placeholder: "Iskanje ...",
        add_button: "Dodajte sestavo"
      },
      table: {
        number: "Številka",
        number_view: "Prikaz številke",
        subject: "Stranka",
        assembly_date: "Datum sestave",
        status: "Status",
        options: "Možnosti",
        data_is_missing: "Tabela je prazna",
        selected_items: {
          selected: "Izbrano",
          items: "montaža",
          remove: "Odstrani izbiro",

          // Singular
          selected1: "Izbrano",
          item1: "sestav",

          // Duality
          selected2: "Izbrano",
          item2: "sestavi",

          // Plural
          selected3: "Izbrano",
          item3: "sestavi"
        }
      }
    },
    details: {
      header: {
        back_button: "Lista sestava",
        finalize_button: "Zaključi sestavo",
        send_to_customer: "Pošlji stranki"
      },
      footer: {
        remove_button: "Odstarni sestavo",
        save_button: "Shrani sestavo"
      },
      main: {
        title: "Nova sestava",
        number: "Številka",
        number_view: "Prikaz številke",
        status: "Status",
        assembly_date: "Datum sestave",
        note: "Opomba",
        finalized_comment: "Končni komentar"
      }
    }
  },
  status: {
    draft: "Osnutek",
    "in-progress": "V obdelavi",
    completed: "Dokončano"
  },
  popup: {
    title: "Zaključek sestave",
    fields: {
      status: "Status",
      finalized_comment: "Končni komentar"
    },
    finalize_button: "Zaključek sestave"
  },
  send_popup: {
    title: "Pošlji obvestilo",
    template: "Predloga obvestila",
    cancel_button: "Prekliči",
    confirm_button: "Naslednji",
    error_message: "Da bi ta funkcija delovala, morate izbrani stranki dodati e-pošto."
  },
  prompts: {
    delete: {
      title: "Izbriši {number}",
      message: "Izbrisali boste {number}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Sestava {number} izdelana",
    updated: "Sestava {number} posodobljena",
    deleted: "Sestava {number} je bila izbrisana",
    deleted_multiple: "Sestave so bile izbrisane",
    not_found: "Ni mogoče najti navedene sestave"
  }
}
