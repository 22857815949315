import LaravelRepository from "@/providers/api/repositories/LaravelRepository"

export default class GlobalSearch extends LaravelRepository {
  route = "search"
  namespace = "search"
  identifier = "uid"

  state = {

  }

  actions = {
    search: async (ctx, search) => {
      let response = await this.request({ namespace: "search" }).post("/search", { search })
      // if (!response.isError) commit("SET_FIELDS", response.data)
      return response
    }
  }
}
