export default {
  views: {
    list: {
      header: {
        title: "Pravila poštnega nabiralnika",
        search_placeholder: "Iskanje ...",
        add_button: "Dodaj pravilo za nabiralnik"
      },
      table: {
        title: "Naslov",
        description: "Opis",
        entity: "Entiteta",
        rule: "Pravilo",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        data_is_missing: "Tabela je prazna"
      },
      entity: {
        "App\\Modules\\Subject\\Subject": "Stranka",
        "App\\Modules\\Opportunity\\Opportunity": "Priložnost"
      },
      rules: {
        "connect-sender": "Ujemanje s pošiljateljem e-pošte",
        "connect-keyword": "Povežite e-pošto s {keyword} <{identifier}> v stranki",
        "create-keyword": "Ustvarite entiteto, ko prejmete pošto s {keyword} v stranki"
      }
    },
    details: {
      header: {
        back_button: "Seznam pravil za nabiralnik",
        edit_button: "Urejanje pravila za nabiralnik"
      },
      main: {
        overview: {
          created_at: "Ustvarjeno",
          updated_at: "Posodobljeno",
          entity: "Entiteta",
          rule: "Pravilo",
          entities: {
            "App\\Modules\\Subject\\Subject": "Stranka",
            "App\\Modules\\Opportunity\\Opportunity": "Priložnost"
          },
          rules: {
            "connect-sender": "Ujemanje s pošiljateljem e-pošte",
            "connect-keyword": "Povežite e-pošto s {keyword} <{identifier}> v stranki",
            "create-keyword": "Ustvarite entiteto, ko prejmete pošto s {keyword} v stranki"
          }
        }
      }
    }
  },
  popup: {
    title: "Novo pravilo za nabiralnik",
    rules: {
      "connect-sender": "Ujemanje s pošiljateljem e-pošte",
      "connect-keyword": "Ujemanje s ključno besedo in identifikatorjem",
      "create-keyword": "Ustvarite entiteto, ko prejmete pošto s ključno besedo v stranki"
    },
    entities: {
      subject: "Stranka",
      opportunity: "Priložnost"
    },
    fields: {
      title: {
        label: "Naslov"
      },
      description: {
        label: "Opis"
      },
      entity: {
        label: "Entiteta"
      },
      rule: {
        type: {
          label: "Vrsta"
        },
        keyword: {
          label: "Ključna beseda"
        },
        id_name: {
          label: "Podatkovno polje"
        }
      }
    },
    add_button: "Dodaj pravilo za nabiralnik",
    update_button: "Posodobi pravilo nabiralnika"
  },
  prompts: {
    delete: {
      title: "Izbriši {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Pravilo nabiralnika {title} ustvarjeno",
    updated: "Pravilo nabiralnika {title} posodobljeno",
    deleted: "Pravilo nabiralnika {title} je bilo izbrisano",
    deleted_multiple: "Pravila nabiralnika so bila izbrisana",
    not_found: "Ni mogoče najti določenega pravila za nabiralnik"
  }
}
