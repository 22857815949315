<template>
    <router-link
        :title="t(`views.${link.route}`)"
        :to="`/${link.route}`"
    >
        <div>
            <UikIcon :icon="[iconStyle(`/${link.route}`), link.icon]"/>
            <span>{{ t(`views.${link.route}`) }}</span>
        </div>

        <div :class="[{'navigation__scroll-wrapper-task-indicators': collapsed}]">
            <div
                v-if="link.route === 'tasks' && taskIndicators.opened"
                class="navigation__task-indicator-open"
            >
                {{  taskIndicators.opened }}
            </div>
            <div
                v-if="link.route === 'tasks' && taskIndicators.expired"
                class="navigation__task-indicator-expired"
            >
                {{ taskIndicators.expired }}
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
  langKey: "Global.menu",
  props: {
    link: { type: Object, default: () => { return {} } },
    collapsed: { type: Boolean, default: false },
    taskIndicators: { type: Object, default: () => { return {} } }
  },
  methods: {
    iconStyle (path, exact) {
      let style = "far"
      if (
        (exact && this.$route.path === path) ||
        (!exact && this.$route.path.startsWith(path))
      ) {
        style = "fas"
      }
      return style
    }
  }
}
</script>
