<template>
    <div class="top-bar">
        <span>{{ message }}</span>
        <button class="close-button" @click="$emit('close')">
            <UikIcon
                :icon="['fas', 'times']"
            />
        </button>
    </div>
</template>

<script>
export default {
  props: {
    message: { type: String, default: "" }
  }
}
</script>

<style lang="scss">
.top-bar {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  background: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0 20px;

  .close-button {
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}
</style>
