export default {
  views: {
    list: {
      header: {
        title: "Kpis",
        search_placeholder: "Search ...",
        add_button: "Add kpi"
      },
      table: {
        title: "Title",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        data_is_missing: "Table is empty"
      }
    },
    details: {
      current_value_table: {
        current_value: "Current value",
        table: {
          kpi_value: "Kpi value"
        }
      },
      header: {
        back_button: "Kpis list",
        edit_button: "Edit kpi",
        show_comparison: "Show comparison",
        show_or_hide_comparison: "Show or hide comparison charts",
        toggle: {
          title: "Active",
          activate: "activate",
          deactivate: "deactivate",
          description: "You can {action} kpi here"
        }
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      },
      historic_data_comparison: {
        kpi_line_chart: "Kpi Line Chart"
      },
      historic_data_table: {
        title: "Historic values",
        table: {
          kpi_value: "Kpi value",
          created_at: "Created at",
          updated_at: "Updated at"
        }
      }
    }
  },
  popup: {
    title: "New Kpi",
    fields: {
      title: {
        label: "Title"
      },
      store_historic_data: {
        label: "Store historic data"
      },
      polling_period: {
        label: "Polling period"
      },
      formula: {
        label: "Formula"
      }
    },
    add_button: "Add kpi",
    update_button: "Update kpi",
    components: {
      variable_form: {
        fields: {
          store_history: {
            label: "Store history"
          },
          title: {
            label: "Title"
          },
          key: {
            label: "Key"
          },
          description: {
            label: "Description"
          },
          model: {
            label: "Model"
          },
          formula: {
            label: "Formula"
          }
        }
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Kpi {title} created",
    updated: "Kpi {title} updated",
    deleted: "Kpi {title} was deleted",
    deleted_multiple: "Kpis were deleted",
    not_found: "Unable to find specified kpi"
  }
}
