import Vue from "vue"

import { mapGetters, mapActions } from "vuex"

export default {
  created () {
    Vue.prototype.$setSpace = this.setSpace
    Vue.prototype.$removeSpace = this.removeSpace

    Vue.prototype.$getUi = this.getUi
    Vue.prototype.$setUi = this.setUi
    Vue.prototype.$removeUi = this.removeUi
  },
  methods: {
    ...mapActions({
      setSpace: "space/setSpace",
      removeSpace: "space/removeSpace",
      setUi: "space/setUi",
      removeUi: "space/removeUi"
    }),
    ...mapGetters({ getUi: "space/getUi" })
  }
}
