export default {
  layouts: {
    details: {
      title: "Details"
    },
    customer: {
      title: "Customer"
    }
  },
  actions: {
    claim: "Create reclamation",
    cancellation: "Cancellation",
    preview: "Document preview"
  },
  views: {
    list: {
      header: {
        title: "Invoices",
        search_placeholder: "Search ...",
        add_button: "Add invoice"
      },
      table: {
        number: "Number",
        number_view: "Number view",
        created_at: "Created at",
        updated_at: "Updated at",
        author: "Author",
        last_modified: "Last modified",
        active: "Active",
        copy: "Copy",
        selected_items: {
          selected: "Selected",
          items: "invoices",
          remove: "Remove selection",
          delete: "Delete",

          // Singular
          selected1: "Selected",
          item1: "invoice",

          // Duality
          selected2: "Selected",
          item2: "invoices",

          // Plural
          selected3: "Selected",
          item3: "invoices"
        },
        subject: "Customer",
        status: "Status",
        invoice_date: "Date",
        data_is_missing: "Table is empty",
        accounted: "Accounted"
      }
    },
    details: {
      sync_in_progress: "Invoice has not yet been fully transferred from Pantheon. Some data is not yet visible and editing is disabled.",
      header: {
        title: "New Invoice",
        back_button: "Invoices list",
        invoice_is_canceled: "Canceled",
        invoice_button: "Create invoice",
        send_to_customer_button: "Send to customer"
      },
      footer: {
        remove_button: "Delete invoice",
        add_button: "Add invoice",
        save_button: "Save invoice",
        save_button_confirmed: "Save and confirm",
        confirmed: "Confirmed"
      },
      main: {
        title: "Main Info"
      },
      tasks: {
        back_button: "Back to invoice"
      },
      properties: {
        main: {
          title: "New Invoice",
          required_data: "Invoice data",
          number: "Invoice number",
          number_view: "Number view",
          subject: {
            label: "Customer",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new customer"
          },
          invoice_date: "Invoice date",
          service_date: "Service date",
          status: "Status",
          department: {
            label: "Department",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new department"
          },
          note: "Note",
          doc_type: "Document type",
          created_from_opportunity: "Created from opporunity",
          responsible_person: "Responsible person",
          confirmed: {
            label: "Confirmed",
            yes: "Invoice is confirmed",
            no: "Invoice is not confirmed"
          },
          user: {
            no_results_message: "This User does not exist",
            no_results_button: "Create new user"
          },
          subject_no_result_data: {
            no_results_message: "This Customer does not exist",
            no_results_button: "Create new Customer"
          },
          days_for_payment: "Days for payment",
          due_date: "Due date"
        },
        status: {
          title: "Status",
          status: {
            draft: "Draft",
            "in-progress": "In progress",
            complete: "Complete"
          }
        },
        delivery: {
          title: "Delivery",
          receiver: {
            label: "Deliver to",
            no_results_message: "Your search {{search}} did not have any matches",
            no_results_button: "Create a new customer"
          },
          delivery_deadline: "Delivery deadline",
          delivery_type: "Delivery type",
          department_code: "Department code",
          sale_method: "Sale method",
          amount: "Amount"
        },
        accounting_summary: {
          title: "Accounting Summary"
        },
        accounting_items: {
          title: "Accounting Items"
        }
      },
      relations: {
        tabs: {
          opportunities: "Opportunities"
        },
        add_buttons: {
          opportunities: "New Opportunity"
        }
      },
      original_relation: {
        title: "Created from",
        relation_description: "Invoice was created from the {type} linked below",
        no_relation_description: "Select the {type} this Invoice was created from",
        type: "Type",
        types: {
          opportunity: "Opportunity",
          order: "Order",
          invoice: "Invoice"
        }
      },
      custom_fields: {
        title: "Custom Fields"
      },
      customer: {
        title: "Customer",
        customer: "Customer"
      },
      creation_info: {
        title: "Creation Info"
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete invoice",
      message: "You're about to delete invoice {number}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    },
    create_invoice: {
      title: "Create invoice from invoice",
      message: "A new invoice will be created from this invoice. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Create Invoice"
    },
    cancellation: {
      title: "Cancel this invoice",
      message: "Would you really like to cancel {number} invoice?",
      cancel: "Cancel",
      confirm: "Confirm"
    },
    delete_multiple: {
      title: "Delete selected",
      message: "You're about to delete multiple items. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Invoice saved",
    updated: "Invoice updated",
    deleted: "Invoice was deleted",
    deleted_multiple: "Invoices were deleted",
    not_found: "Unable to find specified invoice"
  },
  status: {
    draft: "Draft",
    "in-progress": "Issued",
    completed: "Payment recieved",
    confirmed: "Confirmed",
    not_confirmed: "Not confirmed"
  },
  accounted: {
    yes: "Accounted",
    no: "Not accounted"
  }
}
