import Action from "@/providers/api/actions/action"

export default class ClearFiltersAction extends Action {
  /**
   * Removes all filters
   */
  async handle ({ commit }) {
    commit("CLEAR_FILTERS")
  }
}
