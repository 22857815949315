export default {
  SubscriptionNotPaid: {
    title: "Subscription not paid",
    subtitle: "Oops ...",
    content: "You seem to have forgoten to pay your subscription. Please pay your subscription to continue.",
    to_licensing: "Check Subscription",
    to_home: "Home"
  },
  TrialExpired: {
    title: "Trial expired",
    subtitle: "",
    content: "Your trial has expired. Please create a new subscription to continue.",
    to_licensing: "Create Subscription",
    to_home: "Home"
  },
  TenantDoesNotExist: {
    title: "Tenant does not exist",
    subtitle: "",
    content: "This tenant does not exist. You can use this url for your own tenant.",
    to_licensing: "Create Tenant"
  }
}
