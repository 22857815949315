import Utils from "./index"

export default {
  mixins: [ Utils ],
  computed: {
    /**
     * Gets util config
     *
     * @returns {object} config
     */
    popupConfig () {
      return this.utilConfig.popup || {}
    }
  },
  props: {
    /**
     * Is popup visible
     */
    visible: { type: Boolean, default: false }
  },
  created () {
    let config = {}
    if (this.utilConfig.form) {
      config.closeAfterConfirm = true
      config.autoloadForm = true
    }
    this.setUtilConfig("popup", config)
  },
  methods: {
    /**
     * Simulates created hook
     * Loads form is Form util is used
     */
    async created () {
      if (this.utilConfig.form && this.popupConfig.autoloadForm) {
        await this.loadForm()
      }

      return true
    },
    /**
     * Simulates mounted hook
     * Focuses UikInput with "mainInput" ref if Form util is used
     */
    mounted () {
      if (this.utilConfig.form) this.focusMainInput()
    },
    /**
     * Simulates beforeDestroy hook
     * Clears form is Form util is used
     */
    beforeDestroy () {
      if (this.utilConfig.form) this.clearForm()
      if (this.utilConfig.multipleEntries) this.multipleEntries = false
    },
    /**
     * Closes popup
     */
    close () {
      this.$emit("close")
    },
    /**
     * Submits form if popup has one
     *
     * @param {object} payload - data to submit
     * @param {function} callback - function to execute if status OK
     * @param {string} options - action behavior options
     *
     * @returns {object} response
     */
    async confirm (
      payload = this.form,
      callback,
      options
    ) {
      if (
        !this.utilResource ||
        !payload ||
        !this.utilConfig.form
      ) return

      const response = await this.submitForm(payload, (res) => {
        if (callback) callback(res)

        const multipleEntriesEnabled = this.utilConfig.multipleEntries && this.allowMultiple && this.multipleEntries && !this.id

        if (multipleEntriesEnabled) {
          if (this.clearForm && this.loadForm) {
            this.clearForm()
            this.loadForm()
          }
        } else if (this.popupConfig.closeAfterConfirm) {
          this.close()
        }

        this.$emit("confirm", res)
      }, options)

      return response
    }
  }
}
