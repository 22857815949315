// import { ifAuthenticated, ifNotAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"
export default [
  // {
  //   path: "/settings",
  //   name: "settings",
  //   meta: { title: "Settings" },
  //   component: () => import(/* webpackChunkName: "Settings" */ "@/Modules/Auth/Settings/Views/Details.vue")
  //   // beforeEnter: ifAuthenticated
  // }
]
