export default class Job {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handle (params) {
    //
  }

  run () {
    return this.handle(...arguments)
  }
}
