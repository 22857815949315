import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/maps",
    name: "Maps",
    meta: { title: "Maps" },
    component: () => import(/* webpackChunkName: "Maps List" */ "@/Modules/Map/Views/Map"),
    beforeEnter: ifAuthenticated
  }
]
