export default {
  views: {
    list: {
      header: {
        title: "Layouts",
        search_placeholder: "Search ...",
        add_button: "Create new layout"
      },
      table: {
        data_is_missing: "Table is empty",
        columns: {
          title: "Title",
          key: "View",
          last_modified: "Last modified"
        }
      }
    },
    builder: {
      header: {
        back_button: "Back",
        default_layout_button: "Set default layout",
        access: {
          everyone: "Available to everyone",
          selected: "Available to selected",
          select: "Select who can use this layout"
        }
      },
      layout: {
        remove_column_button: "Remove column",
        add_column_button: "Add column",
        remove_row_button: "Remove row",
        add_row_button: "Add row",
        type: "Type",
        types: {
          columns: "Simple layout",
          grid: "Grid layout"
        },
        width: "Width",
        add_widget_button: "Place {widget} here",
        move_widget_button: "Move {widget}",
        remove_widget_button: "Remove {widget}",
        widget_settings_button: "{widget} Settings",
        widget_settings: {
          title: "{title} Settings",
          confirm_button: "Save",
          cancel_button: "Cancel"
        }
      },
      widgets: {
        title: "Widgets"
      },
      footer: {
        undo_button: "Undo all changes",
        save_button: "Save changes",
        save_button_saved: "Saved"
      },
      notify: {
        update_success: "{title} successfully updated",
        save_error: "An error occured while saving",
        widget_missing: "A widget this layout was using is not available anymore"
      },
      before_leave_alert: {
        title: "Changes you made are not saved",
        message: "You're about to leave this page without saving. Your changes will be discarded. Are you sure you'd like to leave?",
        confirm_button: "Leave",
        cancel_button: "Stay on this page"
      }
    }
  },
  components: {
    popup: {
      title: "Create new layout",
      fields: {
        title: "Title",
        key: "View",
        public: {
          title_on: "Public",
          title_off: "Private",
          description_on: "Layout is available to everyone",
          description_off: "Layout is available only to selected users"
        }
      },
      layout_titles: {
        "assembly-details": "Assembly details",
        "claim-details": "Reclamation details",
        dashboard: "Dashboard",
        "invoice-details": "Invoice Details",
        "notification-template-details": "Notification template details",
        "opportunity-details": "Opportunity details",
        "order-details": "Order details",
        "project-details": "Project details",
        "subject-details": "Customer details",
        "subject-task-details": "Customer task details",
        "supply-details": "Supply details",
        "task-details": "Task details",
        "travel-order-details": "Travel order details"
      },
      notify: {
        add_success: "Successfully created layout {title}"
      },
      close_button: "Close",
      confirm_button: "Create layout"
    },
    access: {
      layout: "Layout",
      layout_text1: " is currently available to everyone for use.",
      layout_text2: "You can make it private and grant access to specific users and groups.",
      make_private: "Make private",
      make_public: "Make public",
      revoke_access: "Revoke access",
      grant_access: "Grant access",
      table: {
        name: "Name",
        type: "Type",
        members: "Members"
      },
      prompts: {
        make_public: {
          title: "Make {title} public",
          message: "You're about make {title} layout available for use to everyone. Would you like to proceed with this action?",
          cancel: "Cancel",
          confirm: "Make public"
        },
        make_private: {
          title: "Make {title} private",
          message: "You're about make {title} layout available for use only to selected users and groups. Would you like to proceed with this action?",
          cancel: "Cancel",
          confirm: "Make private"
        }
      }
    }
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete layout {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Layout {title} created",
    updated: "Layout {title} updated",
    deleted: "Layout {title} was deleted",
    deleted_multiple: "Layouts were deleted",
    not_found: "Unable to find specified layout"
  },
  default: "Default layout"
}
